enum SharePageSortType {
    Default = 'default',
    SharePageNameASC = 'sharepagename',
    SharePageNameDESC = 'sharepagenamedesc',
    CreateDateASC = 'createdate',
    CreateDateDESC = 'createdatedesc',
    Published = 'published',
    Unpublished = 'unpublished',
}

export default SharePageSortType;
