import { assign } from 'lodash';
import { Dispatcher } from 'flux';
import { trackEvent } from '../../tools/analytics/index.js';

// create a Dispatcher so we can save it's underlying dispatch method, cuz we want to wrap it with ours
const d = new Dispatcher();

class AppDispatcher extends Dispatcher {
    // save the Dispatcher's version of dispatch
    _dispatch = d.dispatch;

    // wrap Dispatcher's dispatch w/ ours
    dispatch = (payload) => {
        trackEvent(payload.actionType, null);

        this._dispatch(payload); // eslint-disable-line no-underscore-dangle
    };
}

export default assign(d, new AppDispatcher());
