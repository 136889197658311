/**
 * This enumeration contains the Action Type string constants for Redux for the
 * personal form section on public-facing connection form.
 */
enum ActionType {
    SET_CAMPUS = 'PersonalFormActions.Public.ON_UPDATE_CAMPUS',
    SET_FIRSTNAME = 'PersonalFormActions.Public.ON_UPDATE_FIRSTNAME',
    SET_GENDER = 'PersonalFormActions.Public.ON_UPDATE_GENDER',
    SET_LASTNAME = 'PersonalFormActions.Public.ON_UPDATE_LASTNAME',
    SET_PERSONAL_DATA = 'PersonalFormActions.Public.ON_UPDATE_PERSONAL_DATA',
    RESET_DATA = 'PersonalFormActions.Public.ON_UPDATE_RESET_DATA',
}

export default ActionType;
