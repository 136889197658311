import React from 'react';
import { PersonalInformationPanelField } from 'connectionForms/models';
import {
    CampusSelectOption,
    Country,
    Region,
} from 'global/models';
import ConnectionFormSection from './connectionFormSection';
import ContactForm from './contactForm';
import AgreementEntry from './models/agreementEntry.model';
import Answer from './models/answer.model';
import ConnectionFormContainerOrField from './models/connectionFormContainerOrField.model';
import ConnectionFormSectionModel from './models/connectionFormSection.model';
import PersonalForm from './personalForm';

type PropTypes = {
    /**
     * Array of agreement entries
     */
    agreements: AgreementEntry[];
    /**
     * Array of questions answer
     */
    answers: Answer[];
    /**
     * Function to update agreement acceptance
     */
    onChangeAgreementConsent: (field: ConnectionFormContainerOrField, consented: boolean) => void;
    /**
     * Function updates question answers
     */
    onFieldChanged: Function;

    /**
     * Connection form sections which hold question fields/containers
     */
    sections: ConnectionFormSectionModel[];

    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;

    /**
     * Integer. Order of personal panel section
     */
    personalPanelOrder: number;

    /**
     * Type of Personal Information Panel for this Form Template. "Lite" or "Full". Determines which personal/contact information fields are included on the Form.
     */
    isPersonalPanelLite: boolean;

    /**
     * legacy i18n translation function
     */
    legacyI18N?: (key: string) => string;

    /**
     * Boolean flag indicating whether the component is used publicly or not.
     */
    shouldUseNewTranslations?: boolean;

    /**
     * Campus List data
     */
    campusSelectOptions: CampusSelectOption[];

    /**
     * List of fields on the Form's Personal Information Panel that should be considered required when submitting Form Entries.
     */
    personalPanelRequiredFields: PersonalInformationPanelField[];

    /**
     * Country List data
     */
    countriesListData: Country[];

    /**
     * Region by country list data
     */
    regionByCountryData: Region[];

    isWhoIsYourOneForm: boolean;
};

function ConnectionFormSectionList({
    agreements,
    answers,
    onChangeAgreementConsent,
    onFieldChanged,
    sections,
    showValidationErrors,
    personalPanelOrder,
    isPersonalPanelLite,
    shouldUseNewTranslations,
    legacyI18N,
    campusSelectOptions,
    personalPanelRequiredFields,
    countriesListData,
    isWhoIsYourOneForm,
    regionByCountryData,
}: PropTypes) {
    return (
        <React.Fragment>
            {sections?.map((section, index) => (
                <React.Fragment>
                    {!isWhoIsYourOneForm && personalPanelOrder === index ?
                        (
                            <React.Fragment>
                                <PersonalForm
                                    campusOptions={campusSelectOptions}
                                    isLite={isPersonalPanelLite}
                                    legacyI18N={legacyI18N}
                                    requiredFields={personalPanelRequiredFields}
                                    shouldUseNewTranslations={shouldUseNewTranslations}
                                    showValidationErrors={showValidationErrors}
                                />
                                <ContactForm
                                    countriesListData={countriesListData}
                                    isLite={isPersonalPanelLite}
                                    legacyI18N={legacyI18N}
                                    regionByCountryData={regionByCountryData}
                                    requiredFields={personalPanelRequiredFields}
                                    shouldUseNewTranslations={shouldUseNewTranslations}
                                    showValidationErrors={showValidationErrors}
                                />
                            </React.Fragment>
                        ) : null}

                    <ConnectionFormSection
                        agreements={agreements}
                        answers={answers}
                        onChangeAgreementConsent={onChangeAgreementConsent}
                        onFieldChanged={onFieldChanged}
                        section={section}
                        showValidationErrors={showValidationErrors}
                    />
                </React.Fragment>
            ))}
            {isWhoIsYourOneForm || personalPanelOrder >= (sections?.length ?? 0) ?
                (
                    <React.Fragment>
                        <PersonalForm
                            campusOptions={campusSelectOptions}
                            isLite={isPersonalPanelLite}
                            legacyI18N={legacyI18N}
                            requiredFields={personalPanelRequiredFields}
                            shouldUseNewTranslations={shouldUseNewTranslations}
                            showValidationErrors={showValidationErrors}
                        />
                        <ContactForm
                            countriesListData={countriesListData}
                            hidePhoneNumber={isWhoIsYourOneForm}
                            isLite={isPersonalPanelLite}
                            legacyI18N={legacyI18N}
                            regionByCountryData={regionByCountryData}
                            requiredFields={personalPanelRequiredFields}
                            shouldUseNewTranslations={shouldUseNewTranslations}
                            showValidationErrors={showValidationErrors}
                        />
                    </React.Fragment>
                ) : null}
        </React.Fragment>
    );
}

export default ConnectionFormSectionList;
