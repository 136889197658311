enum ResponseStatusText {
    /**
     * 400 Bad Request
     */
    BAD_REQUEST = 'Bad Request',
    /**
     * 409 Conflict
     */
    CONFLICT = 'Conflict',
    /**
     * 403 Forbidden
     */
    FORBIDDEN = 'Forbidden',
    /**
     * 500 Internal Server Error
     */
    INTERNAL_SERVER_ERROR = 'Internal Server Error',
    /**
     * 404 Not Found
     */
    NOT_FOUND = 'Not Found',
    /**
     * 200 OK
     */
    OK = 'OK',
    /**
     * 401 Unauthorized
     */
    UNAUTHORIZED = 'Unauthorized',
}

export default ResponseStatusText;
