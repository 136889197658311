import { isNil } from 'lodash';
import { Region } from '../../global/models';
import ActionType from './connectionForm.actionTypes';

export type RegionByCountryState = {
    data: Region[];
    isFetchingRegionByCountryList: boolean;
};

export const DEFAULT_STATE: RegionByCountryState = {
    data: [],
    isFetchingRegionByCountryList: false,
};

interface RequestRegionByCountryAction {
    type: ActionType.REQUEST_REGIONS_LIST;
}

interface ReceiveRegionByCountryAction {
    type: ActionType.RECEIVE_REGIONS_LIST;
    result: Region[];
}

type KnownActionType = RequestRegionByCountryAction | ReceiveRegionByCountryAction;

export default (
    state: RegionByCountryState | undefined,
    incomingAction: any,
): RegionByCountryState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_REGIONS_LIST:
                return {
                    ...state,
                    isFetchingRegionByCountryList: true,
                };

            case ActionType.RECEIVE_REGIONS_LIST:
            {
                const specificAction = action as ReceiveRegionByCountryAction;

                return {
                    ...state,
                    data: specificAction.result,
                    isFetchingRegionByCountryList: false,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
