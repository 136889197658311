import _ from 'lodash';
import { EventEmitter } from 'events';
import moment from 'moment-timezone';
import request from 'axios';
import AppDispatcher from '../js/dispatcher/AppDispatcher';
import BootstrapConstants from '../js/constants/BootstrapConstants';
import i18nActionCreators from '../js/actionCreators/i18nActionCreators';
import i18nConstants from '../js/constants/i18nConstants';
import UserPreferenceConstants from '../js/constants/User/UserPreferenceConstants';

const CHANGE_EVENT = 'change';

class i18nMessagesStoreClass extends EventEmitter {
    constructor() {
        super();

        this._lcid = 'en-us';
        this._messages = {};

        this._languages = [
            {
                lcid: 'en-us',
                messages: {
                    Admin: {
                        AppSettings: {
                            AppSettings: 'App Settings',
                            DashboardSettings: 'Dashboard Settings',
                            DataCovenant: 'Data Covenant',
                            DataCovenantContent: 'Data Covenant Content (HTML)',
                            DataCovenantTitle: 'Data Covenant Title',
                            LogoImage: 'Logo Image',
                            Save: 'Save',
                            SuccessMessage: 'Success! Your file has been uploaded.',
                            SuccessfullySaved: 'Successfully saved.',
                            TopBannerPhoto: 'Top Banner Photo',
                            TopBannerPhotoPosition: 'Top Banner Photo Position (e.g. x% y% or center top)',
                            TopBannerText: 'Top Banner Text',
                            TopBannerTextCitation: 'Top Banner Text Citation',
                        },
                        WorshipService: {
                            Title: 'Worship Service',
                            CancelButton: 'Cancel',
                            OkButton: 'OK',
                            ForceToUpdate: 'Next events will be deleted:',
                        },
                    },
                    App: {
                        ChangePassword: 'Change Password',
                        Chrome: 'Chrome',
                        Firefox: 'Firefox',
                        Help: 'Help',
                        LogOut: 'Log Out',
                        NoConnection: 'No Push Notifications',
                        NotificationPreferences: 'Notification Preferences',
                        Notifications: 'Notifications',
                        Preferences: 'Preferences',
                        PreviewFeature: 'Preview Feature',
                        Safari: 'Safari',
                        CoreAppFooter: {
                            PeopleManagementLink: 'People Management',
                            Help: 'Help',
                        },
                        CoreAppHeader: {
                            Header: 'Church Management',
                            PersonSearchLink: 'People Management',
                            Menu: 'Menu',
                        },
                        Navigation: {
                            Admin: 'Admin',
                            AddPerson: 'Add Person',
                            'App Settings': 'App Settings',
                            AuditLog: 'Audit Log',
                            'Church Structure': 'Church Structure',
                            Close: 'Close',
                            Dashboard: 'Dashboard',
                            DataCapture: 'Data Capture',
                            'Data Capture': 'Data Capture',
                            'Data Extracts': 'Data Extracts',
                            DevTools: 'Dev Tools',
                            'Duplicate Records': 'Duplicate Records',
                            Feedback: 'Feedback',
                            Help: 'Help',
                            Home: 'Home',
                            Insights: 'Insights',
                            Media: 'Media',
                            Menu: 'Menu',
                            MinistriesCentral: 'Ministries Central',
                            MyDashboard: 'My Dashboard',
                            MyMinistry: 'My Ministry',
                            AddMinistry: 'New Ministry',
                            'Non-MemberList': 'Lite Person Queue',
                            Operations: 'Operations',
                            People: 'People',
                            'People Data Extracts': 'People Data Extracts',
                            Responses: 'Responses',
                            'Response Cards': 'Response Cards',
                            'Response Card Data Extracts': 'Response Card Data Extracts',
                            SearchAdd: 'Find and Add People',
                            Search: 'Search',
                            'Security Roles': 'Security Roles',
                            System: 'System',
                            UserSettings: 'User Settings',
                            Users: 'Users',
                            Import: 'Import',
                            ViewAll: 'View All',
                            'Weekend Flash Report': 'Weekend Flash Report',
                            'Workflow Data Extracts': 'Workflow Data Extracts',
                            'Service Setup': 'Service Setup',
                            'Elastic Search Indexing': 'Elastic Search Indexing',
                            EventsCentral: 'Events Central',
                            ContentManagement: 'Content Management',
                        },
                        QuickNotification: {
                            Clear: 'Clear',
                            ClearAll: 'Clear All',
                            Filters: 'Filters',
                            MarkAllAsRead: 'Mark All As Read',
                            NewNotifications: 'New Notifications',
                            NoNewNotificaitons: 'No New Notifications',
                            NotificationCenter: 'Notification Center',
                            Past30Days: 'Past 30 Days',
                            Search: 'Search',
                            SortBy: 'Sort By',
                            Status: 'Status',
                            Timeline: 'Timeline',
                            Type: 'Type',
                        },
                    },
                    AuditLog: {
                        AuditLog: {
                            AuditLog: 'Audit Log',
                        },
                        AuditLogTypeIcons: {
                            Additions: 'Additions',
                            Changes: 'Changes',
                            Removals: 'Removals',
                        },
                        Modal: {
                            AuditLog: 'Audit Log',
                            DateRange: 'Date Range',
                            ID: 'ID',
                            PreviousRecords: 'Previously Merged Records',
                        },
                        Details: {
                            AddNote: 'Add a Note (optional)',
                            Back: 'Back',
                            By: 'By',
                            MultipleCategories: 'Multiple Categories',
                            NA: 'N/A',
                            NoteSaved: 'Note Saved to Audit Entry',
                            On: 'On',
                            PriorNotes: 'Prior Notes',
                            Save: 'Save',
                            SeeDetails: 'See Details',
                            To: 'To',
                            Via: 'Via',
                        },
                        PersonAuditLogRow: {
                            Id: 'Id',
                            ModifiedBy: 'Modified By',
                            ModifiedDate: 'Modified Date',
                            View: 'View',
                        },
                    },
                    ButtonConfirmation: {
                        No: 'No',
                        Yes: 'Yes',
                    },
                    ChurchStructure: {
                        ChurchStructure: {
                            ChurchStructure: 'Church Structure',
                            Subtitle: 'Explore (Read-Only)',
                            AddCampus: 'Add Campus',
                        },
                        ChurchStructureDetail: {
                            Address: 'Address',
                            Phone: 'Phone',
                            Email: 'Email',
                            Established: 'Established',
                            Overview: 'Overview',
                            Venues: 'Venues',
                            NewVenue: 'New Venue',
                            VenueDetails: 'Venue Details',
                            Name: 'Name',
                            Description: 'Description',
                            Remove: 'Remove?',
                            DeleteVenue: 'Delete Venue',
                            CannotDeleteVenue: 'This venue cannot be deleted because it is currently being used.',
                            UpdateVenueRequest: 'This venue is currently being used.  Are you sure you want to update?',
                            Save: 'Save',
                            SmsName: 'SMS Name',
                            Cancel: 'Cancel',
                            CampusAddress: 'Campus Address',
                            OfficeAddress: 'Office Address',
                            CampusPhone: 'Campus Phone',
                            OfficePhone: 'Office Phone',
                            Extension: 'Extension',
                            AddRoom: 'Add Room',
                        },
                        ChurchStructureOutline: {
                            ChurchTopLevel: 'Saddleback Church',
                            Established: 'Established',
                        },
                    },
                    CommentForm: {
                        Comments: 'Comments',
                        LeaveAComment: 'Leave a comment',
                        Post: 'Post',
                    },
                    ConnectionCards: {
                        EmbeddedEntryDetails: {
                            Church: 'Church',
                            Close: 'Close',
                            ResponseCard: 'Response Card',
                            Responses: 'Responses',
                            WorshipService: 'Worship Service',
                        },
                        Global: {
                            EntryDrawer: {
                                CloseDrawer: 'Close Drawer',
                                Entry: 'Entry',
                                HidePersonalInformation: 'Hide Personal Information',
                                NextEntry: 'Next Entry',
                                Of: 'of',
                                PreviousEntry: 'Previous Entry',
                                ShowPersonalInformation: 'Show Personal Information',
                            },
                            EntryTemplateFields: {
                                Email: 'Email',
                            },
                            EntryDrawerPersonalContent: {
                                Address1: 'Address 1',
                                Address2: 'Address 2',
                                Cell: 'Cell',
                                City: 'City',
                                Country: 'Country',
                                Email: 'Email',
                                FirstName: 'First Name',
                                Home: 'Home',
                                LastName: 'Last Name',
                                Nickname: 'Nickname',
                                PersonalDetails: 'Personal Details',
                                PhoneNumber: 'Phone Number',
                                State: 'State',
                                Suffix: 'Suffix',
                                Work: 'Work',
                                Zip: 'Zip',
                            },
                        },
                    },
                    ContentManagement: {
                        ServiceSeriesBuilder: {
                            Active: 'Active',
                            Add: 'Add',
                            AddQuestion: 'Add Question',
                            AddSeries: 'Add a Series',
                            Close: 'Close',
                            Cancel: 'Cancel',
                            Description: 'Description',
                            EditSeries: 'Edit Series',
                            InActive: 'Inactive',
                            Name: 'Name',
                            Save: 'Save',
                            SeriesName: 'Series Name',
                            Services: 'Services',
                            Tagline: 'Tagline',
                        },
                        ServiceCompanion: {
                            Add: 'Add',
                            AddQuestion: 'Add Question',
                            AddSeries: 'Add a Series Name',
                            AddServiceOutline: 'Add Service Outline',
                            AddSpeaker: 'Add a Speaker',
                            AddTemplate: 'Add Template',
                            Announcement: 'Announcement',
                            AreYouSure: 'Are you sure?',
                            Body: 'Body',
                            CallToAction: 'Call to Action',
                            Campus: 'Campus',
                            Campuses: 'Campuses',
                            Cancel: 'Cancel',
                            Categories: 'Categories',
                            Category: 'Category',
                            Choose: 'Choose',
                            ChooseAMinistry: 'Choose a Ministry',
                            Close: 'Close',
                            ConnectionQuestions: 'Connection Question(s)',
                            Date: 'Date',
                            Day: 'Day',
                            Description: 'Description',
                            Event: 'Event',
                            EditServiceOutline: 'Edit Service Outline',
                            First: 'First',
                            Filter: 'Filter',
                            Last: 'Last',
                            Location: 'Location',
                            Locations: 'Locations',
                            Media: 'Media',
                            Milestone: 'Milestone',
                            Ministry: 'Ministry',
                            Name: 'Name',
                            NextStepQuestions: 'Next Step Question(s)',
                            PleaseEnterAValidUrl: 'Please enter a valid URL.',
                            Preview: 'Preview',
                            Published: 'Published',
                            Save: 'Save',
                            SelectEvent: 'Select Event',
                            Series: 'Series',
                            PartNumber: 'Series Part Number',
                            Service: 'Service',
                            Song: 'Song',
                            SongName: 'Song Name',
                            Artist: 'Artist',
                            Lyrics: 'Lyrics',
                            Link: 'Link',
                            ServiceAssociation: 'Service Association',
                            ServiceOutline: 'Service Outline',
                            ServiceTime: 'Service Time',
                            Services: 'Services',
                            Sort: 'Sort',
                            Speaker: 'Speaker',
                            Tags: 'Tags',
                            Time: 'Time',
                            Title: 'Title',
                            URL: 'URL',
                            Version: 'Version',
                            When: 'When',
                        },
                    },
                    Dashboard: {
                        Actions: 'Actions',
                        Activity: 'Activity',
                        Alerts: 'Alerts',
                        All: 'All',
                        Assignee: 'Assignee',
                        Attachments: 'Attachments',
                        Back: 'Back',
                        Blocked: 'Blocked',
                        ClaimTask: 'Claim Task',
                        Closed: 'Closed',
                        ComingSoon: 'Coming Soon',
                        Complete: 'Complete',
                        CompletedOn: 'Complete on',
                        Comments: 'Comments',
                        Details: 'Details',
                        Done: 'Done',
                        Due: 'Due',
                        GoToTask: 'Go to Task',
                        Hold: 'Hold',
                        Home: 'Home',
                        Links: 'Links',
                        LoadMore: 'Load More',
                        Mentions: 'Mentions',
                        MobileSupport: 'Mobile Support',
                        MyTasks: 'My Tasks',
                        NeededBy: 'Needed By',
                        NoOwnTasks: 'You have no {0} tasks',
                        NoTasks: 'There are no {0} tasks',
                        Notifications: 'Notifications',
                        Open: 'Open',
                        Reassigned: 'Reassigned',
                        Removed: 'Removed',
                        Resources: 'Resources',
                        StartedOn: 'Started On',
                        Supervisor: 'Supervisor',
                        Task: 'Task',
                        Tasks: 'Tasks',
                        Todos: 'Todos',
                        Users: 'Users',
                        Workflow: 'Workflow',
                        RefreshActivities: 'Refresh',
                        NextActivities: 'Next',
                        DashboardActivities: {
                            Complete: 'Complete',
                            CompleteInDays: 'In {0} days',
                            CompleteToday: 'Today',
                            CompleteOverdue: 'Overdue',
                            CompleteOverdueForDays: 'Overdue for {0} days',
                            From: 'From',
                            To: 'To',
                            Details: 'Details',
                        },
                        DashboardMinistryMemberDrawer: {
                            Ministry: 'Ministry',
                            Campus: 'Campus',
                            MinistryDashboard: 'Ministry Dashboard',
                            About: 'About',
                            Personal: 'Personal',
                            FirstName: 'First Name',
                            LastName: 'Last Name',
                            Birthday: 'Birthday',
                            Contact: 'Contact',
                            Address: 'Address',
                            Email: 'Email',
                            Cell: 'Cell',
                            ViewRecord: 'View Record',
                        },
                        Welcome: {
                            Dashboard: 'Dashboard',
                            Home: 'Home',
                        },
                    },
                    DataCapture: {
                        BrowseFacts: {
                            AddEntry: 'Add Entry',
                            PageTitle: 'Data Entries',
                            DataEntriesAwaitingVerification: 'Data Entries Awaiting Verification: ',
                            Filter: 'Filter Data Entries',
                        },
                        BrowseFactsSearchFilter: {
                            FilterBy: 'Filter By',
                            MakeSelectionBelow: 'Make a Selection Below',
                            ChurchLocation: 'Church Location',
                            Apply: 'Apply',
                            Reset: 'Reset',
                            Select: 'Select',
                        },
                        BrowseFactsSearchRow: {
                            ChurchLocation: 'Church Location',
                            Month: 'Month',
                            DayAndTime: 'Day & Time',
                            ViewDataEntry: 'View Data Entry',
                        },
                        DataEntry: {
                            Month: 'Month',
                            PageTitle: 'Data Capture',
                            Salvations: 'Salvations:',
                            ServiceTime: 'Service Time',
                            WeekPeriod: 'Event Period',
                            Select: 'Select',
                            ChurchLocation: 'Location',
                            SelectLocationAndTime: 'Choose Place and Time',
                            SetAndEnterData: 'Set & Enter Data',
                            Cancel: 'Cancel',
                            Done: 'Done',
                            SuccessfullySaved: 'Successfully recorded the data',
                        },
                        DataEntryRow: {
                            Cancel: 'Cancel',
                            Edit: 'Edit',
                            EnterData: 'Enter Data',
                            PleaseEnterAValue: 'Please enter a Value',
                            Save: 'Save',
                            Verify: 'Verify',
                        },
                        DataVerify: {
                            Month: 'Month',
                            PageTitle: 'Data Verification',
                            ServiceTime: 'Service Time',
                            WeekPeriod: 'Event Period',
                            ChurchLocation: 'Location',
                            SelectLocationAndTime: 'Title TBD',
                            Cancel: 'Cancel',
                            SuccessfullySaved: 'Successfully updated data verification status',
                        },
                        DataVerifyRow: {
                            Cancel: 'Cancel',
                            Edit: 'Edit',
                            EnterData: 'Enter Data',
                            PleaseEnterAValue: 'Please enter a Value',
                            Save: 'Save',
                            Verify: 'Verify',
                        },
                        PlaceManager: {
                            Add: 'Add Place',
                            Edit: 'Edit Place',
                            BasicInfo: 'Basic Information',
                            Location: 'Location',
                            HostChurch: 'Hosting Church Location',
                            Name: 'Name',
                            FacilityName: 'Facility Name',
                            SortOrder: 'Sort Order',
                            Address: 'Address',
                            Latitude: 'Latitude',
                            Longitude: 'Longitude',
                            TimeZone: 'Time Zone',
                            Locale: 'Locale',
                            Select: 'Select',
                            PleaseFillInPlaceName: 'Please fill in Name',
                            PleaseFillInSortOrder: 'Please fill in a number for SortOrder',
                            SuccessfullySaved: 'Successfully Saved',
                        },
                    },
                    DataGrid: {
                        DataGridFooter: {
                            LoadMore: 'Load More',
                        },
                    },
                    DatePicker: {
                        ActionButtons: {
                            Apply: 'Apply',
                            Reset: 'Reset',
                            SelectADateRange: 'Select A Date Range',
                        },
                    },
                    DetailToggler: {
                        LessDetails: 'Show Less Details',
                        MoreDetails: 'Show More Details',
                    },
                    Errors: {
                        403: '403',
                        404: '404',
                        500: '500',
                        ApplicationError: 'Application Error',
                        ContactSupport: 'If you need further assistance, contact Support.',
                        ForbiddenPage: 'You do not have access to this page',
                        Message: 'Message',
                        Msg404: 'The page you blah blah blah yeah it’s not here.',
                        NoKeys: 'You do not have keys to unlock this door.',
                        PageNotFound: 'Page Not Found',
                        PermissionRequired: 'Permission Required',
                        Response: 'Response',
                        StatusCode: 'Status Code',
                        UseNav: 'Please use the navigation to find the page you are looking for.',
                        ValidationErrors: 'Request is invalid:',
                    },
                    Events: {
                        EventSettings: {
                            Accommodation: 'Accommodation',
                            Accommodations: 'Accommodations',
                            AccommodationsAndRoomCategories: 'Accommodations & Room Categories',
                            AccommodationSaved: 'Accommodation Saved',
                            AccommodationPlaceholder: 'e.g. Food Truck',
                            Add: 'Add',
                            AddAccommodation: 'Add Accommodation',
                            AddEventCategory: 'Add Event Category',
                            AddRoomCategory: 'Add Room Category',
                            AddTag: 'Add Tag',
                            AlreadyExists: 'already exists',
                            CategoriesAndTags: 'Categories & Tags',
                            EventCategories: 'Event Categories',
                            EventCategory: 'Event Category',
                            EventCategoryPlaceholder: 'e.g. Worship Service',
                            EventCategorySaved: 'Event Category Saved',
                            ListOfAccommodations: 'List of Accommodations',
                            ListOfEventCategories: 'List of Event Categories',
                            ListOfRoomCategories: 'List of Room Categories',
                            ListOfTags: 'List of Tags',
                            PleaseEnterValue: 'Please enter a value.',
                            RoomCategories: 'Room Categories',
                            RoomCategory: 'Room Category',
                            RoomCategoryPlaceholder: 'e.g. 1st Grade Room',
                            RoomCategorySaved: 'Room Category Saved',
                            Save: 'Save',
                            Tag: 'Tag',
                            Tags: 'Tags',
                            TagSaved: 'Tag Saved',
                            TagPlaceholder: 'e.g. Community',
                        },
                    },
                    Help: {
                        Help: {
                            Help: 'Help',
                            WhatDoYouNeedHelpWith: 'What Do You Need Help With',
                        },
                    },
                    Insights: {
                        Dashboard: {
                            AllRegions: 'All Regions',
                            Title: 'Insights',
                            Filters: 'Filters',
                            OverallHealth: 'Overall Health',
                            Attendance: 'Attendance',
                            Salvations: 'Salvations',
                            Baptisms: 'Baptisms',
                            Class: 'Discover Your Purpose',
                            Basic: 'Basic',
                            Detailed: 'Detailed',
                            Totals: 'Totals: ',
                            YearToDateAverages: 'Year to Date Averages',
                            YearToDateTotal: 'Year to Date Total',
                            MonthlyTotal: 'Monthly Total',
                            MonthlyAverages: 'Monthly Averages',
                            WeeklySums: 'Weekly Sums',
                            AgeGroup: 'Age Group: ',
                            AllCampuses: 'All Campuses',
                            CampusesAndVenues: 'Campuses & Venues: ',
                            ShowVenues: 'Show Venues',
                            SinceInception: 'Since Inception',
                            Campus: 'Campus',
                            Venues: 'Venues',
                        },
                        WeekendFlashReport: {
                            TitleAttendance: 'Attendance Flash Report',
                            TitleSalvations: 'Salvations Flash Report',
                            TitleBaptisms: 'Baptisms Flash Report',
                            TitleParking: 'Parking Flash Report',
                            TitleOnline: 'Online Attendance Flash Report',
                            Salvations: 'Salvations',
                            Baptisms: 'Baptisms',
                            Parking: 'Parking',
                            Attendance: 'Attendance',
                            Online: 'Online',
                            Campus: 'Campus',
                            Total: 'Total',
                            CampusVenueTime: 'Campus, Venue, and Service Time',
                            VenueDayTime: 'Venue, Day of Week and Service Time',
                            Adult: 'Adult',
                            Children: 'Children',
                            HighSchool: 'High School',
                            JuniorHigh: 'Junior High',
                            College: 'College',
                            NoData: 'No {0} data for the related period',
                            Details: 'Details',
                            All: 'All',
                        },
                        FollowUps: {
                            AllCampuses: 'All Campuses',
                            AllTime: 'All Time',
                            AvgResponseTime: 'Average Response Time',
                            AvgResponseTimeTooltipNote: 'This gives you a rough idea of how quickly people are being responsed to',
                            Blocked: 'Blocked',
                            Campus: 'Campus',
                            Days: 'Days',
                            Escalated: 'Escalated',
                            EscalatedTooltipNote: 'These are those Follows Ups that have been handed off to a supervisor to handle',
                            HelpOnInProgressStatistics: 'Help on In Progress Statistics',
                            HelpOnResponses: 'Help on Responses',
                            InProgress: 'In Progress',
                            InProgressPageTooltipNote: 'See the current status of In Progress Follow Ups.',
                            InProgressPageTooltipTitle: 'In Progress',
                            InProgressTooltipNote: 'Any Follow Up that is still Open or On Hold',
                            Noteworthy: 'Noteworthy',
                            OnHold: 'On Hold',
                            Open: 'Open',
                            Overdue: 'Overdue',
                            Past: 'Past',
                            PastDue: 'Past Due',
                            PastDueTooltipNote: 'Any Follow Up that is still Open or On Hold beyond the due date',
                            Received: 'Received',
                            ReceivedTooltipNote: 'Any Follow Up that has been created',
                            Responded: 'Responded',
                            RespondedTooltipNote: 'Any Follow Up that is now closed',
                            Responses: 'Responses',
                            ResponsesPageTooltipNote: 'From here you can see how many Follow Ups were completed Successfully versus Unsuccessfully.',
                            ResponsesPageTooltipTitle: 'Responses',
                            Successful: 'Successful',
                            Timeframe: 'Timeframe',
                            Today: 'Today',
                            Totals: 'Totals',
                            Unsuccessful: 'Unsuccessful',
                        },
                    },
                    ListSteps: {
                        Current: 'Current',
                    },
                    Ministries: {
                        Actions: 'Actions',
                        Active: 'Active',
                        AddMinistry: 'New Ministry',
                        ApplyFilters: 'Apply Filters',
                        Campus: 'Campus',
                        Category: 'Category',
                        Email: 'Email',
                        EventsServing: 'Events & Serving',
                        Export: 'Export',
                        Filter: 'Filter',
                        Filters: 'Filters',
                        FindAMinistry: 'Filter Ministries by Keywords',
                        General: 'General',
                        Inactive: 'Inactive',
                        Insights: 'Insights',
                        LoadMore: 'Load More',
                        Location: 'Location',
                        Locations: 'Locations',
                        Ministries: 'Ministries',
                        MinistryAdd: {
                            Cancel: 'Cancel',
                            Category: 'Category',
                            Location: 'Location',
                            Ministry: 'Ministry',
                            PageTitle: 'Add Ministry',
                            Tag: 'Tag',
                            WasCreated: 'was created',
                            WasUpdated: 'was updated',
                        },
                        MyMinistries: 'My Ministries',
                        MyMinistry: 'My Ministry',
                        Name: 'Name',
                        NewEmail: 'New Email',
                        NewMinistry: 'New Ministry',
                        NoMinistriesFound: 'No Ministries Found',
                        Opportunities: 'Serving Opportunities',
                        Overview: 'Overview',
                        Pending: 'Pending',
                        Search: 'Search',
                        SearchMinistry: 'Search Ministries',
                        Settings: 'Settings & Preferences',
                        SMS: 'SMS',
                        StaffConnectors: 'Staff Connectors',
                        Tags: 'Tags',
                        Text: 'Text',
                        Total: 'Total',
                        Training: 'Training',
                        Volunteers: 'Volunteers',
                        WebSettings: 'Website Settings',
                        MinistryEvents: {
                            AccommodationsSuccessfullyUpdated: 'Accommodations has been successfully updated',
                            Actions: 'Actions',
                            Add: 'Add',
                            Any: 'Any',
                            Canceled: 'Canceled',
                            CancelledEventSubject: '{0} has been cancelled',
                            ClearHost: 'Clear Host',
                            Duplicate: 'Duplicate',
                            Entry: 'Entry',
                            EventContact: 'Event Contact',
                            EventDetails: 'Event Details',
                            Events: 'Events',
                            Free: 'Free',
                            NewEmail: 'New Email',
                            NewEvent: 'Create Event',
                            Paid: 'Paid',
                            Edit: 'Edit',
                            EditEvent: 'Edit Event',
                            ViewEvent: 'View Event',
                            EventName: 'Event Name',
                            EventDescription: 'Event Description',
                            OneTime: 'One Time',
                            Recurring: 'Recurring',
                            Status: 'Status',
                            When: 'When',
                            Lifespan: 'Lifespan',
                            Ongoing: 'Ongoing',
                            Ranged: 'Ranged',
                            OnCampus: 'On Campus',
                            OffCampus: 'Off Campus',
                            Timezone: 'Time Zone',
                            SelectTimezone: 'Select Time Zone',
                            Campus: 'Campus',
                            Category: 'Category',
                            CategoryAndTags: 'Category & Tags',
                            SelectCampus: 'Select Campus',
                            Search: 'Search',
                            Month: 'Month',
                            Day: 'Day',
                            EventTypes: 'Event Types',
                            MyEvents: 'My Events',
                            Ministry: 'Ministry',
                            NotRequired: 'Not Required',
                            EventOptions: 'Event Options',
                            Repeats: 'Repeats',
                            RegistrationRequired: 'Registration Required',
                            TimeFormat: 'Time Format',
                            OnlyShowTimesWithEvents: 'Only Show Times With Events',
                            CampusEvent: 'Campus Event',
                            MinistryEvent: 'Ministry Event',
                            MinistryName: 'Ministry Name',
                            StartTime: 'Start Time',
                            StartDate: 'Start Date',
                            EndTime: 'End Time',
                            EndDate: 'End Date',
                            WhichCampus: 'Who will be hosting this event?',
                            What: 'What',
                            Who: 'Who',
                            RepeatingEvent: 'Repeating Event?',
                            Cancel: 'Cancel',
                            DoesNotRepeat: 'Does Not Repeat',
                            RepeatDaily: 'Daily',
                            RepeatWeekly: 'Weekly On {0}',
                            RepeatMonthly: 'Monthly On The {0} {1}',
                            RepeatYearly: 'Yearly On {0}',
                            RepeatCustom: 'Custom...',
                            CustomRepeat: 'Custom Repeat',
                            RepeatEvery: 'Repeat Every',
                            RepeatOn: 'Repeat On',
                            Required: 'Required',
                            EndRepeat: 'End Repeat',
                            Days: 'Days',
                            Weeks: 'Weeks',
                            Months: 'Months',
                            Years: 'Years',
                            Never: 'Never',
                            OnDate: 'On Date',
                            EndsAfter: 'Ends After',
                            Occurrences: 'Occurrences',
                            The: 'The',
                            Of: 'of',
                            StartEvent1: 'This event starts on <b>{0}</b> from <b>{1}</b> and continues until <b>{2} {3}</b>',
                            StartEvent3: 'and will take place {0}',
                            Save: 'Save',
                            First: 'First',
                            Second: 'Second',
                            Third: 'Third',
                            Fourth: 'Fourth',
                            Last: 'Last',
                            SuccessfullyAdded: 'Successfully Added',
                            SuccessfullyCanceled: 'Successfully Canceled',
                            SuccessfullyUpdated: 'The event has been successfully updated',
                            RequiresRegistration: 'Requires Registration',
                            DoesntRequireRegistration: 'Does Not Require Registration',
                            SelectMinistry: 'Select A Ministry',
                            Me: '(Me) ',
                            Email: 'Email',
                            Cell: 'Cell',
                            NA: 'N/A',
                            AreYouSure: 'Are you sure?',
                            Remove: 'Remove',
                            Tags: 'Tags',
                            DragAndDropOr: 'Drag & Drop here to upload new photo or ',
                            Browse: 'Browse',
                            Error: 'Error',
                            ImageIsTooLarge: 'The image is too large',
                            UploadError: 'There was a problem while trying to upload your file',
                            Uploading: 'Uploading...',
                            Where: 'Where',
                            WhereIsThisEvent: 'Where is this event?',
                            Venue: 'Venue',
                            AddAnotherVenue: 'Add Another Venue',
                            AddVenueFromThisCampus: 'Add venue(s) from this campus',
                            Capacity: 'Capacity',
                            VenueName: 'Venue Name',
                            IsThisEventsAddressPrivate: 'Is this event\'s address private?',
                            AddressWillNotBePublic: '(Address will not be public when published)',
                            Name: 'Name',
                            Address1: 'Address 1',
                            Address2: 'Address 2',
                            City: 'City',
                            MakeSelection: 'Make a Selection',
                            Region: 'Region',
                            PostalCode: 'Postal Code',
                            Country: 'Country',
                            Latitude: 'Latitude',
                            Longitude: 'Longitude',
                            ChangeCampusAlert: 'Change campus and clear chosen venues?',
                            SuccessfullyInvited: 'Invites Sent To Ministry(s) to help with Serving Opps',
                            IncorrectRecurringRule: 'Fewer than two occurrences will happen before {0}',
                            SuccessfullyPublished: 'The event has been successfully published',
                            SuccessfullyUnpublished: 'The event has been successfully unpublished',
                            HostedBy: 'Hosted By',
                            Publish: 'Publish',
                            Published: 'Published',
                            PublishedFmt: 'Published: {0}',
                            Unpublish: 'Unpublish',
                            Unpublished: 'Unpublished',
                            ViewPage: 'View Page',
                            CopyURL: 'Copy URL',
                            ServingOppsTitle: 'Serving Opps',
                            ServingOppsHelp: 'Do you need a ministry to help with serving opps?',
                            ServingOppsValues: '{0} SO(s) Attached',
                            Accommodations: 'Accommodations',
                            AccommodationsHelp: 'What accommodations will be at your event?',
                            AccommodationsValues: '{0} Added',
                            Location: 'Location',
                            Repeating: 'Repeating',
                            Registration: 'Registration',
                            Contact: 'Contact',
                            Yes: 'Yes',
                            No: 'No',
                            PaidEvent: 'Paid Event?',
                            ContinueToEventbrite: 'Continue to Eventbrite',
                            StayInHC: 'Stay in HC',
                            WhichWayToGo: 'Which page to go to?',
                            DateRange: 'Date Range',
                            Sort: 'Sort',
                            EventDateAscending: 'Event Date (Ascending)',
                            EventDateDescending: 'Event Date (Descending)',
                            EditCustomQuestions: 'Edit Custom Questions',
                            AddAttendeeDrawer: {
                                AddAttendee: 'Add Attendee',
                                New: 'New',
                                Person: 'Person',
                                Roster: 'Roster',
                                Search: 'Search',
                            },
                            AttendeeDetailsDrawer: {
                                Activity: 'Activity',
                                AllTime: 'All Time',
                                Apply: 'Apply',
                                AreYouSure: 'Are you sure?',
                                Attendance: 'Attendance',
                                Attended: 'Attended',
                                AttendeeStatus: 'Attendee Status',
                                AttendingNextOccurrence: 'Attending Next Occurrence?',
                                Birthday: 'Birthday',
                                Cancel: 'Cancel',
                                Cell: 'Cell',
                                ClearFilters: 'Clear Filters',
                                Contact: 'Contact',
                                DateAscending: 'Date Ascending',
                                DateDescending: 'Date Descending',
                                Delete: 'Delete',
                                Deleted: 'Deleted',
                                EditNote: 'Edit Note',
                                EditResponse: 'Edit Response',
                                Email: 'Email',
                                Filters: 'Filters',
                                FirstName: 'First Name',
                                Home: 'Home',
                                LastMonth: 'Last 30 Days',
                                LastName: 'Last Name',
                                LastWeek: 'Last 7 Days',
                                LessContactInfo: 'Less Contact Info',
                                MemberDetails: 'Member Details',
                                MoreContactInfo: 'More Contact Info',
                                NA: 'N/A',
                                NewEmail: 'New Email',
                                NewNote: 'New Note',
                                NextOccurrence: 'Next Occurrence',
                                No: 'No',
                                Note: 'Note',
                                Notes: 'Notes',
                                Occurrence: 'Occurrence',
                                Personal: 'Personal',
                                Post: 'Post',
                                PreferredContactMethod: '* Preferred Contact Method',
                                ResponseToCustomQuestions: 'Response to Custom Questions',
                                ResponseToCustomQuestionsDescription: 'This attendee\'s answers to the custom questions when they registered for this event occurrence.',
                                ResponseToQuestions: 'Response to Questions',
                                Save: 'Save',
                                Sort: 'Sort',
                                Timeline: 'Timeline',
                                Unattended: 'Unattended',
                                Work: 'Work',
                                Yes: 'Yes',
                            },
                            AttendeeEmailDrawer: {
                                All: 'All',
                                AreYouSure: 'Are you sure?',
                                AttachFile: 'Attach File',
                                Attachment: 'Attachment',
                                Cancel: 'Cancel',
                                Content: 'Content',
                                EmailSent: 'Email has been scheduled for delivery',
                                From: 'From',
                                Message: 'Message',
                                RecipientList: 'Recipient List',
                                Recipients: 'Recipients',
                                Reply: 'Reply',
                                Send: 'Send',
                                Sending: 'Sending',
                                Subject: 'Subject',
                                Title: 'New Email to SO attendees',
                                To: 'To',
                                Uploading: 'Uploading...',
                                View: 'View',
                            },
                            CheckIn: {
                                Actions: 'Actions',
                                AllRooms: 'All Spaces',
                                Capacity: 'Capacity',
                                CheckIn: 'Check-in',
                                CheckedIn: 'Checked-in',
                                Child: 'Child',
                                Closed: 'Closed',
                                EventDetails: 'Event Details',
                                General: 'General',
                                OpenRooms: 'Open Space(s)',
                                RoomName: 'Name',
                                Search: 'Search',
                                SendNotification: 'Send Notification',
                                TotalRooms: 'Total Space(s)',
                                Volunteers: 'Volunteers',
                                EventVolunteers: {
                                    AvailableRooms: 'Available Spaces',
                                    Cancel: 'Cancel',
                                    Capacity: 'Capacity',
                                    Category: 'Category',
                                    CheckIn: 'Check-in',
                                    Checkout: 'Checkout',
                                    Child: 'Child',
                                    Closed: 'Closed',
                                    EventVolunteers: 'Event Volunteers',
                                    General: 'General',
                                    Ministry: 'Ministry',
                                    Name: 'Name',
                                    Phone: 'Phone',
                                    Room: 'Space',
                                    RoomName: 'Name',
                                    Search: 'Search',
                                    SelectRoom: 'Select Space',
                                    ServingOpp: 'Serving Opp',
                                    ServingOpportunities: 'Serving Opportunities',
                                    ServingOpportunity: 'Serving Opportunity',
                                    SlotsFilled: 'Slots Filled',
                                    SlotsOpen: 'Slots Open',
                                    Volunteers: 'Volunteers',
                                    VolunteerCheckIn: 'Volunteer Check-in',
                                },
                                OpenRoomModal: {
                                    Cancel: 'Cancel',
                                    Child: 'Child',
                                    General: 'General',
                                    RequiresTwoVolunteers: '(requires two volunteers)',
                                    SelectType: 'Select a Check-in Type to Open the Space',
                                },
                                RoomAttendees: {
                                    AllRooms: 'All Spaces',
                                    ScanTag: 'Scan Tag',
                                    VolunteersAndRoom: 'Volunteers & Space',
                                },
                            },
                            CustomQuestionsDrawer: {
                                AddOption: 'Add an Option',
                                AddQuestions: 'Add Questions',
                                Cancel: 'Cancel',
                                CustomQuestions: 'Custom Questions',
                                CustomQuestionsHint: 'Your attendees will be asked these questions when they register for an event. Add new question to continue. Hit the save button when you are done',
                                Delete: 'Delete',
                                Done: 'Done',
                                Drag: 'Drag',
                                EditQuestions: 'Edit Questions',
                                NewQuestion: 'New Question',
                                Option: 'Option',
                                Options: 'Options',
                                PlaceholderText: 'Placeholder Text',
                                Question: 'Question',
                                QuestionType: 'Question Type',
                                RemoveOption: 'Remove an Option',
                                Required: 'Required',
                                Save: 'Save',
                                Settings: 'Settings',
                                Title: 'Title',
                                YourQuestion: 'Your Question',
                            },
                            ServingOppsEmailDrawer: {
                                All: 'All',
                                AreYouSure: 'Are you sure?',
                                AttachFile: 'Attach File',
                                Attachment: 'Attachment',
                                Cancel: 'Cancel',
                                Content: 'Content',
                                EmailSent: 'Email has been scheduled for delivery',
                                From: 'From',
                                Message: 'Message',
                                Reply: 'Reply',
                                Send: 'Send',
                                Sending: 'Sending',
                                Subject: 'Subject',
                                Title: 'New Email to SO contacts',
                                To: 'To',
                                Uploading: 'Uploading...',
                                View: 'View',
                            },
                            OccurrenceSchedule: {
                                Actions: 'Actions',
                                AddAsInterestedToMinistry: 'Add As Interested To Ministry',
                                AddToRoster: 'Add to Roster',
                                AreYouSure: 'Are you sure?',
                                Attendance: 'Attendance',
                                Attended: 'Attended',
                                BirthDate: 'Birth Date',
                                CancelOccurrence: 'Cancel Occurrence',
                                Capacity: 'Capacity',
                                Email: 'Email',
                                EventAttendeeExport: 'Event Attendee Export',
                                EventDetails: 'Event Details',
                                ExportOccurrenceRosterCsv: 'Export Occurrence Roster (CSV)',
                                ExportOccurrenceRosterXlsx: 'Export Occurrence Roster (XLSX)',
                                FirstName: 'First Name',
                                ForAllOccurrences: 'For All Occurrences',
                                ForThisOccurrence: 'For This Occurrence',
                                HomeCampus: 'Home Campus',
                                LastName: 'Last Name',
                                MarkAttended: 'Mark Attended',
                                MarkUnattended: 'Mark Unattended',
                                Name: 'Name',
                                NewEmail: 'New Email',
                                Occurrence: 'Occurrence',
                                OccurrenceSchedule: 'Occurrence Schedule',
                                OccurrenceSuccessfullyCanceled: 'Occurrence Successfully Canceled',
                                PersonsSuccessfullyAddedToMinistry: 'Persons Were Successfully Added To The Ministry',
                                PersonsSuccessfullyAddedToRoster: 'Persons Were Successfully Added To The Roster',
                                Registered: 'Registered',
                                RemoveFromAllOccurrences: 'Remove From All Occurrences',
                                RemoveFromThisOccurrence: 'Remove From This Occurrence',
                                Search: 'Search',
                                SelectACampus: 'Select a campus',
                                SortBy: 'Sort By',
                                Unattended: 'Unattended',
                                VolunteersSuccessfullyAttended: 'Volunteer(s) Successfully Attended',
                                VolunteersSuccessfullyRemoved: 'Volunteer(s) Successfully Removed',
                                VolunteersSuccessfullyUnattended: 'Volunteer(s) Successfully Unattended',
                            },
                            RegistrationRoster: {
                                Actions: 'Actions',
                                AddToRoster: 'Add to Roster',
                                BirthDate: 'Birth Date',
                                Email: 'Email',
                                EventDetails: 'Event Details',
                                ExportOccurrenceRosterCsv: 'Export Occurrence Roster (CSV)',
                                ExportOccurrenceRosterXlsx: 'Export Occurrence Roster (XLSX)',
                                AddAsInterestedToMinistry: 'Add As Interested To Ministry',
                                PersonsSuccessfullyAddedToMinistry: 'Persons Were Successfully Added To The Ministry',
                                PersonsSuccessfullyAddedToRoster: 'Persons Were Successfully Added To The Roster',
                                PersonsSuccessfullyDeleted: 'Persons Were SuccessfullyDeleted From Event',
                                FirstName: 'First Name',
                                HomeCampus: 'Home Campus',
                                LastName: 'Last Name',
                                Name: 'Name',
                                NewEmail: 'New Email',
                                RegistrationRoster: 'Registration Roster',
                                Registered: 'Registered',
                                RemoveFromEvent: 'Remove From Event',
                                Search: 'Search',
                                SelectACampus: 'Select a campus',
                                SelectAnOptions: 'Select an options',
                                SortBy: 'Sort By',
                                EventAttendeeExport: 'Event Attendee Export',
                            },
                            SelectVenueDrawer: {
                                Cancel: 'Cancel',
                                Capacity: 'Capacity',
                                Done: 'Done',
                                Name: 'Name',
                                Rooms: 'Space(s)',
                                SelectVenue: 'Select Venue(s)',
                                TotalMaxCapacity: 'Total Max. Capacity: {0}',
                                Venues: 'Venues',
                                VenuesHelp: 'Venues are defaulted to all spaces, click a venue to edit',
                            },
                            SelectVenueRoomDrawer: {
                                Cancel: 'Cancel',
                                Capacity: 'Capacity',
                                Category: 'Category',
                                Done: 'Done',
                                EventSuccessfullyUpdated: 'Event has been successfully updated',
                                Name: 'Name',
                                RoomsHelp: 'Please select space(s) for your event and click into the space to change capacity/category',
                                TotalCapacity: 'Space(s) Total Capacity: {0}',
                            },
                            ServingOpps: {
                                Actions: 'Actions',
                                AddMinistry: 'Add Ministry(s)',
                                Contact: 'Contact',
                                InvitedMinistry: 'Invited Ministry(s)',
                                Name: 'Name',
                                NewEmail: 'New Email',
                                Email: 'Email',
                                EventDetails: 'Event Details',
                                Help: 'Do you need help with serving opps? Request a ministry above by clicking “Add Ministry(s)”',
                                Ministrys: 'Ministry(s)',
                                Remove: 'Remove',
                                Search: 'Search',
                                ServingOpps: 'Serving Opps',
                                SuccessfullyInvited: 'Invites Sent To Ministry(s) to help with Serving Opps',
                                SuccessfullyRemoved: 'Invites Successfully Removed',
                            },
                            SelectMinistryDrawer: {
                                Apply: 'Apply',
                                AtoZ: 'A to Z',
                                Cancel: 'Cancel',
                                Category: 'Category',
                                Choose: 'Choose',
                                ChooseMinistry: 'Choose A Ministry',
                                ClearFilters: 'Clear Filters',
                                Filters: 'Filters',
                                InviteMessage: 'The selected ministry(s) leaders will receive a notification for this event.',
                                InviteTitle: 'Which ministry(s) will help create serving opps for this event?',
                                InviteToEvent: 'Invite to Event ({0})',
                                Matches: '{0} Match(es)',
                                Ministry: 'Ministry',
                                NewEmail: 'New Email',
                                Sort: 'Sort',
                                Tags: 'Tags',
                                ZtoA: 'Z to A',
                            },
                            EventDetailsSODrawer: {
                                AttachedSO: 'Attached Serving Opportunities',
                                Close: 'Close',
                                Description: 'Description',
                                Email: 'Email',
                                Name: 'Name',
                                NewEmail: 'New Email',
                            },
                            SelectContactDrawer: {
                                Add: 'Add',
                                Campus: 'Campus',
                                Cancel: 'Cancel',
                                Name: 'Name',
                                SearchForAUser: 'Search For a User',
                                UserHasNoPublicContactInfo: 'User Has No Public Contact Info',
                                UserAlreadyExists: 'User already exists',
                            },
                            VenueRoomSettingsDrawer: {
                                Cancel: 'Cancel',
                                Done: 'Done',
                                EventSuccessfullyUpdated: 'Event has been successfully updated',
                                RoomCapacity: 'Space Capacity',
                                RoomCapacityHelp: 'Assign a capacity to your space',
                            },
                        },
                        MinistriesSearch: {
                            AcceptingNew: 'Accepting New Members',
                            Active: 'Active',
                            CoLeader: 'Co-Leader',
                            Description: 'Description',
                            Filter: 'Filter',
                            HideDescription: 'Hide Description',
                            ID: 'ID',
                            Inactive: 'Inactive',
                            Interested: 'Interested Volunteers',
                            InitialContact: 'Initial Contact',
                            Leader: 'Leader',
                            Leadership: 'Leadership',
                            LessDetails: 'Less Details',
                            MoreDetails: 'More Details',
                            NoResultsMessage: 'No Ministries found',
                            PageTitle: 'Search Ministries',
                            Pending: 'Pending',
                            SecondContact: 'Second Contact',
                            StaffConnector: 'Staff Connector',
                            TotalRecordsFound: 'Total Ministries Found:',
                            ViewLeader: 'View Leader',
                            ViewMinistry: 'View Ministry',
                            ViewDescription: 'View Description',
                            WhoLookingFor: 'Search Ministries',
                        },
                        MinistryView: {
                            as: 'as',
                            AcceptingNew: 'Accepting New Members',
                            added: 'added',
                            AddLeaders: 'Add Leader',
                            AddCoLeaders: 'Add Co-Leader',
                            AddStaffConnectors: 'Add Staff Connector',
                            Action: 'Action',
                            Active: 'Active',
                            Advertise: 'Advertise on Web',
                            AgeGroup: 'Age Group',
                            Age: 'Age',
                            Apply: 'Apply',
                            ApplyBulkAction: 'Apply Bulk Action',
                            AreYouSure: 'Are you sure to apply this option?',
                            Campus: 'Campus',
                            Cancel: 'Cancel',
                            Change: 'Change',
                            ChangeLeaders: 'Change Leaders',
                            Category: 'Category',
                            Cell: 'Cell',
                            CoLeader: 'Co-Leader',
                            Department: 'Department',
                            Description: 'Description',
                            Details: 'Details',
                            Email: 'Email',
                            Female: 'Female',
                            FromMinistry: 'from this Ministry?',
                            Gender: 'Gender',
                            Group: 'Group',
                            Groups: 'Groups',
                            GroupRemoved: 'Group Removed',
                            Id: 'ID',
                            Inactive: 'Inactive',
                            Leader: 'Leader',
                            Leadership: 'Leadership',
                            Location: 'Location',
                            Male: 'Male',
                            Manage: 'Manage',
                            MaxAge: 'Max Age',
                            Members: 'Members',
                            Membership: 'Membership',
                            MembershipSettings: 'Membership Settings',
                            Milestones: 'Milestones',
                            MinAge: 'Min Age',
                            MinistryDetails: 'Ministry Details',
                            MinistrySettings: 'Ministry Settings',
                            AddToMembers: 'Add To Members',
                            NewEmail: 'New Email',
                            NewRole: 'New Role',
                            NewGroup: 'New Group',
                            No: 'No',
                            Overview: 'Ministry Overview',
                            PageTitle: 'View Ministry',
                            Phone: 'Phone',
                            Primary: 'Primary',
                            PrimaryEmail: 'Primary Email',
                            PrimaryPhone: 'Primary Phone',
                            Public: 'Public',
                            PublicEmail: 'Public Email',
                            PublicPhone: 'Public Phone',
                            PurposeStatement: 'Purpose Statement',
                            Remove: 'Remove',
                            Role: 'Role',
                            Roles: 'Roles',
                            RoleRemoved: 'Role Removed',
                            SearchForPerson: 'Search for a Person',
                            SaveSettings: 'Save Settings',
                            SelectStatus: 'Select Status',
                            ServingOpportunities: 'Serving Opportunities',
                            Settings: 'Settings',
                            SettingsSaved: 'Ministry Location Settings have been saved.',
                            ShortDescription: 'Short Description',
                            Staff: 'Staff Connector',
                            StaffConnector: 'Staff Connector',
                            Status: 'Status',
                            UpdateStatus: 'Update status',
                            ViewRecord: 'View Record',
                            Volunteers: 'Volunteers',
                            Yes: 'Yes',
                            Website: 'Website',
                            SendInterestedToLeader: 'Send Interested emails to Leader',
                            SendInterestedToColeader: 'Send Interested emails to Co-Leader',
                            SendInterestedToStaffConnector: 'Send Interested emails to Staff Connector',
                            Notifications: 'Notifications',
                            Ineligible: 'Ineligible',
                            Insights: 'Insights',
                            InterestedVol: 'Interested Volunteers',
                            InitialContact: '1st Contact',
                            InTraining: 'In Training',
                            NotContacted: 'Not Contacted',
                            Pending: 'Pending',
                            SecondContact: '2nd Contact',
                        },
                        MinistryManager: {
                            Advertise: 'Advertise on Web',
                            Cancel: 'Cancel',
                            Category: 'Category',
                            DeleteMinistry: 'Delete Ministry',
                            Description: 'Description',
                            Deleted: 'Deleted',
                            Done: 'Done',
                            Edit: 'Edit',
                            EditMinistry: 'Edit Ministry',
                            InvalidEntries: 'Please fill in all fields.',
                            MaxAge: 'Max Age',
                            MemberPrefs: 'Member Preferences',
                            MinAge: 'Min Age',
                            Ministry: 'Ministry',
                            MinistryDetails: 'Ministry Details',
                            MinistryName: 'Ministry Name',
                            MinistrySaved: 'Ministry Saved',
                            MinistryTags: 'Ministry Tags',
                            Name: 'Name',
                            NewEmail: 'New Email',
                            No: 'No',
                            Overview: 'Ministry Overview',
                            PageTitle: 'Add Ministry',
                            PrimaryEmail: 'Primary Email',
                            PrimaryPhone: 'Primary Phone',
                            PublicEmail: 'Public Email',
                            PublicPhone: 'Public Phone',
                            PurposeStatement: 'Ministry Purpose Statement',
                            Save: 'Save',
                            SelectCategory: 'Select a Category',
                            SelectMinistry: 'Select Parent Ministry',
                            SelectStatus: 'Select Ministry Status',
                            ShortDescription: 'Short Description',
                            SmsName: 'SMS Name',
                            States: 'States',
                            Status: 'Status',
                            Tags: 'Tags',
                            Leaders: 'Leaders',
                            Leader: 'Leader',
                            AddLeader: 'Add Leader',
                            added: 'added',
                            Email: 'Email',
                            Cell: 'Cell',
                            Change: 'Change',
                            UpdateMinistry: 'Update Ministry Settings',
                            URL: 'URL',
                            Yes: 'Yes',
                            WasCreated: 'Was Created',
                            MembershipSettings: 'Membership Settings',
                            Age: 'Age',
                            Gender: 'Gender',
                            Milestones: 'Milestones',
                            Male: 'Male',
                            Female: 'Female',
                            Notifications: 'Notifications',
                            Remove: 'Remove',
                            SendInterestedToLeader: 'Send Interested emails to Leader',
                            SendInterestedToColeader: 'Send Interested emails to Co-Leader',
                            SendInterestedToStaffConnector: 'Send Interested emails to Staff Connector',
                            ClosingMinistry1: '{0} location has open Serving Opportunities and/or non-empty Interested Queue. Please fix this first.',
                            ClosingMinistry2: '{0} and {1} locations have open Serving Opportunities and/or non-empty Interested Queues. Please fix this first.',
                            ClosingMinistry3: '{0}, {1} and {2} more locations have open Serving Opportunities and/or non-empty Interested Queues. Please fix this first.',
                            UnpublishMinistry1: '{0} location has advertised on Web. Please fix this first.',
                            UnpublishMinistry2: '{0} and {1} locations have advertised on Web. Please fix this first.',
                            UnpublishMinistry3: '{0}, {1} and {2} more locations have advertised on Web. Please fix this first.',
                            UnpublishLoc1: 'The current Ministry is not advertised on Web. Please fix this first.',
                            UnpublishLoc2: 'There {0} Serving Opportunities are advertised on Web. Please turn it off for all Serving opportunities first.',
                            DeactivateMinistryPrompt1: '{0} of Serving Opportunities will be closed',
                            DeactivateMinistryPrompt2: ' and ',
                            DeactivateMinistryPrompt3: '{0} of Ministry Locations will be inactivated',
                            DeactivateMinistryPrompt4: 'The ministry will be inactivated',
                            DeactivateLocationPrompt1: '{0} of Serving Opportunities will be closed',
                            DeactivateLocationPrompt2: 'The location will be inactivated',
                            AreYouSure: 'Are you sure?',
                        },
                        MinistryVolunteers: {
                            Active: 'Active',
                            AddPersonButton: 'Add Person',
                            AddPersonAsInterested: 'Add a Person as Interested',
                            AsInterested: 'As Interested',
                            AtAGlance: 'At A Glance',
                            FirstContact: '1st Contact',
                            GroupsTabLabel: 'Manage Groups',
                            InterestedTabLabel: 'Interested',
                            Inactive: 'Inactive',
                            InTraining: 'In Training',
                            ServingTabLabel: 'Serving Overview',
                            MembersTabLabel: 'Members',
                            Members: 'Members',
                            MembershipStatus: 'Membership Status',
                            ManageTabLabel: 'Manage',
                            NotContacted: 'Not Contacted',
                            PageTitle: 'Ministry Volunteers',
                            Pending: 'Pending',
                            ToRoster: 'To Roster',
                            Total: 'Total',
                            SecondContact: '2nd Contact',
                            SortBy: 'Sort By',
                            MembersTab: {
                                Actions: 'Actions',
                                ApplyFilters: 'Apply Filter',
                                AreYouSure: 'Are you sure',
                                Campus: 'Campus',
                                Contacted: 'Contacted',
                                Days: 'Days',
                                DateAdded: 'Date Added',
                                DaysInStatus: 'Days in Status',
                                Email: 'Email',
                                Female: 'Female',
                                Filters: 'Filters',
                                FindAMember: 'Find People or Groups',
                                Gender: 'Gender',
                                Hours: 'Hours',
                                Last: 'Last',
                                Male: 'Male',
                                Membership: 'Membership',
                                MembershipStatus: 'Membership Status',
                                AddToMembers: 'Add To Members',
                                Name: 'Name',
                                FirstName: 'First Name',
                                LastName: 'Last Name',
                                Role: 'Role',
                                Roles: 'Roles',
                                Search: 'Search',
                                Served: 'Served',
                                SMS: 'SMS',
                                Status: 'Status',
                                Source: 'Source',
                                Text: 'Text',
                                Total: 'Total',
                                LoadMore: 'Load More',
                                NewEmail: 'New Email',
                                NewSms: 'New SMS Message',
                                UpdateStatus: 'Update status',
                                AddInterestedButton: 'Add Interested',
                                AddMemberButton: 'Add Member',
                                Remove: 'Remove from ministry',
                                BulkActions: 'Bulk Actions',
                                Export: 'Export',
                                ExportXLSX: 'Export XLSX',
                                ExportCSV: 'Export CSV',
                            },
                            NewVolunteer: {
                                AddPersonToRoster: 'Add Person To Roster',
                                AreYouSure: 'Are You Sure',
                                Cancel: 'Cancel',
                                HowClose: 'How close are they to becoming a member?',
                                InterestedButton: 'Interested',
                                InterestedHow: 'How did this person become interested in the ministry?',
                                MemberButton: 'Member',
                                New: 'New',
                                Next: 'Next',
                                Person: 'Person',
                                Save: 'Save',
                                Select: 'Select',
                                Search: 'Search',
                                Source: 'Source',
                                Status: 'Status',
                                SuccessfullySaved: 'Successfully saved',
                                ToInterested: 'to Interested Roster',
                                MinistryMember: 'Ministry Member',
                                AddPersonAsInterested: 'Add Person as Interested',
                                Ministry: 'Ministry',
                            },
                            NewOpportunityVolunteer: {
                                AddPersonToRoster: 'Add Person To Roster',
                                Cancel: 'Cancel',
                                HowClose: 'How close are they to becoming a member?',
                                InterestedButton: 'Interested',
                                InterestedHow: 'How did this person become interested in the Opportunity?',
                                MemberButton: 'Member',
                                New: 'New',
                                Next: 'Next',
                                Person: 'Person',
                                Save: 'Save',
                                Select: 'Select',
                                Search: 'Search',
                                Source: 'Source',
                                Status: 'Status',
                                SuccessfullySaved: 'Successfully Saved',
                                SuccessfullyRemoved: 'has been Successfully Removed',
                                SuccessfullyUpdated: 'has been Successfully Updated',
                                IsNowCommitted: 'is now Committed to the Opportunity',
                                ToInterested: 'to Interested Roster',
                                MinistryMember: 'Ministry Member',
                                CommitToOpportunity: 'Commit to Opportunity',
                                RemoveAsInterested: 'Remove as Interested',
                                Email: 'Email',
                                Actions: 'Actions',
                                Opportunity: 'Opportunity',
                                InterestedDetails: 'Interested Details',
                                HomeCampus: 'Home Campus',
                                Source: 'Source',
                                About: 'About',
                                Personal: 'Personal',
                                FirstName: 'First Name',
                                LastName: 'Last Name',
                                Birthday: 'Birthday',
                                Contact: 'Contact',
                                Address: 'Address',
                                Cell: 'Cell',
                                Ministry: 'Ministry',
                                Membership: 'Membership',
                                Eligible: 'Eligible',
                                Interested: 'Interested',
                                InterestedStatus: 'Interested Status',
                                AddToMembers: 'Add to Ministry Members',
                                RemoveFromOpportunity: 'Remove from Opportunity',
                                ServingDetails: 'Serving Details',
                                MembershipDetails: 'Membership Details',
                                SetSchedule: 'Set Schedule',
                                EditSchedule: 'Edit Schedule',
                                HoursServed: 'Hours Served',
                                Roles: 'Role(s)',
                                Groups: 'Group(s)',
                                AreYouSure: 'Are you sure?',
                                MinistryMembers: 'Ministry Members',
                                MemberDetails: 'Member Details',
                                Schedule: 'Schedule',
                                Committed: 'Committed',
                                Interested: 'Interested',
                                Roster: 'Roster',
                                NA: 'N/A',
                                Phone: 'Phone',
                                PersonAlreadyExistsIn: 'Person already exists in SO Roster',
                            },
                            NewEmail: {
                                AreYouSure: '<{0}> is not a Healthy Church address. Are you sure you want to use it as reply-to email?',
                                AttachmentIsTooLarge: 'The attachment is too large.',
                                Campus: 'Campus',
                                Cancel: 'Cancel',
                                Content: 'Content',
                                EmailSent: 'Email has been scheduled for delivery',
                                From: 'From',
                                Groups: 'Groups',
                                Message: 'Message',
                                Name: 'Name',
                                Position: 'Position',
                                Recipients: 'Recipients',
                                Reply: 'Reply To Email',
                                Subject: 'Subject',
                                Roles: 'Roles',
                                Send: 'Send',
                                Sending: 'Sending',
                                RecipientList: 'Recipient List',
                                Remove: 'Remove',
                                Title: 'New Email',
                                Total: 'Total',
                                To: 'To',
                                View: 'View',
                                CommittedVolunteers: 'Committed Volunteers',
                                InterestedVolunteers: 'Interested Volunteers',
                                SendAllMembers: 'All ministry members ({0} recipients)',
                                SendAllVolunteers: 'All opportunity volunteers ({0} recipients)',
                                UserHasDoNotTextPreferences: 'This person is unable to receive SMS messages due to their contact preferences',
                                UserHasNoPublicContactSmsInfo: 'This person does not have a cell phone on record',
                            },
                            NewSmsMessage: {
                                AreYouSure: 'Are you sure you want to send SMS?',
                                Cancel: 'Cancel',
                                CommittedVolunteers: 'Committed Volunteers',
                                Groups: 'Groups',
                                InterestedVolunteers: 'Interested Volunteers',
                                Position: 'Position',
                                RecipientList: 'Recipient List',
                                Recipients: 'Recipients',
                                Remove: 'Remove',
                                Roles: 'Roles',
                                Send: 'Send',
                                Sending: 'Sending',
                                SmsSent: 'SMS has been scheduled for delivery',
                                Title: 'New SMS Message',
                                To: 'To',
                                View: 'View',
                            },
                            SelectPerson: {
                            },
                            VolunteerDetails: {
                                AboutInterestedTabLabel: 'Personal Details',
                                AboutTabLabel: 'Member Details',
                                ActivityFeedTabLabel: 'Activity',
                                MissingRequirements: 'Missing Requirements',
                                NotesTabLabel: 'Notes',
                                PageTitle: 'Volunteer Details',
                                StatusTabLabel: 'Status',
                                View: 'View',
                                ActivityFeed: {
                                    ActivityFeed: 'Activity Feed',
                                    AddNote: 'New Note',
                                    Added: 'Added',
                                    As: 'as',
                                    EditNote: 'Edit Note',
                                    StatusChangedTo: 'Changed Status to',
                                },
                                AboutTab: {
                                    About: 'About',
                                    Address: 'Address',
                                    AddToMembers: 'Add To Members',
                                    Actions: 'Actions',
                                    Attributes: 'S.H.A.P.E Attributes',
                                    Availability: 'Serving Availability',
                                    Birthday: 'Birthday',
                                    Cell: 'Cell',
                                    ChangeStatus: 'Change Status',
                                    Contact: 'Contact',
                                    DateAdded: 'Date Added',
                                    DaysInStatus: 'Days in Status',
                                    Deceased: 'Deceased',
                                    Details: 'Details',
                                    DoNotContact: 'Do Not Contact',
                                    Edit: 'Edit',
                                    EditMember: 'Edit Member',
                                    Email: 'Email',
                                    FirstName: 'First Name',
                                    Group: 'Group',
                                    InterestedStatus: 'Interested Status',
                                    LastName: 'Last Name',
                                    Membership: 'Membership',
                                    Milestones: 'Milestones',
                                    Ministries: 'Ministries',
                                    Ministry: 'Ministry',
                                    Manage: 'Manage',
                                    NewEmail: 'New Email',
                                    Personal: 'Personal',
                                    Remove: 'Remove',
                                    RemoveAsInterested: 'Remove as Interested',
                                    RemoveFromMinistry: 'Remove From Ministry',
                                    Role: 'Role',
                                    Source: 'Source',
                                    Status: 'Status',
                                    SuccessfullyRemoved: 'Successfully removed',
                                    SuccessfullySaved: 'Successfully saved',
                                },
                                ManageTab: {
                                    AddNoteButton: 'New Note',
                                    AddPersonToRoster: 'Add Person to Roster:',
                                    AddRoleButton: 'Add Role',
                                    Availability: 'Availability',
                                    Cancel: 'Cancel',
                                    CancelButton: 'Cancel',
                                    Committed: 'Committed',
                                    Groups: 'Groups',
                                    Interested: 'Interested',
                                    Notes: 'Notes',
                                    NowMember: 'is now a Ministry Member!',
                                    RemoveButton: 'Remove',
                                    SaveButton: 'Save',
                                    Roles: 'Roles',
                                    Save: 'Save',
                                    SaveChanges: 'Save Changes?',
                                    SelectStatus: 'Select Status',
                                    SelectRole: 'Select Role',
                                    Status: 'Status',
                                    SuccessfullyAdded: 'has been Successfully Added',
                                    SuccessfullySaved: 'Settings Saved for',
                                    Tags: 'Tags',
                                },
                                NotesTab: {
                                    AddNoteButton: 'New Note',
                                    AreYouSure: 'Are You Sure?',
                                    CancelButton: 'Cancel',
                                    EditButton: 'Edit Note',
                                    EditNote: 'Edit Note',
                                    Notes: 'Notes',
                                    Note: 'Note',
                                    Post: 'Post',
                                    SaveButton: 'Save',
                                },
                            },
                        },
                        MinistrySettings: {
                            AddCategory: 'Add Category',
                            AddTag: 'Add Tag',
                            AlreadyExists: 'already exists',
                            Categories: 'Categories',
                            CategoriesAndTags: 'Categories & Tags',
                            Category: 'Category',
                            CategorySaved: 'Category Saved',
                            CentralLevelSettings: 'Manage Central Level Settings',
                            File: 'File',
                            PageTitle: 'Ministry Settings',
                            PleaseEnterValue: 'Please enter a value.',
                            Settings: 'Settings',
                            Tags: 'Tags',
                            TagSaved: 'Tag Saved',
                            URL: 'URL',
                        },
                        MinistryLocations: {
                            AcceptingNew: 'Accepting New Members',
                            Active: 'Active',
                            added: 'added',
                            AddLocation: 'Add Location',
                            AddALocation: 'Add A Location',
                            AddCoLeaders: 'Add Co-Leader',
                            AddLeaders: 'Add Leader',
                            AddStaffConnectors: 'Add Staff Connectors',
                            AddMore: 'Add More',
                            Advertise: 'Advertise on Web',
                            Age: 'Age',
                            All: 'All',
                            AllLocations: 'All Locations',
                            BulkActions: 'Bulk Actions',
                            Campus: 'Campus',
                            Cancel: 'Cancel',
                            Cell: 'Cell',
                            Change: 'Change',
                            Close: 'Close',
                            CoLeader: 'Co-Leader',
                            Date: 'Date',
                            Description: 'Description',
                            Edit: 'Edit',
                            Email: 'Email',
                            EmailStatistics: 'Email Statistics',
                            Error: 'Error',
                            Errors: 'Failed',
                            Female: 'Female',
                            FirstContact: '1st Contact',
                            History: 'History',
                            Gender: 'Gender',
                            GlobalError: 'All e-mails failed to send or skipped.',
                            Leader: 'Leader',
                            LeadershipInfo: 'Leadership Information',
                            ID: 'ID',
                            Inactive: 'Inactive',
                            Ineligible: 'Ineligible',
                            Insights: 'Insights',
                            Interested: 'Interested',
                            InterestedVol: 'Interested Volunteers',
                            InitialContact: '1st Contact',
                            InTraining: 'In Training',
                            Leadership: 'Leadership',
                            LeadershipPositions: 'Leadership Positions',
                            Male: 'Male',
                            MaxAge: 'Max Age',
                            Members: 'Members',
                            MembershipSettings: 'Membership Settings',
                            Milestones: 'Milestones',
                            MinAge: 'MinAge',
                            MinistryDetails: 'Ministry Details',
                            MinistrySettings: 'Ministry Settings',
                            MonthAvg: 'Monthly Average',
                            Name: 'Name',
                            NotContacted: 'Not Contacted',
                            No: 'No',
                            None: 'None',
                            NoLocations: 'No locations have been added yet for this Ministry.',
                            NewEmail: 'New Email',
                            NumberOfRetries: '# of Retries',
                            PageTitle: 'Locations',
                            Pending: 'Pending',
                            Personal: 'Personal',
                            PurposeStatement: 'Purpose Statement',
                            Remove: 'Remove',
                            Save: 'Save',
                            SecondContact: '2nd Contact',
                            Select: 'Select',
                            SelectALeader: 'Select a Leader',
                            SelectStatus: 'Select Status',
                            ChangeStatus: 'Change Status',
                            Sent: 'Sent',
                            SentBy: 'Sent by',
                            Sending: 'Sending',
                            ServingHours: 'Serving Hours',
                            ShortDescription: 'Short Description',
                            Skipped: 'Skipped',
                            SMS: 'SMS',
                            StaffConnector: 'Staff Connector',
                            States: 'States',
                            Status: 'Status',
                            ThisMonth: 'This Month',
                            Training: 'Training',
                            ViewDashboard: 'View Dashboard',
                            ViewMinistry: 'View Ministry',
                            VolHours: 'Volunteer Hours',
                            Yes: 'Yes',
                            Notifications: 'Notifications',
                            SendInterestedToLeader: 'Send Interested emails to Leader',
                            SendInterestedToColeader: 'Send Interested emails to Co-Leader',
                            SendInterestedToStaffConnector: 'Send Interested emails to Staff Connector',
                            Subject: 'Subject',
                            Total: 'Total',
                            MinistryRoleGroups: {
                                Add: 'Add',
                                All: 'All',
                                AddPerson: 'Add Person',
                                Cancel: 'Cancel',
                                DateAdded: 'Date Added',
                                Delete: 'Delete',
                                Details: 'Details',
                                Edit: 'Edit',
                                EditGroup: 'Edit Group',
                                EditRole: 'Edit Role',
                                FirstName: 'First Name',
                                Group: 'Group',
                                GroupAdded: 'Group Has Been Added',
                                GroupSaved: 'Group Has Been Updated',
                                GroupMembersRemoved: 'Group Member Removed',
                                GroupMembersSaved: 'Group Members Updated',
                                LastName: 'Last Name',
                                Location: 'Location',
                                Name: 'Name',
                                No: 'No',
                                MinistryMember: 'Ministry Member',
                                Remove: 'Remove',
                                Role: 'Role',
                                RoleAdded: 'Role Has Been Added',
                                RoleSaved: 'Role Has Been Updated',
                                RoleMembersRemoved: 'Role Member Removed',
                                RoleMembersSaved: 'Role Members Updated',
                                SelectMembers: 'Select Members',
                                Save: 'Save',
                                Yes: 'Yes',
                            },
                        },
                        MinistryTraining: {
                            PageTitle: 'Training',
                        },
                        MinistryWebSettings: {
                            AddLeader: 'Add Leader Contact',
                            AddMeetingTime: 'Add Meeting Time',
                            AdSliderId: 'Ad Slider ID',
                            AdSliderIdInvalid: 'Ad Slider ID does not appear to be valid',
                            Advertise: 'Advertise on Web',
                            AlternateWebsite: 'Alternate Website',
                            AlternateWebsiteImageURL: 'Alternate Website Image URL',
                            AlternateWebsiteURLInvalid: 'Alternate Website URL must be valid format, i.e. http://www.somewhere.com/',
                            AlternateWebsiteImageURLInvalid: 'Alternate Website Image URL must be valid format, i.e. http://www.somewhere.com/imagefile.jpg',
                            AlternateWebsiteDescription: 'Alternate Website Description',
                            AlternateWebsiteImage: 'Alternate Website Image',
                            AlternateWebsiteTitle: 'Alternate Website Title',
                            AlternateWebsiteUrl: 'Alternate Website URL',
                            BellsAndWhistles: 'Bells & Whistles',
                            BlogId: 'Blog ID',
                            BlogIdInvalid: 'Blog ID does not appear to be valid',
                            BlueHornetId: 'Blue Hornet Segment ID',
                            BlueHornetIdInvalid: 'Blue Hornet Segment ID does not appear to be valid; it must be a number',
                            CustomHTML: 'Custom HTML',
                            Day: 'Day',
                            Description: 'Description',
                            Email: 'Email',
                            EndTime: 'End Time',
                            File: 'File',
                            FullName: 'Full Name',
                            GlobalInfo: 'Global Information',
                            Global: 'Global',
                            IsRequired: 'is required',
                            IsService: 'Is this a church service?',
                            LeaderContact: 'Leader Contact',
                            LeaderContactInvalid: 'Leader contact must have a valid email or phone number',
                            Local: 'Local',
                            LocalInfo: 'Locational Information',
                            Masthead: 'Masthead',
                            MastheadURL: 'Masthead URL',
                            MastheadURLInvalid: 'Masthead URL must be valid format, i.e. http://www.somewhere.com/imagefile.jpg',
                            MeetingLocation: 'Meeting Location',
                            MeetingTimes: 'Meeting Times',
                            MinistryName: 'Ministry Name',
                            MinistrySaved: 'Ministry Web Settings Saved',
                            MinistryTags: 'Ministry Tags',
                            PageTitle: 'Web Settings',
                            Phone: 'Phone',
                            Published: 'Advertise on Web',
                            PurposeStatement: 'Purpose Statement',
                            RelatedMinistries: 'Related Ministries',
                            RelatedStoryTags: 'Related Story Tags',
                            Remove: 'Remove',
                            Save: 'Save Web Settings',
                            SelectALeader: 'Select a Leader',
                            SelectDay: 'Select Day',
                            SelectEndTime: 'Select End Time',
                            SelectStartTime: 'Select Start Time',
                            SelectTimeZone: 'Select Time Zone',
                            ShortDescription: 'Short Description',
                            SocialNetworking: 'Social Networking',
                            StartTime: 'Start Time',
                            Tags: 'Tags',
                            Thumbnail: 'Thumbnail',
                            ThumbnailURL: 'Thumbnail URL',
                            ThumbnailURLInvalid: 'Thumbnail URL must be valid format, i.e. http://www.somewhere.com/imagefile.jpg',
                            TimeZone: 'Time Zone',
                            UpdateMinistry: 'Update Ministry Settings',
                            URL: 'URL',
                            UserName: 'User Name',
                            WasCreated: 'Was Created',
                            WufooForm: 'Wufoo Form',
                        },
                        MinistryOpportunities: {
                            ButtonNewOpportunity: 'New Opportunity',
                            PageTitle: 'Serving Opportunity',
                            TabRecurring: 'Recurring',
                            TabOneTime: 'One-Time',
                            TabClosed: 'Closed',
                            FindOpportunity: 'Find Opportunity',
                            Date: 'Date',
                            Times: 'Times',
                            Needed: 'Needed',
                            Committed: 'Committed',
                            Campus: 'Campus',
                            Venue: 'Venue',
                            DaysAndTimes: 'Days & Times',
                            NextVolunteers: 'Next Volunteers',
                            Where: 'Where',
                            Volunteers: 'Volunteers',
                            Served: 'Served',
                            TotalHours: 'Total Hours',
                            EndedOn: 'Ended On',
                            LoadMore: 'Load More',
                            Filters: 'Filters',
                            Interested: 'Interested',
                            FilledSlots: 'Filled Slots',
                            OpenSlots: 'Open Slots',
                            ActiveOpportunities: 'Active Opportunities',
                            ClosedOpportunities: 'Closed Opportunities',
                            RecurringOpportunity: 'Recurring Opportunity',
                            OneTimeOpportunity: 'One-Time Opportunity',
                            NextOccurrence: 'Next Occurrence',
                            OccurrsOn: 'Occurrs On',
                            At: 'At',
                            AddToMinistry: 'Add to Ministry',
                            AddVolunteer: 'Add Volunteer',
                            Actions: 'Actions',
                            Search: 'Search',
                            AsUnscheduled: 'As Unscheduled',
                            AsScheduled: 'As Scheduled',
                            AddVolunteerAsInterested: 'Add as Interested',
                            AddVolunteerAsCommitted: 'Add as Committed',
                            SuccessfullySaved: 'Successfully Saved',
                            SuccessfullyAdded: 'Selected person(s) successfully added to this Serving Opportunity',
                            Email: 'Email',
                            SelectAnOptions: 'Select an option',
                            SortBy: 'Sort By',
                            EndingFirst: 'Ending First',
                            ExportFullRosterCsv: 'Export Full Roster (CSV)',
                            ExportFullRosterXlsx: 'Export Full Roster (XLSX)',
                            OpportunityEmail: 'Email volunteers in serving opportunities',
                            ServingOpportunityEmail: {
                                AreYouSure: '<{0}> is not a Healthy Church address. Are you sure you want to use it as reply-to email?',
                                AttachmentIsTooLarge: 'The attachment is too large.',
                                Campus: 'Campus',
                                Cancel: 'Cancel',
                                Content: 'Content',
                                EmailSent: 'Email has been scheduled for delivery',
                                From: 'From',
                                Groups: 'Groups',
                                Message: 'Message',
                                Name: 'Name',
                                Position: 'Position',
                                Reply: 'Reply To Email',
                                Subject: 'Subject',
                                Send: 'Send',
                                Sending: 'Sending',
                                Remove: 'Remove',
                                Title: 'New Email to Serving Opportunity Volunteers',
                                To: 'To',
                                View: 'View',
                            },
                        },
                        MinistryOpportunityDetails: {
                            Actions: 'Actions',
                            AddFilter: 'Add Filter',
                            Details: 'Details',
                            DoNotContact: 'Do Not Contact',
                            EmailStatistics: 'Email Statistics',
                            Subject: 'Subject',
                            Sent: 'Sent',
                            Sending: 'Sending',
                            Errors: 'Failed',
                            Skipped: 'Skipped',
                            History: 'History',
                            Volunteers: 'Volunteers',
                            ManageSchedule: 'Manage Schedule',
                            PublishingSettings: 'Publishing Settings',
                            Back: 'Back',
                            WhatAndWhy: 'What & Why',
                            When: 'When',
                            Where: 'Where',
                            Edit: 'Edit',
                            EditMember: 'Edit Member',
                            Description: 'Description',
                            ShortDescription: 'Short Description',
                            Verse: 'Verse',
                            Tags: 'Tags',
                            Event: 'Event',
                            OpportunityContact: 'Opportunity Contact',
                            Lifespan: 'Lifespan',
                            CustomLocation: 'Custom Location',
                            DaysAndTimes: 'Days & Times',
                            StartDate: 'Start Date',
                            EndDate: 'End Date',
                            Months: 'Months',
                            Weeks: 'Weeks',
                            ButtonNewVolunteer: 'Add Volunteer',
                            AddVolunteerAsInterested: 'Add a Volunteer as Interested',
                            AddVolunteerAsCommitted: 'Add a Volunteer as Committed',
                            Filters: 'Filters',
                            FindInterestedVolunteers: 'Find Interested Volunteers',
                            FindCommittedVolunteers: 'Find Committed Volunteers',
                            TabCommitted: 'Committed',
                            TabInterested: 'Interested',
                            SuccessfullySaved: 'Successfully Added',
                            AsInterested: 'As Interested',
                            AsCommitted: 'As Committed',
                            Status: 'Status',
                            Schedule: 'Schedule',
                            ServingStatus: 'Serving Status',
                            MinistryMember: 'Ministry Member',
                            HomeCampus: 'Home Campus',
                            HoursServed: 'Hours Served',
                            Yes: 'Yes',
                            No: 'No',
                            NextOccurrence: 'Next Occurrence',
                            Date: 'Date',
                            Times: 'Times',
                            Live: 'Live',
                            Unpublished: 'Unpublished',
                            SchedulingSettingsUpdated: 'Scheduling Settings Successfully Updated',
                            EmailVolunteers: 'Email Volunteers?',
                            NewEmail: 'New Email',
                            CloseOpportunity: 'Close Opportunity',
                            CopyOpportunity: 'Copy Opportunity',
                            ResetDraft: 'SO draft already exists. Overwrite',
                            SuccessfullyClosed: 'Serving Opportunity Successfully Closed',
                            SuccessfullyCopied: 'Serving Opportunity Successfully Copied',
                            PublishSOError: 'The current location is not advertised on Web. Please fix this first.',
                            Venue: 'Venue',
                            Name: 'Name',
                            FirstName: 'First Name',
                            LastName: 'Last Name',
                            Latitude: 'Latitude',
                            Longitude: 'Longitude',
                            Email: 'Email',
                            Address: 'Address',
                            Map: 'Map',
                            Total: 'Total',
                            Source: 'Source',
                            BulkActions: 'Bulk Actions',
                            MoveToCommitted: 'Move to Committed',
                            AddToMembers: 'Add to Ministry Members',
                            ApplyBulkAction: 'Apply Bulk Action',
                            AreYouSure: 'Are you sure',
                            ServingOpportunities: 'Serving Opportunities',
                            ServingOpportunity: 'Serving Opportunity',
                            FilledSlots: 'Filled Slots',
                            OpenSlots: 'Open Slots',
                            Interested: 'Interested',
                            Committed: 'Committed',
                            ActiveOpportunities: 'Active Opportunities',
                            RosterDetails: 'Roster Details',
                            ActivityAndStatus: 'Activity & Status',
                            Served: 'Served',
                            Ministry: 'Ministry',
                            Hours: 'Hours',
                            OpportunityDetails: 'Opportunity Details',
                            ContactDetails: 'Contact Details',
                            Remove: 'Remove',
                            Phone: 'Phone',
                            RemoveFromOpportunity: 'Remove from Opportunity',
                            SuccessfullyRemoved: 'Successfully Removed',
                            SortBy: 'Sort By',
                            Activity: 'Activity',
                            Active: 'Active',
                            Inactive: 'Inactive',
                            MinistryMembership: 'Ministry Membership',
                            Members: 'Members',
                            NonMembers: 'Non Members',
                            SelectAnOptions: 'Select an options',
                            ID: 'ID',
                            BirthDate: 'Birth Date',
                            VolunteerRequirements: 'Volunteer Requirements',
                            Milestones: 'Milestones',
                            Gender: 'Gender',
                            Age: 'Age',
                            QuantityNeeded: 'Quantity Needed',
                            NoRequirements: 'No Requirements',
                            Male: 'Male',
                            Female: 'Female',
                            SelectACampus: 'Select a Campus',
                            Campus: 'Home Campus',
                            SelectAGroup: 'Select a Group',
                            Group: 'Group',
                            ConfirmToRemove: 'Confirm To Remove',
                            Cancel: 'Cancel',
                            MissingRequirements: 'Missing Requirements',
                            Keywords: 'Keywords',
                            AddTerms: 'Add more terms here...',
                            Search: 'Search',
                            ServingOpportunityVolunteerExport: 'Serving Opportunity Volunteer Export',
                            Groups: 'Groups',
                            Roles: 'Roles',
                            Close: 'Close',
                            Collapse: 'Collapse',
                            Expand: 'Expand',
                            CustomQuestionTitle: 'Custom Questions',
                            CustomQuestion: 'Add custom questions to ask your volunteers when they sign up',
                            EditCustomQuestions: 'Edit Custom Questions',
                        },
                        MinistryOpportunitySchedule: {
                            OneTime: 'One Time',
                            Recurring: 'Recurring',
                            Actions: 'Actions',
                            DoNotContact: 'Do Not Contact',
                            Email: 'Email',
                            EmptySlots: 'Empty Slots',
                            FilledSlots: 'Filled Slots',
                            AddSlot: 'Add Slot',
                            VolunteersNeeded: 'Volunteers Needed',
                            OpenSlots: 'Open Slots',
                            Empty: 'Empty',
                            AddVolunteer: 'Add Volunteer',
                            Reminders: 'Reminders',
                            RemoveFromThisOccurrence: 'Remove from This Occurrence',
                            RemoveFromAllOccurrences: 'Remove from All Occurrences',
                            ForThisOccurrence: 'For This Occurrence',
                            ForAllOccurrences: 'For All Occurrences',
                            MemberSearch: 'Member Search',
                            PersonSearch: 'Person Search',
                            Interested: 'Interested',
                            Search: 'Search',
                            Add: 'Add',
                            Cancel: 'Cancel',
                            Name: 'Name',
                            ActivityAndStatus: 'Activity & Status',
                            Served: 'Served',
                            Ministry: 'Ministry',
                            Hours: 'Hours',
                            Active: 'Active',
                            Inactive: 'Inactive',
                            ServingDetails: 'Serving Details',
                            MinistryMember: 'Ministry Member',
                            Yes: 'Yes',
                            No: 'No',
                            Delete: 'Delete',
                            HoursServed: 'Hours Served',
                            Address: 'Address',
                            Phone: 'Phone',
                            ContactDetails: 'Contact Details',
                            RemoveFromOpportunity: 'Remove from Opportunity',
                            SuccessfullyRemoved: 'Selected person(s) is removed from this Serving Opportunity',
                            SetSlotVolunteerError: 'Selected person is already assigned to this Serving Opportunity',
                            SuccessfullySaved: 'Selected person successfully added',
                            ServingOpportunity: 'Serving Opportunity',
                            Opportunity: 'Opportunity',
                            AreYouSure: 'Are you sure?',
                            Update: 'Update',
                            Close: 'Close',
                            Enabled: 'Enabled',
                            Before: 'Before',
                            Interval: 'Interval',
                            CustomEmail: 'Custom Email',
                            Message: 'Message',
                            From: 'From',
                            Reply: 'Reply',
                            Subject: 'Subject',
                            MissingRequirements: 'Missing Requirements',
                            Activity: 'Activity',
                            SelectAnOptions: 'Select an options',
                            SortBy: 'Sort By',
                            Status: 'Status',
                            Group: 'Group',
                            Role: 'Role',
                            Membership: 'Membership',
                            ByName: 'By Name',
                        },
                        NewServingOpportunityDrawer: {
                            Title: 'New Serving Opportunity',
                            Templates: 'Templates',
                            Clear: 'Clear',
                            AttachTo: 'Attach to...',
                            Cancel: 'Cancel',
                            Save: 'Save',
                            Next: 'Next',
                            Back: 'Back',
                            Done: 'Done',
                            Setup: 'Setup',
                            Preview: 'Preview',
                            PreviewIsEmpty: 'No previews available',
                            Step1: 'What & Why',
                            Step2: 'When & Where',
                            Step3: 'Volunteer Requirements',
                            What: 'What',
                            Name: 'Name',
                            Why: 'Why',
                            ShortDescription: 'Short Description',
                            Description: 'Description',
                            Tags: 'Tags',
                            OpportunityContact: 'Opportunity Contact',
                            AddContact: 'Add Contact',
                            Remove: 'Remove',
                            AddGroup: 'Additional Group',
                            Complete: 'Complete',
                            FromRoster: 'From Roster',
                            PersonSearch: 'Person Search',
                            Email: 'Email',
                            Cell: 'Cell',
                            'Address 1': 'Address 1',
                            'Address 2': 'Address 2',
                            City: 'City',
                            Region: 'Region',
                            'Postal Code': 'Postal Code',
                            Country: 'Country',
                            Optional: 'Optional',
                            'Venue Name': 'Venue Name',
                            'Select on Map': 'Select on Map',
                            Longitude: 'Longitude',
                            Latitude: 'Latitude',
                            Custom: 'Custom',
                            Event: 'Event',
                            EventUrl: 'Event URL',
                            Date: 'Date',
                            Time: 'Time',
                            Times: 'Times',
                            Add: 'Add',
                            When: 'When',
                            Where: 'Where',
                            Volunteers: 'Volunteers',
                            QuantityNeeded: 'Quantity Needed',
                            ClearAllFields: 'Clear All Fields?',
                            StartOver: 'Start Over',
                            AllFieldsCleared: 'All Fields Cleared',
                            AreYouSure: 'Are you sure?',
                            Verse: 'Verse',
                            MemberSearch: 'Ministry Members',
                            PersonSearch: 'Person Search',
                            Venue: 'Venue',
                            OpportunityLifespan: 'Lifespan',
                            Days: 'Days',
                            Frequency: 'Frequency',
                            Months: 'Months',
                        },
                        PersonSelector: {
                            Email: 'Email',
                            Name: 'Name',
                            Phone: 'Phone',
                            Select: 'Select',
                            Total: 'Total',
                        },
                        PublishingSettingsDrawer: {
                            Title: 'Publishing Settings',
                            Live: 'Live',
                            Unpublished: 'Unpublished',
                            SuccessfullySaved: 'Publishing Settings Successfully Updated',
                            AreYouSure: 'Are you sure?',
                        },
                    },
                    MobileBlock: {
                        ComingSoon: 'Coming Soon',
                        MobileSupport: 'Mobile Support',
                    },
                    Modal: {
                        ModalTitle: 'Modal Title',
                        Close: 'Close',
                        DrawerDeprecated: {
                            Close: 'Close',
                        },
                        FormSheet: {
                            Close: 'Close',
                        },
                    },
                    MyDashboard: {
                        Notifications: {
                            Discipleship: 'Discipleship',
                            Events: 'Events',
                            GoTo: 'Go To ',
                            Matching: 'Matching',
                            Ministry: 'Ministry',
                            MarkAllAsRead: 'Mark All As Read',
                            NotificationType: 'NotificationType',
                            NoNewNotificaitons: 'No New Notifications',
                            Others: 'Others',
                            Read: 'Read',
                            Results: 'Results',
                            Showing: 'Showing',
                            SortBy: 'Sort By',
                            Status: 'Status',
                            System: 'System',
                            Timeline: 'Timeline',
                            Title: 'Notification Center',
                            Unread: 'Unread',
                            Workflow: 'Follow Ups',
                        },
                        Task: {
                            Blocked: 'Blocked',
                            Due: 'Due',
                            DueToday: 'Due Today',
                            Escalate: 'Escalate',
                            HighPriority: 'High Priority',
                            PastDue: 'Past Due',
                            Reassign: 'Reassign',
                            RemoveHighPriority: 'Remove High Priority',
                            Reopen: 'Reopen',
                            Successful: 'Successful',
                            Unsuccessful: 'Unsuccessful',
                            Upcoming: 'Upcoming',
                            WriteAComment: 'Write a comment',
                        },
                        TaskContactInformation: {
                            Address: 'Address',
                            Cell: 'Cell',
                            ContactInformation: 'Contact Information',
                            Email: 'Email',
                            Home: 'Home',
                            LessConactInfo: 'Less Contact Info',
                            MoreContactInfo: 'More Contact Info',
                            NoContactPreferenceDefined: 'No contact preference defined',
                            PleaseDoNotContact: 'Please Do Not Contact',
                            PreferedContactMethod: 'Prefered Contact Method',
                            ViewPersonRecord: 'View Person Record',
                            Work: 'Work',
                        },
                        TasksReassignEscalateTable: {
                            Blocked: 'Blocked',
                            Close: 'Close',
                            Due: 'Due',
                            Name: 'Name',
                            Open: 'Open',
                            Received: 'Received',
                            Status: 'Status',
                            TasksBeingEscalated: 'Follow Ups Being Escalated',
                            TasksBeingReassigned: 'Follow Ups Being Reassigned',
                        },
                        TasksReassignDrawer: {
                            All: 'All',
                            AreYouSure: 'Are you sure?',
                            Cancel: 'Cancel',
                            ChooseAnAssigneeBelow: 'Choose An Assignee Below',
                            Comment: 'Comment',
                            Escalate: 'Escalate',
                            EscalateWorkItems: 'Escalate Follow Up(s)',
                            LetTheNewAssigneeKnowWhatUp: 'Let the new assignee know what\'s up',
                            LetTheSupervisorKnowWhatUp: 'Let the boss know what\'s up',
                            NewAssignee: 'New Assignee',
                            PleaseProvideAnyExtraInformationHere: 'Please provide any extra information here',
                            Reassign: 'Reassign',
                            ReassignWorkItems: 'Reassign Follow Up(s)',
                            Team: 'Team',
                            WhyAreYouEscalatingThisTask: 'Why are you escalating this follow up?',
                        },
                        TaskReceivedInformation: {
                            APersonEnteredTextAsA: 'A Person Entered Text As A',
                            Received: 'Received',
                        },
                        Workflow: {
                            Blocked: 'Blocked',
                            Claimed: 'Claimed',
                            ClaimTask: 'Claim Follow Up',
                            ClosedDate: 'Closed Date',
                            ClosedSuccessful: 'Closed Successful',
                            ClosedUnsuccessful: 'Closed Unsuccessful',
                            Due: 'Due',
                            DueToday: 'Due Today',
                            Closed: 'Closed',
                            Escalated: 'Escalated',
                            Name: 'Name',
                            Open: 'Open',
                            PastDue: 'Past Due',
                            Received: 'Received',
                            Search: 'Search',
                            SortBy: 'Sort By',
                            Status: 'Status',
                            Successful: 'Successful',
                            Total: 'Total',
                            Unclaimed: 'Unclaimed',
                            Unsucessful: 'Unsucessful',
                            Upcoming: 'Upcoming',
                            Overview: 'Overview',
                        },
                        Workflows: {
                            Blocked: 'Blocked',
                            Claimed: 'Claimed',
                            Closed: 'Closed',
                            DueSoonest: 'Due Soonest',
                            FollowUps: 'Follow Ups',
                            InProgress: 'In Progress',
                            MostRecent: 'Most Recent',
                            Name: 'Name',
                            NextUp: 'Next Up',
                            Oldest: 'Oldest',
                            Open: 'Open',
                            PastDue: 'Past Due',
                            Received: 'Received',
                            Successful: 'Successful',
                            Total: 'Total',
                            Unclaimed: 'Unclaimed',
                            Unsuccessful: 'Unsuccessful',
                            Upcoming: 'Upcoming',
                        },
                        Overview: {
                            Claimed: 'Claimed',
                            Escalated: 'Escalated',
                            Management: 'Management',
                            Overview: 'Overview',
                            PastDue: 'Past Due',
                            Unclaimed: 'Unclaimed',
                            Workflows: 'Follow Ups',
                        },
                    },
                    Person: {
                        AuditLog: {
                            Additions: 'Additions',
                            After: 'After',
                            Before: 'Before',
                            Changes: 'Changes',
                            DisplayName: 'Display Name',
                            Id: 'ID',
                            FileName: 'File Name',
                            HouseholdName: 'Household Name',
                            Location: 'Location',
                            Members: 'Members',
                            Milestone: 'Milestone',
                            MilestoneDate: 'Milestone Date',
                            NA: 'N/A',
                            Note: 'Note',
                            OfficialDocumentation: 'Official Documentation',
                            Relation: 'Relation',
                            Removed: 'Removals',
                            MergedFrom: 'Data from record',
                            MergedTo: 'Merged into record',
                            ServingOpportunity: 'Serving Opportunity',
                            Name: 'Name',
                        },
                        Duplicate: {
                            PersonDuplicateReport: {
                                Actions: 'Actions',
                                Church: 'Church',
                                Contact: 'Contact',
                                DOB: 'DOB',
                                LoadMore: 'Load More',
                                Merge: 'Merge',
                                MobileMerge: 'Person Merge',
                                Modified: 'Modified',
                                Name: 'Name',
                                NotAvailable: 'Not Available on Small Screens',
                                Placeholder: 'What duplicates are you looking for?',
                                DuplicateRecords: 'Duplicate Records',
                                Id: 'ID',
                            },
                            PersonDuplicateReportRow: {
                                Address: 'address',
                                AreRelated: 'These individuals are related to each other',
                                Birthdate: 'birthdate',
                                Create: 'Create',
                                CreateRelationship: 'Create relationship',
                                Email: 'email',
                                Id: 'id',
                                KeepRecord: 'Keep record',
                                ModifiedDate: 'Modified',
                                Name: 'name',
                                Home: 'home',
                                Mobile: 'mobile',
                                Work: 'work',
                                MergeRecords: 'Merge Records',
                                NoMatch: 'Not a possible match',
                                NotDuplicates: 'Not Duplicates',
                                NotSamePerson: 'These records do not represent the same person',
                                Record: 'Record',
                                SelectSecond: 'Select a second record',
                                SelectRecord: 'Select which record will remain after you merge the data',
                                UpdateRecord: 'Update record',
                                Remove: 'Remove',
                            },
                        },
                        GlobalAuditLog: {
                            All: 'All',
                            Alphabetical: 'A-Z',
                            Apply: 'Apply',
                            Ascending: 'Ascending',
                            AuditLog: 'Audit Log',
                            Date: 'Date',
                            Descending: 'Descending',
                            Editor: 'Editor',
                            FilterBy: 'Filter By',
                            Filters: 'Filters',
                            Id: 'ID',
                            LoadMore: 'Load More',
                            Method: 'Method',
                            Name: 'Name',
                            NoneFound: 'No Audit Log Entries Found',
                            On: 'On',
                            Period: 'Period',
                            Reset: 'Reset',
                            ReverseAlphabetical: 'Z-A',
                            SearchViaEditor: 'Search via Editor',
                            SortBy: 'Sort By',
                            To: 'To',
                        },
                        GlobalAuditLogDetails: {
                            AddANote: 'Add a Note (optional)',
                            Additions: 'Additions',
                            After: 'After',
                            Before: 'Before',
                            By: 'By',
                            Changes: 'Changes',
                            Id: 'ID',
                            MultipleCategories: 'Multiple Categories',
                            NA: 'N/A',
                            NoteSaved: 'Note Saved',
                            On: 'On',
                            PriorNotes: 'Prior Notes',
                            Removals: 'Removals',
                            Save: 'Save',
                            To: 'To',
                            Via: 'Via',
                            ViewRecord: 'View Record',
                        },
                        Manager: {
                            PossibleDuplicates: {
                                PossibleDuplicates: 'Possible Duplicates',
                                NoDuplicates: 'No Duplicates',
                                ViewDuplicates: 'View Duplicate Report',
                                NotDuplicate: 'Not a Duplicate',
                                CreateRelationship: 'Create Relationship',
                                MergeOptions: 'Merge Options',
                            },
                            PersonSubscriptions: {
                                Subscriptions: 'Subscriptions',
                                Communications: 'Communications',
                                CampusENews: 'Campus eNews',
                                Ministry: 'Ministry',
                                Others: 'Others',
                                EditSubscription: 'Edit Subscription',
                                Save: 'Save',
                                Cancel: 'Cancel',
                                SaveAndNote: 'Save with Note',
                                SuccessfullyAdded: 'Subscriptions Updated',
                            },
                            Giving: {
                                Giving: 'Giving',
                                LastGivingDate: 'Last Giving Date',
                                NoGivingRecords: 'No Giving Records',
                            },
                            Notes: {
                                AllNotes: 'All Notes',
                                By: 'By',
                                Cancel: 'Cancel',
                                ContextualNotes: 'Contextual Notes',
                                Delete: 'Delete',
                                EditNote: 'Edit Note',
                                ID: 'ID',
                                LoadMore: 'Load More',
                                Ministry: 'Ministry',
                                NewNote: 'New Note',
                                No: 'No',
                                Note: 'Note',
                                Notes: 'Notes',
                                On: 'On',
                                To: 'To',
                                Save: 'Save',
                                SaveNote: 'Save Note',
                                Select: 'Select',
                                SensitiveInformation: 'Sensitive Information',
                                Topic: 'Topic',
                                Topics: 'Topics',
                                TotalNotesFound: 'Total Notes Found',
                                Yes: 'Yes',
                            },
                            PersonContact: {
                                AddAnotherAddress: 'Add another address',
                                AddAnotherEmail: 'Add another email',
                                AddAnotherPhone: 'Add another phone',
                                Additional: 'Additional',
                                AddNote: 'Add a Note (optional)',
                                Address: 'Address',
                                Address1: 'Address 1',
                                Address2: 'Address 2',
                                AddressDeleted: 'Address has been deleted',
                                AddressSaved: 'Addresses have been saved',
                                AddressTitle: 'Address Title (optional)',
                                BadContact: 'Bad Contact',
                                BadContactNote: 'Bad Contact Reason (optional)',
                                Cancel: 'Cancel',
                                City: 'City',
                                ContactPrefs: 'Contact Preferences',
                                Country: 'Country',
                                Delete: 'Delete',
                                DeleteAddress: 'Delete Address',
                                DeleteAddressAreYouSure: 'Are you sure you want to delete this address?',
                                DeleteEmail: 'Delete Email Address',
                                DeleteEmailAreYouSure: 'Are you sure you want to delete this email address?',
                                DeletePhone: 'Delete Phone Number',
                                DeletePhoneAreYouSure: 'Are you sure you want to delete this phone number?',
                                Done: 'Done',
                                DoNotContact: 'Do Not Contact',
                                DoNotContactVia: 'Do Not Contact via',
                                DoNotContactIndividual: 'Do Not Contact This Individual',
                                EditSection: 'Edit Section',
                                Email: 'Email',
                                EmailDeleted: 'Email Address has been deleted',
                                EmailSaved: 'Email Addresses have been saved',
                                EmailType: 'Email Type',
                                EmailTitle: 'Email Title (optional)',
                                EnterValidEmail: 'Enter Valid Email',
                                EnterValidPhone: 'Enter Valid Phone Number',
                                EnterValidPhoneType: 'Select Valid Phone Type',
                                Extension: 'Extension',
                                HideDetails: 'Show Less Details',
                                InvalidContact: 'Not Valid',
                                NoPref: 'No Preference',
                                NoteFor: 'Note for',
                                OK: 'OK',
                                Optional: '(optional)',
                                Phone: 'Phone',
                                PhoneDeleted: 'Phone Number has been deleted',
                                PhoneNumber: 'Phone Number',
                                PhoneSaved: 'Phone Numbers have been saved',
                                PhoneType: 'Phone Type',
                                PhoneTitle: 'Phone Title (optional)',
                                PostalCode: 'Postal Code',
                                PreferredMethod: 'Preferred Method',
                                Prefers: 'Prefers',
                                PrefsSaved: 'Contact Preferences have been saved',
                                Primary: 'Primary',
                                Public: 'Public',
                                Region: 'Region',
                                Save: 'Save',
                                SaveUpdate: 'Save & Update',
                                SaveAndNote: 'Save with Note',
                                ShowDetails: 'Show All Details',
                                ThisisPrimary: 'This is the Primary Contact for this person.  You will need to change the Primary Contact first before deleting this.',
                                UpdateSaved: 'Update Saved',
                                ValidContact: 'Valid',
                            },
                            PersonDocument: {
                                DragAndDrop: 'Drag and drop files here to upload.',
                                FileDeleted: 'File was successfully deleted.',
                                FileSaved: 'File was successfully saved.',
                                Or: 'or',
                                Browse: 'browse',
                                Documents: 'Documents',
                                UploadSuccess: 'File successfully uploaded!',
                                FileTriedUploading: 'The file you tried uploading',
                                UnsupportedType: 'is an unsupport file type. Supported file types: .bmp, .doc, .docx, .gif, .ico, .jpg,, .pdf, .png, .xlsx, and .zip',
                                TooLarge: 'is too large. You can only upload 10mb files.',
                                GenericErrorMessage: 'There was a problem while trying to upload your file.  Possibly it is too large. You can only upload 10mb files.',
                                AlreadyUploadedMessage: 'The file is already uploaded. Would you like to overwrite it?',
                            },
                            PersonDocumentRow: {
                                DeleteConfirmation: 'Are you sure you want to delete this document?',
                                Ok: 'Ok',
                                Cancel: 'Cancel',
                                Deleted: 'File has been deleted.',
                            },
                            PersonDuplicateModal: {
                                PossibleDuplicates: 'Possible Duplicate',
                                PersonPreview: 'Person Preview',
                                FirstName: 'First Name',
                                LastName: 'Last Name',
                                Address1: 'Address 1',
                                Address2: 'Address 2',
                                City: 'City',
                                Region: 'Region',
                                PostalCode: 'Postal Code',
                                Update: 'Use This Record',
                                Close: 'Close',
                                Cancel: 'Cancel',
                                Back: 'Back',
                                PossibleMatch: 'Possible Match',
                                Previous: 'Previous',
                                Next: 'Next',
                                Of: 'of',
                                Personal: 'Personal',
                                Title: 'Title',
                                MiddleName: 'Middle Name',
                                Address: 'Address',
                                MobilePhone: 'Mobile Phone',
                                HomePhone: 'Home Phone',
                                WorkPhone: 'Work Phone',
                                Email: 'Email',
                                AuditLog: 'Audit Log',
                            },
                            PossibleDuplicates: {
                                NotDuplicate: 'Not a Duplicate',
                                NoDuplicates: 'No Duplicate Records.',
                                CreateRelationship: 'Create Relationship',
                                MergeOptions: 'Merge Options',
                                ViewDuplicates: 'View Duplicates',
                                PossibleDuplicates: 'Possible Duplicate',
                            },
                            PersonDuplicateRow: {
                                Id: 'ID',
                                Name: 'Name',
                                Preview: 'Preview',
                            },
                            PersonRecord: {
                                LeaveThisPage: 'Leave this page',
                                StayOnThisPage: 'Stay on this page',
                                YouHaveUnsavedData: 'You have unsaved data.',
                                YouHaveUnsavedDataAreYouSure: 'You have unsaved data. Are you sure you want leave this page?',
                            },
                            PersonManager: {
                                RefineQuery: 'There are over {0} results. Please refine your query.',
                                AddPerson: 'Add Person',
                                Address: 'Address',
                                Address1: 'Address 1',
                                Address2: 'Address 2',
                                Allergies: 'Allergies',
                                Back: 'Back',
                                Birthdate: 'Birthdate',
                                Birthday: 'Birthday',
                                Campus: 'Campus',
                                Cancel: 'Cancel',
                                CellPhone: 'Cell Phone',
                                City: 'City',
                                ChangeAddress: 'Change Address',
                                ChangeCampus: 'Change Campus',
                                Church: 'Church',
                                ChurchAffiliation: 'Church Affiliation',
                                ConfirmDeceasedDate: 'Are you sure you want to mark this person\'s record as deceased?',
                                Contact: 'Contact',
                                Country: 'Country',
                                Create: 'Create',
                                DeceasedDate: 'Deceased Date',
                                Deceased: 'Deceased',
                                EditSection: 'Edit Section',
                                Email: 'Email',
                                EnterValidEmail: 'Please enter a valid email address.',
                                Extension: 'Extension',
                                FamilyUpdated: 'Family Members information has been updated.',
                                FirstName: 'First Name',
                                ForTheFollowing: 'for the following Relationships as well',
                                Gender: 'Gender',
                                Grade: 'Grade',
                                HomePhone: 'Home Phone',
                                Id: 'Id',
                                ID: 'ID',
                                LastName: 'Last Name',
                                LiteRecordQ: 'Lite Record Queue',
                                LoadMore: 'Load More',
                                MaritalStatus: 'Marital Status',
                                Membership: 'Membership',
                                MembershipStatus: 'Membership Status',
                                MembershipNoteChangeReason: 'Please indicate a reason for this change.',
                                MemberStatusBadSwitchAlertEnd: '" because of the following problem:',
                                MemberStatusChoiceAlertStart: 'You cannot select the Membership Status of "',
                                Merge: 'Merge',
                                MiddleName: 'Middle Name',
                                NA: 'NA',
                                Name: 'Name',
                                NickName: 'Nick Name',
                                No: 'No',
                                NonMembers: 'Non-Members',
                                NonMember: 'Non-Member',
                                Occupation: 'Occupation',
                                Notes: 'Notes',
                                Personal: 'Personal',
                                PersonRecord: 'Person Record',
                                Phone: 'Phone',
                                PostalCode: 'Postal Code',
                                'Preferred Service': 'Preferred Service',
                                PreferredService: 'Preferred Service',
                                PrimaryAddress: 'Primary Address',
                                Region: 'Region',
                                Relationships: 'Relationships',
                                Save: 'Save',
                                SaveAndNote: 'Save with Note',
                                Search: 'Search',
                                SelectPersonToMerge: 'Select to merge with ',
                                Source: 'Source',
                                StateProvince: 'State/Province',
                                SuccessfullySavedMembership: 'Successfully saved membership data',
                                SuccessfullySavedContact: 'Successfully saved contact data',
                                SuccessfullySavedPersonal: 'Successfully saved personal data',
                                SuccessfullySaved: 'Successfully saved',
                                Suffix: 'Suffix',
                                Title: 'Title',
                                Total: 'Total',
                                Type: 'Type',
                                WorkPhone: 'Work Phone',
                                Yes: 'Yes',
                                ManageUserAccountButton: 'Manage User Account',
                            },
                            PersonManagerDuplicates: {
                                ByFullName: 'By Full Name',
                                ByAddress: 'By Address',
                                Save: 'Save',
                                Cancel: 'Cancel',
                            },
                            PersonMilestones: {
                                Milestones: 'Milestones',
                                SuccessfullyAdded: 'Successfully added milestone ',
                                SuccessfullyUpdated: 'Successfully updated milestone ',
                                SuccessfullyDeleted: 'Successfully removed milestone ',
                                On: ' on ',
                            },
                            PersonMilestoneDateManager: {
                                AddAnotherDate: 'Add another date',
                                AddMilestone: 'Add Milestone',
                                OtherFile: 'Other File',
                                Date: 'Date',
                                DateErrorMessage: 'Please fill in the Date field.',
                                Location: 'Location',
                                HomeChurch: 'Saddleback Campus',
                                NotAtHomeChurch: 'Not at Saddleback',
                                MilestoneDate: 'Milestone Date',
                                MilestoneType: 'Milestone Type',
                                OfficialDocumentation: 'Official Documentation',
                                OfficialDocumentationErrorMessage: 'Please attach a file.',
                                Other: 'Other',
                                Save: 'Save',
                                SaveMilestone: 'Save Milestone',
                                SaveAndNote: 'Save with Note',
                                Cancel: 'Cancel',
                                RequiredFields: ' cannot be saved at this time.  Date and Location are required.',
                            },
                            PersonMilestoneDateView: {
                                DeleteMilestone: 'Delete Milestone',
                                EditMilestone: 'Edit Milestone',
                                HideDetails: 'Hide Details',
                                Location: 'Location',
                                HomeChurch: 'Saddleback Campus',
                                NotAtHomeChurch: 'Not at Saddleback',
                                MilestoneDate: 'Milestone Date',
                                OfficialDocumentation: 'Official Documentation',
                                OtherFile: 'Other File',
                                ShowDetails: 'Show Details',
                            },
                            PersonPictureUploader: {
                                Avatar: 'Avatar',
                                Browse: 'browse',
                                Cancel: 'Cancel',
                                ChangeAvatar: 'Change Avatar',
                                ChangePhoto: 'Change Photo',
                                DragAndDrop: 'Drag and drop here to upload new photo',
                                Or: 'or',
                                Photo: 'Photo',
                                Remove: 'Delete Current Photo',
                                RemoveError: 'Unable to remove the Image!',
                                ShowAvatar: 'Show Web Avatar',
                                ShowStaff: 'Show Photo',
                                SuccessfullyRemoved: 'Successfully Removed Image',
                            },
                            PersonResponseCardEntryPanel: {
                                SeeMore: 'See More',
                            },
                            PersonView: {
                                AcceptedChrist: 'Accepted Christ',
                                Address: 'Address',
                                Baptism: 'Baptism',
                                BecameMember: 'Became Member',
                                Birthdate: 'Birthdate',
                                Campus: 'Campus',
                                CellPhone: 'Cell Phone',
                                Contact: 'Contact',
                                Church: 'Church',
                                ChurchAffiliation: 'Church Affiliation',
                                DeceasedDate: 'Deceased Date',
                                Deceased: 'Deceased',
                                Decisions: 'Decisions',
                                Divorced: 'Divorced',
                                Edit: 'Edit',
                                Email: 'Email',
                                Female: 'Female',
                                FirstName: 'First Name',
                                Gender: 'Gender',
                                HomePhone: 'Home Phone',
                                ID: 'ID',
                                LastName: 'Last Name',
                                Male: 'Male',
                                MaritalStatus: 'Marital Status',
                                Married: 'Married',
                                MembershipStatus: 'Membership Status',
                                MiddleName: 'Middle Name',
                                NA: 'N/A',
                                NickName: 'Nick Name',
                                PageTitle: 'People Management',
                                Personal: 'Personal',
                                Single: 'Single',
                                Subtitle: 'View Person',
                                Suffix: 'Suffix',
                                Title: 'Title',
                                Widowed: 'Widowed',
                                WorkPhone: 'Work Phone',
                                Address1: 'Address 1',
                                Address2: 'Address 2',
                                City: 'City',
                                State: 'State/Province',
                                PostalCode: 'Postal Code',
                                Country: 'Country',
                                Profile: 'Profile',
                                LastUpdated: 'Last Updated',
                                Prefix: 'Prefix',
                                Milestones: 'Milestones',
                                NoImage: 'No Image',
                            },
                            PersonViewNav: {
                                Edit: 'Edit Person',
                                Audit: 'Audit Log',
                                AddPersonButton: 'Add Person',
                            },
                            PersonDeceasedModal: {
                                DeceasedQuestion: 'ADD DECEASED DATE?',
                                DeceasedDate: 'Deceased Date',
                                SetDate: 'Confirm & Add',
                                Cancel: 'Cancel',
                                Or: 'or',
                            },
                            GroupManager: {
                                GroupLeader: 'Group Leader',
                                GroupName: 'Group Name',
                                Groups: 'Small Groups',
                                IsLeader: 'Is Leader',
                                Loading: 'Loading',
                                MeetingLocation: 'Meeting Location',
                                MeetingTime: 'Meeting Time',
                                MemberSince: 'Member Since',
                                NoGroups: 'Not in a Small Group',
                                Role: 'Role',
                                SmallGroups: 'Small Groups',
                                Yes: 'Yes',
                            },
                            Ministries: {
                                AcceptingNewMembers: 'Accepting New Members',
                                AddAsInterested: 'Add As Interested',
                                Age: 'Age',
                                Campus: 'Campus',
                                Cancel: 'Cancel',
                                CoLeaders: 'Co-Leaders',
                                DateAdded: 'Date Added',
                                Description: 'Description',
                                DaysInStatus: 'Days in Status',
                                Gender: 'Gender',
                                HowFound: 'How did this person hear about the ministry?',
                                HowClose: 'How close are they to becoming a member?',
                                InterestedHow: 'How did this person become interested in the ministry?',
                                InterestedStatus: 'Interested Status',
                                Leader: 'Leader',
                                Milestones: 'Milestones',
                                Ministries: 'Ministries',
                                MissingEligibilityRequirements: 'Missing Eligibility Requirements',
                                No: 'No',
                                Overview: 'Overview',
                                OverrideRequirements: 'Override Requirements',
                                Roles: 'Roles',
                                SelectACampus: 'Select A Campus',
                                SelectAMinistry: 'Select A Ministry',
                                ShowMinistryDetails: 'Show Ministry Details',
                                StaffConnectors: 'Staff Connectors',
                                Status: 'Status',
                                Yes: 'Yes',
                            },
                            Occupations: {
                                AddOccupation: 'Add Occupation',
                                AddNote: 'Add a Note (optional)',
                                JobTitle: 'Job Title',
                                Occupation: 'Occupation',
                                OccupationPlace: 'Place of Employment',
                                SuccessfullyAdded: 'Successfully Added Occupation',
                                SuccessfullyDeleted: 'Successfully Deleted Occupation',
                                SuccessfullyUpdated: 'Successfully Updated Occupation',
                                Type: 'Type',
                            },
                            OccupationsManager: {
                                AddOccupation: 'Add Occupation',
                                AddANote: 'Add a Note',
                                Cancel: 'Cancel',
                                Category: 'Industry',
                                Delete: 'Delete',
                                JobTitle: 'Job Title',
                                Occupation: 'Occupation',
                                OccupationName: 'Occupation Name',
                                OccupationPlace: 'Place of Employment',
                                PrimaryOccupation: 'Primary Occupation',
                                Save: 'Save',
                                SaveAndNote: 'Save with Note',
                                Type: 'Type',
                            },
                            OccupationsView: {
                                EditOccupation: 'Edit Occupation',
                                Industry: 'Industry',
                                Note: 'Note',
                                PlaceOfEmployment: 'Place of Employment',
                                ShowAllDetails: 'Show All Details',
                                ShowLessDetails: 'Show Less Details',
                                Title: 'Job Title',
                            },
                        },
                        MembershipStatus: {
                            Deceased: 'Deceased',
                            MembershipStatus: 'Membership Status',
                        },
                        Merge: {
                            PersonMerge: {
                                Select: 'Select ...',
                                Before: 'Before',
                                Empty: '(empty)',
                                After: 'After',
                                Cancel: 'Cancel',
                                Personal: 'Personal',
                                Prefix: 'Prefix',
                                FirstName: 'First Name',
                                LastName: 'Last Name',
                                MiddleName: 'Middle Name',
                                NickName: 'Nick Name',
                                Suffix: 'Suffix',
                                Gender: 'Gender',
                                Male: 'Male',
                                Female: 'Female',
                                MaritalStatus: 'Marital Status',
                                Single: 'Single',
                                Married: 'Married',
                                Divorced: 'Divorced',
                                Widowed: 'Widowed',
                                Birthdate: 'Birthdate',
                                Contact: 'Contact',
                                Address: 'Address',
                                Address1: 'Address 1',
                                Address2: 'Address 2',
                                City: 'City',
                                State: 'State/Province',
                                PostalCode: 'Postal Code',
                                Country: 'Country',
                                Phone: 'Phone',
                                CellPhone: 'Cell Phone',
                                HomePhone: 'Home Phone',
                                WorkPhone: 'Work Phone',
                                Email: 'Email',
                                Campus: 'Church Campus',
                                Church: 'Church',
                                MembershipStatus: 'Membership Status',
                                Decisions: 'Decisions',
                                AcceptedChristDate: 'Accepted Christ Date',
                                BaptismDate: 'Baptism Date',
                                Milestones: 'Milestones',
                                Membership: 'Membership',
                                MembershipStatus: 'Membership Status',
                                Ministries: 'Ministries',
                                Ministry: 'Ministry',
                                NoMilestones: 'Person has no Milestones',
                                NoMinistries: 'Person is not in a Ministry',
                                Notes: 'Notes',
                                NoNotes: 'Person has no Notes',
                                Occupation: 'Occupation',
                                OccupationCompany: 'Company',
                                OccupationTitle: 'Title',
                                OccupationType: 'Type',
                                ResolveFieldsMessage: 'Please resolve all fields before saving.',
                                Profile: 'Profile ID',
                                LastUpdated: 'Last Updated',
                                NoMatch: 'Not a possible match',
                                NoImage: 'No Image',
                                NoOccupations: 'Person has no Occupations',
                                CreateRelationship: 'Create relationship',
                                SaveRelationship: 'Save & Update',
                                RelationshipType: 'Relationship Type',
                                Id: 'ID',
                                AddAll: 'Add All Fields',
                                ClearAll: 'Clear All Fields',
                                OldRecord: 'Old Record',
                                RemovingRecord: 'Removing Record',
                                NewRecord: 'New Record',
                                PreviewMessage: 'This is a preview of the record to be saved',
                            },
                        },
                        Record: {
                            Global: {
                                CoreMilestones: {
                                    AcceptedChrist: 'Accepted Christ',
                                    ActiveInMinistry: 'Active in Ministry',
                                    ActiveInMissions: 'Active in Trips',
                                    ActiveInSmallGroup: 'Active in Small Group',
                                    AndHasNotSignedMaturityCovenant: 'and has not Signed Maturity Covenant',
                                    AndHasNotSignedMembershipCovenant: 'and has not Signed Membership Covenant',
                                    AndHasNotSignedMinistryCovenant: 'and has not Signed Ministry Covenant',
                                    AndHasNotSignedMissionCovenant: 'and has not Signed Mission Covenant',
                                    AndSignedMaturityCovenant: 'and Signed Maturity Covenant',
                                    AndSignedMembershipCovenant: 'and Signed Membership Covenant',
                                    AndSignedMinistryCovenant: 'and Signed Ministry Covenant',
                                    AndSignedMissionCovenant: 'and Signed Mission Covenant',
                                    AtSaddleback: 'At Saddleback',
                                    Baptized: 'Baptized',
                                    ButHasSignedMaturityAgreement: 'but has signed Maturity Agreement',
                                    ButHasSignedMembershipCovenant: 'but has signed Membership Covenant',
                                    butHasSignedMinistryCovenant: 'but has signed Ministry Covenant',
                                    ButHasSignedMissionCovenant: 'but has signed Mission Covenant',
                                    HasNotAcceptedChrist: 'Has not accepted Christ',
                                    HasNotTakenCLASS101: 'Has not taken DYP 1',
                                    HasNotTakenCLASS201: 'Has not taken DYP 2',
                                    HasNotTakenCLASS301: 'Has not taken DYP 3',
                                    HasNotTakenCLASS401: 'Has not taken DYP 4',
                                    MemberFor: 'Member For',
                                    NotActiveInMinistry: 'Not active in Ministry',
                                    NotActiveInMissions: 'Not active in Trips',
                                    NotActiveInSmallGroup: 'Not active in Small Group',
                                    NotBaptized: 'Not Baptized',
                                    TakenCLASS101: 'Taken DYP 1',
                                    TakenCLASS201: 'Taken DYP 2',
                                    TakenCLASS301: 'Taken DYP 3',
                                    TakenCLASS401: 'Taken DYP 4',
                                },
                            },
                            DetailsWindow: {
                                Notes: 'Notes',
                            },
                            EmergencyContact: {
                                AddEmergencyContact: 'Add Emergency Contact',
                                AddedEmergencyContactSuccessMsg: 'Successfully Added Emergency Contact',
                                AreYouSure: 'Are You Sure?',
                                Cancel: 'Cancel',
                                Deceased: 'Deceased',
                                DeleteEmergencyContact: 'Delete Emergency Contact',
                                DeletedEmergencyContactSuccessMsg: 'Successfully Deleted Emergency Contact',
                                DeletedFollowingEmergencyContact: 'Deleting the following Emergency Contact',
                                EmergencyContact: 'Emergency Contact',
                                NoEmergencyContacts: 'No Emergency Contacts',
                                PersonAlreadySelected: 'Person Already Selected as Emergency Contact',
                                Primary: 'Primary',
                                RelationshipType: 'Relationship Type',
                                Save: 'Save',
                                UpdatedEmergencyContactSuccessMsg: 'Successfully Updated Emergency Contact',
                            },
                            Notes: {
                                AddedNote: 'Successfully Added Note',
                                AreYouSure: 'Are you sure ?',
                                Categories: 'Categories',
                                Delete: 'Delete',
                                Edit: 'Edit',
                                EditNote: 'Edit Note',
                                Filters: 'Filters',
                                NewNote: 'New Note',
                                Notes: 'Notes',
                                Pinned: 'Pinned',
                                PinNote: 'Pin Note',
                                PinNoteSuccessMsg: 'Successfully Pinned Note',
                                UnPinNoteSuccessMsg: 'Successfully UnPinned Note',
                                RemoveNoteSuccessMsg: 'Successfully removed Note',
                                SortBy: 'Sort By',
                                Type: 'Type',
                                UnpinNote: 'Unpin Note',
                                UpdatedNote: 'Successfully Updated Note',
                                Form: {
                                    Category: 'Category',
                                    Cancel: 'Cancel',
                                    Note: 'Note',
                                    Save: 'Save',
                                    Subject: 'Subject',
                                },
                            },
                            SubPages: {
                                ComingSoonMessage: {
                                    CheckBackSoon: 'Check back soon for a sneak peek of what’s coming!',
                                    CurrrentlyWorking: 'We are currently working on this section.',
                                },
                                Personal: {
                                    PersonalInfo: {
                                        Birthday: 'Birthday',
                                        DNCVia: 'DNC via',
                                        NoPreference: 'no preference',
                                        Service: 'Service',
                                    },
                                },
                            },
                            TopLevel: {
                                PersonalInformation: {
                                    DNCVia: 'DNC via',
                                    DoesNotAttend: 'Does Not Attend',
                                    DoNotContactThisIndividual: 'Do Not Contact This Individual',
                                    Email: 'email',
                                    Mail: 'mail',
                                    NoPreference: 'no preference',
                                    Phone: 'phone',
                                    PrefersEmail: 'prefers email',
                                    PrefersPhone: 'prefers phone',
                                    PrefersText: 'prefers text',
                                    Text: 'text',
                                    Unknown: 'Unknown',
                                },
                                SubNavigation: {
                                    Engagement: 'Engagement',
                                    MinistryAndServing: 'Ministry & Serving',
                                    Personal: 'Personal',
                                    SmallGroups: 'Small Groups',
                                },
                            },
                        },
                        Relationship: {
                            PersonRelationship: {
                                Relationships: 'Relationships',
                                RemoveRelationship: 'Remove relationship',
                                AddRelationship: 'Add Relationship',
                                SuccessfullyDeleted: 'Successfully deleted relationship',
                                SuccessfullySaved: 'Successfully saved relationships',
                            },
                            PersonRelationshipMap: {
                                SelectHowRelates: 'Select how this person relates to',
                            },
                            PersonRelationshipSearch: {
                                WhoLookingFor: 'Who are you looking for?',
                            },
                            PersonRelationshipSearchRecord: {
                                Name: 'Name',
                                ID: 'ID',
                                Phone: 'Phone',
                                Home: 'Home',
                                Mobile: 'Mobile',
                                Work: 'Work',
                                Email: 'Email',
                                Address: 'Address',
                                Birthdate: 'Birthdate',
                                Add: 'Add',
                            },
                            PersonRelationshipView: {
                                Relationships: 'Relationships',
                            },
                            ConflictRelationshipsBanner: {
                                MessageFmt1: 'The following relationships appear to be invalid:',
                                MessageFmt2_1: 'New \'{relationType}\' relation between {personFullName} and {relativeFullName} replaces relationship \'{existingRelationType}\', resulting the following conflicts:',
                                MessageFmt2_2: 'The requested changes to relationships would create the following conflicts:',
                                ConflictFmt1: 'Existing: {relativeFullName} is the {relationType} of {personFullName}.',
                                ConflictFmt2: 'New: {relativeFullName} is the {relationType} of {personFullName}.',
                                InvalidFmt: '{relativeFullName} is the {relationType} of {personFullName}',
                                ForceToUpdate: 'Do you wish to to force this update despite the issue?',
                                CancelButton: 'Cancel',
                                OkButton: 'Force',
                            },
                        },
                        Search: {
                            PersonSearch: {
                                RefineQuery: 'There are over {0} results. Please refine your query.',
                                AddPerson: 'Add Person',
                                Filters: 'Filters',
                                SearchOrAdd: 'Search or Add',
                                PageSubtitle: 'People Search',
                                AddPersonButton: 'Add Person',
                                LoadMore: 'Load More',
                                Or: 'or',
                                Placeholder: 'Who are you looking for?',
                                NoResultsMessage: 'Please use the search above to render some results',
                                NoResultsTip: 'Tip: Use commas to separate keywords to narrow your search.',
                                TotalRecordsFound: 'Total Records Found: ',
                                ContactPrefs: 'Contact Preferences',
                                Hide: 'Hide',
                                Show: 'Show',
                                SearchHelp: 'Search Help',
                                SyntaxError: 'Syntax Error. See help for details.',
                            },
                            PeopleSearchDrawer: {
                                Nickname: 'Nickname',
                                RefineQuery: 'There are over {0} results. Please refine your query.',
                                PageTitle: 'People Management',
                                PageSubtitle: 'People Search',
                                AddPersonButton: 'Add Person',
                                Additional: 'Additional',
                                Or: 'or',
                                Placeholder: 'E.g.: first-name:John   phone.number:9515554444   email:john@example.com',
                                NoPublicContacts: 'Person has no public contacts',
                                NoResultsMessage: 'Please use the search above to render some results',
                                NoResultsTip: 'Tip: Use commas to separate keywords to narrow your search.',
                                TotalRecordsFound: 'Total Records Found: ',
                                ContactPrefs: 'Contact Preferences',
                                Contacts: 'Contacts',
                                Hide: 'Hide',
                                SelectPerson: 'Select Person',
                                Show: 'Show',
                                SearchHelp: 'Search Help',
                                Address: 'Address',
                                Birthday: 'Birthday',
                                Contact: 'Contact',
                                Cell: 'Cell',
                                City: 'City',
                                DoNotContact: 'Do Not Contact',
                                Email: 'Email',
                                FirstName: 'First Name',
                                Have: 'Have',
                                ID: 'ID',
                                LastName: 'Last Name',
                                Membership: 'Membership',
                                Milestones: 'Milestones',
                                Ministries: 'Ministries',
                                NA: 'N/A',
                                Personal: 'Personal',
                                Select: 'Select',
                                Gender: 'Gender',
                                Groups: 'Groups',
                                Age: 'Age',
                                PersonHasDoNotContact: 'Person has do not contact.',
                                MissingRequirements: 'Missing Requirements',
                                OverrideRequirements: 'Override Requirements',
                                AreYouSure: 'Are you sure?',
                                Need: 'Need',
                                NoPublicContacts: 'Person has no public contacts',
                                PersonAlreadyExistsIn: 'Person already exists in',
                                Parent: 'parent',
                                Phone: 'Phone',
                                Primary: 'Primary',
                                LoadMore: 'Load More',
                                Roles: 'Roles',
                                State: 'State',
                                Status: 'Status',
                                TotalCount: 'Total: ',
                                View: 'View',
                                Zip: 'Postal Code',
                            },
                            MemberSearchDrawer: {
                                PageTitle: 'People Management',
                                PageSubtitle: 'People Search',
                                AddPersonButton: 'Add Person',
                                Or: 'or',
                                Placeholder: 'Search People',
                                NoResultsMessage: 'Please use the search above to render some results',
                                NoResultsTip: 'Tip: Use commas to separate keywords to narrow your search.',
                                TotalRecordsFound: 'Total Records Found: ',
                                ContactPrefs: 'Contact Preferences',
                                Hide: 'Hide',
                                SelectPerson: 'Select Person',
                                Show: 'Show',
                                SearchHelp: 'Search Help',
                                Address: 'Address',
                                Birthday: 'Birthday',
                                Contact: 'Contact',
                                Cell: 'Cell',
                                Email: 'Email',
                                FirstName: 'First Name',
                                Have: 'Have',
                                ID: 'ID',
                                LastName: 'Last Name',
                                MembershipStatus: 'Membership Status',
                                Milestones: 'Milestones',
                                Personal: 'Personal',
                                Select: 'Select',
                                Gender: 'Gender',
                                Age: 'Age',
                                MissingRequirements: 'Missing Requirements',
                                OverrideRequirements: 'Override Requirements',
                                AreYouSure: 'Are you sure?',
                                Need: 'Need',
                                PersonAlreadyExistsIn: 'Person already exists in',
                                Parent: 'parent',
                                LoadMore: 'Load More',
                                TotalCount: 'Total: ',
                                View: 'View',
                                Add: 'Add',
                                Name: 'Name',
                                Role: 'Role',
                                Location: 'Location',
                                Filters: 'Filters',
                                Cancel: 'Cancel',
                            },
                            PersonSearchFilter: {
                                Apply: 'Apply',
                                Reset: 'Reset',
                            },
                            PersonSearchHelpModal: {
                                AboutSearch: 'About Search',
                                BooleanSearch: 'Boolean Search',
                                BooleanSearchBullet1: 'first-name:Shirly AND last-name:Miller',
                                BooleanSearchBullet2: 'first-name:Shirly OR first-name:Sally',
                                BooleanSearchBullet3: 'marital-status:"Married" AND age:39',
                                BooleanSearchBullet4: 'milestone.type:"Accepted Christ" NOT "Baptism"',
                                BooleanSearchParagraph1: 'We also have the ability to do Boolean searches, so you can search for multiple terms with AND, OR. Using any of the above techniques, you can simple join them with an AND or OR (case sensitive)',
                                BooleanSearchParagraph2: 'Returns people with firstname like Shirly AND lastname like Miller',
                                BooleanSearchParagraph3: 'Returns people with firstname like Shirly OR firstname like Sally',
                                BooleanSearchParagraph4: 'Returns people with maritalstatus status married and age 39',
                                BooleanSearchParagraph5: 'When you are searching for items, you may want to search a list of terms within a field that are NOT a certain term. To do this, you would put the keyword "NOT" in front of the term: <fieldnam>:NOT <term>',
                                BooleanSearchParagraph6: 'People that have accepted Christ, that have not been baptized.',
                                DateSearch: 'Date Search',
                                DateSearchBullet11: 'birthdate:11/1/1982',
                                DateSearchBullet12: 'birthdate:11-1-1982',
                                DateSearchBullet13: 'birthdate:1/1/82',
                                DateSearchBullet14: 'birthdate:11-1-82',
                                DateSearchBullet2: 'birthdate:[2000-01-01 TO 2016-07-01]',
                                DateSearchBullet3: 'milestone.date:[2016-06-01 TO 2016-07-01] milestone.type:"Accepted Christ"',
                                DateSearchBullet4: 'age:[20-30] AND marital-status:Single',
                                DateSearchParagraph1: 'Using any of the above techniques, you can also expand it to have date searches. However, dates must be MONTH, DAY, YEAR format. You can use 1 or digits to denote month and day, and 2 or 4 digits to denote year. Forward slashes (/), dashes (-), and periods (-) are acceptable dividers.',
                                DateSearchParagraph2: 'Returns all people whose birthday is on November 1, 1982.',
                                DateSearchParagraph3: 'When dealing with fields that are numbers or birthdates, it’s often necessary to search for ranges within that field. In order to do that, in the targeted field search, enclose the range with brackets and "TO" (case sensitive) between the 2 ranges: <fieldname>:[<firstrange> TO <secondrange>].',
                                DateSearchParagraph4: 'Return all people that have a birthday between Januaray 1, 2000 and July 1, 2016.',
                                DateSearchParagraph5: 'Return all people that have accepted Christ in the month of June (i.e. between June 1, 2016 and July 1, 2016).',
                                DateSearchParagraph6: 'Return all singles between the ages of 20 and 30.',
                                Grouping: 'Grouping',
                                GroupingBullet1: '(marital-status:"Married" OR martial-status:"Single") AND age:40',
                                GroupingParagraph1: 'You also have the ability to group any of the above so that you can narrow down the search more by groups, using the parentheses for the groups. For instance, if you are looking for all people who are (married OR Single) AND age is 40, you would have a query that looks like:',
                                GroupingParagraph2: 'All people who are (married OR Single) AND are 40 years of age',
                                Media: 'Media',
                                Overview: 'Overview',
                                OverviewParagraph1: 'The Healthy Church leverages Elastic search in order to facilitate a responsive and fast search. As the syntax for Elastic can vary from being simple, to extremely complicated, we want to ensure that users are at least able to execute some of the simpler commands for day to day use.',
                                QuickHelp: 'Quick Help',
                                QuickHelpList1: 'In the search bar, first type the NAME of the person (first & last).',
                                QuickHelpList11: 'If you find the name, check for at least one other matching identifier (match point), such as a matching email, address, or phone number to make certain you choose the correct “John Smith”.',
                                QuickHelpList12: 'If you only match a name and no other info, then proceed to Step 2',
                                QuickHelpList2: 'Search the other info you have: EMAIL, ADDRESS, PHONE OR DATE OF BIRTH',
                                QuickHelpList21: 'Always remember you need a name and another matching identifier to make sure you have the right person.',
                                QuickHelpList3: 'The wildcard is * and can be used to replace a letter or letters that are unreadable',
                                QuickHelpList4: 'All multi-word names, terms or phrases that need to be treated as a single value must be enclosed in double quotation marks, e.g. "Accepted Christ" as a miletone type, "Los Angeles" as a city, "Mary Ann" as a name, etc.  If you don\'t do this, your search will not be parsed correctly and you may get unexpected results.',
                                QuickHelpList5: 'If you cannot find the person after searching all info, then click on Add Person + button and carefully enter the name and contact info.',
                                QuickHelpList51: 'If you do not see the Add Person + button in the upper right hand corner of the screen, then your account does not have the permission to add new records to the system. Please contact your administrator to request permission to perform this action.',
                                QuickHelpParagraph1: 'Additional advanced search features are available to you. Help on how to perform advanced searches is listed below.',
                                People: 'People',
                                PhraseSearch: 'Phrase Search',
                                PhraseSearchBullet1: 'Accepted Christ',
                                PhraseSearchParagraph1: 'Often times, we may want to search for a phrase with multiple terms. In this case, in your search, enclose the entire phrase in quotation marks.',
                                PhraseSearchParagraph2: 'Return all people who have the phrase "Accepted Christ" anywhere in the fields listed above.',
                                RangeSearch: 'Range Search',
                                SearchableFields: 'Searchable Fields',
                                SearchableFieldsPeopleBullet1: 'id',
                                SearchableFieldsPeopleBullet2: 'church-entity-name',
                                SearchableFieldsPeopleBullet3: 'church-entity-id',
                                SearchableFieldsPeopleBullet4: 'prefix',
                                SearchableFieldsPeopleBullet5: 'first-name',
                                SearchableFieldsPeopleBullet6: 'last-name',
                                SearchableFieldsPeopleBullet7: 'middle-name',
                                SearchableFieldsPeopleBullet8: 'nickname',
                                SearchableFieldsPeopleBullet9: 'suffix',
                                SearchableFieldsPeopleBullet10: 'gender',
                                SearchableFieldsPeopleBullet11: 'marital-status',
                                SearchableFieldsPeopleBullet12: 'birthdate',
                                SearchableFieldsPeopleBullet13: 'age',
                                SearchableFieldsPeopleBullet14: 'deceased-date',
                                SearchableFieldsPeopleBullet15: 'grade',
                                SearchableFieldsPeopleBullet16: 'address.primary',
                                SearchableFieldsPeopleBullet17: 'address.address1',
                                SearchableFieldsPeopleBullet18: 'address.address2',
                                SearchableFieldsPeopleBullet19: 'address.city',
                                SearchableFieldsPeopleBullet20: 'address.country',
                                SearchableFieldsPeopleBullet21: 'address.region',
                                SearchableFieldsPeopleBullet22: 'address.postal-code',
                                SearchableFieldsPeopleBullet23: 'primary-email',
                                SearchableFieldsPeopleBullet24: 'email',
                                SearchableFieldsPeopleBullet25: 'phone.primary',
                                SearchableFieldsPeopleBullet26: 'primary-phone-type',
                                SearchableFieldsPeopleBullet27: 'phone.number',
                                SearchableFieldsPeopleBullet28: 'phone.type',
                                SearchableFieldsPeopleBullet29: 'preferred-contact-method',
                                SearchableFieldsPeopleBullet30: 'do-not-contact-method',
                                SearchableFieldsPeopleBullet31: 'do-not-contact-this-individual',
                                SearchableFieldsPeopleBullet32: 'staff-member',
                                SearchableFieldsPeopleBullet33: 'occupation.type',
                                SearchableFieldsPeopleBullet34: 'occupation.place-of-employment',
                                SearchableFieldsPeopleBullet35: 'occupation.industry',
                                SearchableFieldsPeopleBullet36: 'occupation.job-title',
                                SearchableFieldsPeopleBullet37: 'occupation.campus',
                                SearchableFieldsPeopleBullet38: 'occupation.department',
                                SearchableFieldsPeopleBullet39: 'membership-status',
                                SearchableFieldsPeopleBullet40: 'milestone.church-entity-name',
                                SearchableFieldsPeopleBullet41: 'milestone.church-entity-id',
                                SearchableFieldsPeopleBullet42: 'milestone.date',
                                SearchableFieldsPeopleBullet43: 'milestone.location',
                                SearchableFieldsPeopleBullet44: 'milestone.type',
                                SearchableFieldsPeopleBullet45: 'last-give-date',
                                TargetedFieldSearch: 'Targeted Field Search',
                                TargetedFieldSearchParagraph1: 'If you do not want to do a global wide search, but instead know exactly which field you want to search on, you can use a targeted field search by typing in one of the EXACT field names (case sensitive) listed above, followed by a colon and the term (<fieldname>:<value>)',
                                TargetedFieldSearchParagraph2: 'Returns all people who have the first name Shirly, or people whose first name look similar to Shirly (i.e. Shirley)',
                                TargetedFieldSearchParagraph3: 'Returns all people who have a milestone of "Accepted Christ" exactly',
                                TargetedFieldSearchParagraph4: 'Returns people with the first name exactly "Cris".  Without ~0, you will get "Cris", but also near matches, like "Chris" or "Kris", so use ~0 when you only want exact matches.',
                                TargetedFieldSearchParagraph5: 'Returns all people that have an address with city of "Los Angeles".  Because "Los Angeles" is two words, it must be enclsed in double quotes.',
                                TargetedFieldSearchBullet1: 'first-name:Shirly',
                                TargetedFieldSearchBullet2: 'milestone.type:"Accepted Christ"',
                                TargetedFieldSearchBullet3: 'first-name:cris~0',
                                TargetedFieldSearchBullet4: 'address.city:"Los Angeles"',
                                TermSearch: 'Term Search',
                                TermSearchBullet1: 'Shirly',
                                TermSearchBullet2: 'Single',
                                TermSearchBullet3: 'Joseph',
                                TermSearchParagraph1: 'A Term search is the simplest search, where you type in any word or words. Note that these will search the enter HC system and your search may return results with folks who have the term in their name, or address, etc. The term search searches for similar matches as well. If you want exact, enclose the term in quotes.',
                                TermSearchParagraph2: 'Returns all people with the word "Shirly" anywhere in the fields listed above. Since this is not in quotes, will also return similar people, like "Shirley".',
                                TermSearchParagraph3: 'Returns all people with the word "Single" listed anywhere in the fields above.',
                                TermSearchParagraph4: 'Returns all people with the exact word "Joseph" anywhere in the fields listed above.',
                            },
                            PersonSearchRow: {
                                Id: 'ID',
                                Name: 'Name',
                                Church: 'Church',
                                Email: 'Email',
                                Mail: 'Mail',
                                Phone: 'Phone',
                                TextMessage: 'Text Message',
                                Home: 'Home',
                                Mobile: 'Mobile',
                                Work: 'Work',
                                Address: 'Address',
                                HomeShort: 'H',
                                MobileShort: 'M',
                                WorkShort: 'W',
                                Birthdate: 'Birthdate',
                                ContactPrefs: 'Contact Preferences',
                                PreferredMethod: 'Preferred Method',
                                Prefers: 'Prefers',
                                NoPref: 'No Preferred Contact Method',
                                DoNotContact: 'Do Not Contact',
                                DoNotContactVia: 'Do Not Contact via',
                                DoNotContactIndividual: 'Do Not Contact This Individual',
                            },
                        },
                    },
                    PersonNotes: {
                        EmbeddedPersonCreateNote: {
                            Cancel: 'Cancel',
                            FollowUps: 'Follow Ups',
                            NewNote: 'New Note',
                            SaveNote: 'Save Note',
                            SensitiveInformation: 'Sensitive Information',
                            SuccessfullyAddedNote: 'Successfully added note.',
                        },
                        EmbeddedPersonNoteDetails: {
                            AreYouSure: 'Are You Sure',
                            Back: 'Back',
                            By: 'By',
                            CMEditor: 'CM Editor',
                            Close: 'Close',
                            EditNote: 'Edit Note',
                            Note: 'Note',
                            NoteDetails: 'Note Details',
                            RemoveNote: 'Remove Note',
                            SuccessfullyDeletedNote: 'Successfully deleted note.',
                        },
                        EmbeddedPersonNotes: {
                            AddNote: 'Add Note',
                            AllTopics: 'All Topics',
                            By: 'By',
                            CMEditor: 'CM Editor',
                            Close: 'Close',
                            Notes: 'Notes',
                        },
                        EmbeddedPersonUpdateNote: {
                            Cancel: 'Cancel',
                            EditNote: 'Edit Note',
                            FollowUps: 'Follow Ups',
                            SaveNote: 'Save Note',
                            SensitiveInformation: 'Sensitive Information',
                            SuccessfullyEditedNote: 'Successfully edited note.',
                            Topic: 'Topic',
                        },
                    },
                    ResponseCard: {
                        ResponseCardSearch: {
                            FindAResponseCard: 'Find A Response Card By Title',
                            PageTitle: 'Connection Cards',
                            NewCard: 'New Card',
                            ChurchLocation: 'Church Location',
                            LoadMore: 'Load More',
                            No: 'No',
                            Select: 'Select',
                            Filter: 'Filter',
                            Total: 'Total',
                            Yes: 'Yes',
                        },
                        ResponseCardSearchFilter: {
                            FilterBy: 'Filter By',
                            ChurchLocation: 'Church Location',
                            MakeSelectionBelow: 'Make a Selection Below',
                            Apply: 'Apply',
                            SearchForLocation: 'Search for location(s)',
                            Reset: 'Reset',
                        },
                        ResponseCardSearchRow: {
                            Title: 'Title',
                            Church: 'Church',
                            Version: 'Version',
                            LastUpdated: 'Last Updated',
                            AddEntry: 'Add New Entry',
                            View: 'View Response Card',
                        },
                        ResponseCardManager: {
                            Apply: 'Apply',
                            Cancel: 'Cancel',
                            CardName: 'Card Name',
                            CardFields: 'Card Fields',
                            DataEntry: 'Data Entry',
                            Edit: 'Edit',
                            EditResponseCard: 'Edit Connection Card',
                            NewResponseCard: 'New Connection Card',
                            Save: 'Save',
                            Settings: 'Settings',
                            Edit: 'Edit Connection Card',
                            Information: 'Information',
                            Title: 'Connection Card Name',
                            ResponseCard: 'Connection Card',
                            Version: 'Version',
                            LastUpdated: 'Last Updated',
                            PleaseFillInTitle: 'Connection Card Name is required',
                            ChurchLocation: 'Campus',
                            EventType: 'Event Type',
                            PleaseSelectChurch: 'Connection Card Campus association is required',
                            Locale: 'Language',
                            PleaseSelectLocale: 'Connaction Card Language is required',
                            Select: 'Select',
                            FormFields: 'Form Fields',
                            AddFormField: 'Add Form Field',
                            AddSection: 'New Section',
                            General: 'General',
                            EditGeneralInfo: 'Edit General Info',
                            PublishToWeb: 'Publish to Web',
                            Public: 'Public Labels',
                            No: 'No',
                            NowPublished: 'This Card is now Published and viewable publicly.',
                            NowUnpublished: 'This Card is now Unpublished and not viewable publicly.',
                            UpdateGeneralInfo: 'Update General Info',
                            ReorderSections: 'Reorder Sections',
                            DoneReorderingSections: 'Done Reordering Sections',
                            CancelRederingSections: 'Cancel Section Reordering',
                            PublicFacing: 'Public Facing',
                            SectionTitle: 'Section',
                            SectionSubTitle: 'Subtitle',
                            SuccessfullySaved: 'Successfully Saved Card',
                            SuccessfullySavedLabels: 'Successfully Saved Card Public Labels',
                            SuccessfullyPublished: 'Published Setting Changed',
                            Workflow: 'Workflow',
                            Yes: 'Yes',
                        },
                        ResponseCardView: {
                            PageTitle: 'Response Card Details',
                            ResponseCardName: 'Response Card',
                            CurrentVersion: 'Current Version',
                            Version: 'Version',
                            LastUpdated: 'Last Updated',
                            ChurchLocation: 'Church',
                            EventType: 'Event Type',
                            Locale: 'Language',
                            Edit: 'Edit',
                            AddNewEntry: 'Add New Entry',
                            ViewPersonEntries: 'View Person Entries',
                        },
                        ResponseCardFieldView: {
                            Type: 'Field Type',
                            Label: 'Field Name',
                            Selections: 'Selections',
                            SubFields: 'Sub-Fields',
                            SortOrder: 'Order',
                            Options: 'Options',
                        },
                        ResponseCardFieldManager: {
                            AddChoice: 'Add Choice', // for choices in selects and radios
                            AddSubField: 'Add Choice', // for choices in checkboxes (just in case we want a different term)
                            Choice: 'Choice',
                            Delete: 'Delete Field',
                            FieldName: 'Field Title',
                            FieldNameOptional: 'Title (Optional)',
                            Help: 'Help',
                            RemoveChoice: 'Remove Choice', // for choices in selects and radios
                            RemoveSubField: 'Remove Choice', // for choices in checkboxes (just in case we want a different term)
                            SortOrder: 'Order',
                            SubField: 'Sub-Field', // used to describe the sub-fields within a template field
                            ViewTemplates: 'View Templates',
                            UmWhatsThis: 'Um, what\'s this',
                            CheckboxTitleField: 'Checkbox Title Field',
                            DescriptionLine1: 'As seen in the example below:',
                            DescriptionRest: 'Adding a checkbox title can be handy when multiple choices need to live under one main heading',
                            PanelTitle: 'Panel Title',
                            CheckboxTitle: 'Checkbox Title',
                        },
                        ResponseCardSectionManager: {
                            NewSection: 'New Section',
                            SectionTitle: 'Section Title',
                            SectionSubTitle: 'Section Subtitle',
                            AddField: 'Add Field',
                            SaveSection: 'Save Section',
                            DeleteSection: 'Delete Section',
                            EditSection: 'Edit Section',
                            Cancel: 'Stop Editing',
                            Sensitive: 'Sensitive',
                            SortOrder: 'Order',
                            Select: 'Select',
                            ExpandToViewDetails: 'Expand to View Details',
                            Field: 'Field',
                            Fields: 'Fields',
                            TotalFields: 'Total Fields',
                            ReorderFields: 'Reorder Fields',
                            DoneReorderingFields: 'Done Reordering Fields',
                            CancelReorderingFields: 'Cancel Field Reordering',
                            DoneSensitiveFields: 'Done Setting Field Sensitivity',
                            CancelSensitiveFields: 'Cancel Setting Field Sensitivity',
                        },
                    },
                    ResponseCardEntry: {
                        BrowseResponseCardEntriesByCard: {
                            ActivateCard: 'Activate Card',
                            AddResponseCardEntry: 'Add Entry',
                            Back: 'Back',
                            ChurchLocation: 'Church',
                            CurrentVersion: 'Current Version',
                            DeactivateCard: 'Deactivate Card',
                            EditCard: 'Edit Card',
                            EventType: 'Event Type',
                            Filter: 'Filter',
                            LastUpdated: 'Last Updated',
                            LastWeekendTotal: 'Last Weekend Total',
                            LoadMore: 'Load More',
                            Locale: 'Locale',
                            NewEntry: 'New Entry',
                            No: 'No',
                            NowActive: 'This Card is now Active.',
                            NowInactive: 'This Card is now Inactive.',
                            PageTitle: 'Browse Response Card Entries',
                            Published: 'Published',
                            ResponseCardName: 'Response Card',
                            RunWorkflows: 'Run Workflows',
                            Search: 'Search',
                            SuccessfullyActivated: 'Successfully Activated',
                            SuccessfullyDeactivated: 'Successfully Deactivated',
                            SuccessfullyRanWorkflows: 'Successfully ran workflows',
                            WhoLookingFor: 'Who are you looking for?',
                            Yes: 'Yes',
                        },
                        BrowseResponseCardEntriesByPerson: {
                            Back: 'Back',
                            Campus: 'Campus',
                            Filter: 'Filter',
                            Id: 'Id',
                            PageTitle: 'Browse Response Card Entries by Person',
                            Person: 'Person',
                        },
                        BrowseResponseCardEntriesByPersonSearchRow: {
                            ResponseCardName: 'Response Card',
                            Church: 'Church',
                            WorshipService: 'Worship Service',
                            CreatedOrUpdated: 'Created/Updated',
                        },
                        PersonResponseCardEntryFilter: {
                            FilterBy: 'Filter By',
                            Apply: 'Apply',
                            Reset: 'Reset',
                            MakeSelectionsBelow: 'Make Selections Below',
                            ChurchLocation: 'Church',
                            EventType: 'Event Type',
                            Select: 'Select',
                        },
                        ResponseCardEntrySearchFilter: {
                            Apply: 'Apply',
                            FilterBy: 'Filter By',
                            IncludeDeceased: 'Include Entries for Deceased Persons?',
                            MakeSelectionBelow: 'Make a Selection Below',
                            Reset: 'Reset',
                        },
                        ResponseCardEntryManager: {
                            AddEntry: 'Add Entry',
                            Apply: 'Apply',
                            Back: 'Back',
                            Campus: 'Campus',
                            Cancel: 'Cancel',
                            Continue: 'Continue',
                            DataEntryNotes: 'Data Entry Notes',
                            DateAndTime: 'Date & Time',
                            DateAndTimeSettings: 'Date & Time Settings',
                            EditDateAndTime: 'Edit Date & Time',
                            EditEntry: 'Edit Entry',
                            Find: 'Find',
                            For: ' for ',
                            Id: 'ID',
                            LinkedPerson: 'Linked Person',
                            LinkToPerson: 'Select Existing Person Record',
                            New: 'New',
                            NewEntry: 'New Entry',
                            NoAttachment: 'This entry will not attach to any person record when saved',
                            NoRecord: 'No Record',
                            Optional: 'Optional',
                            OrCancel: 'or Cancel if No Person',
                            Person: 'Person',
                            PossibleMatches: 'possible matches',
                            ResponseCardName: 'Response Card',
                            ResponseCard: 'Response Card',
                            Save: 'Save',
                            Select: 'Select Person',
                            SelectPerson: 'Select Person',
                            Settings: 'Settings',
                            Search: 'Search',
                            SetAndAddEntry: 'Set & Add Entry',
                            ServiceDate: 'Service Date',
                            ServiceTime: 'Service Time',
                            SuccessfullySavedEntry: 'Successfully saved response card',
                            UpdateDateAndTime: 'Update Date & Time',
                            ViewMatches: 'View matches',
                            Version: 'Version',
                            WorshipService: 'Worship Service',
                        },
                        ResponseCardEntryPersonAttachDialog: {
                            PossibleMatch: 'Possible Match',
                            Previous: 'Previous',
                            Next: 'Next',
                            Of: 'of',
                            Personal: 'Personal',
                            Title: 'Title',
                            FirstName: 'First Name',
                            MiddleName: 'Middle Name',
                            LastName: 'Last Name',
                            Address: 'Address',
                            MobilePhone: 'Mobile Phone',
                            HomePhone: 'Home Phone',
                            WorkPhone: 'Work Phone',
                            Email: 'Email',
                            UseMatch: 'Use Match',
                            RemoveFromMatches: 'Remove from Matches',
                        },
                        ResponseCardEntrySaveDialog: {
                            AddAnother: 'Add another entry for this card?',
                            CreateAndAttach: 'Create & Attach Entry',
                            JustSaveEntry: 'Just Save New Entry?',
                            Or: 'or',
                            SaveAndAttachEntry: 'Save & Attach Entry',
                            SaveAndAttach: 'Save & Attach',
                            Save: 'Save',
                            SaveNewEntry: 'Save New Entry?',
                            ToNewPerson: 'to a new person:',
                            ToSelectedMatch: 'to the selected match?',
                            To: 'to',
                        },
                        ResponseCardEntryView: {
                            Address: 'Address',
                            Back: 'Back',
                            ChurchEntity: 'Church',
                            CreateDate: 'Created',
                            DataEntryNotes: 'Data Entry Notes',
                            Edit: 'Edit',
                            EditEntry: 'Edit Entry',
                            EventType: 'Event Type',
                            Hide: 'Hide',
                            Information: 'Response Card Entry Info',
                            ModifyDate: 'Updated',
                            Name: 'Name',
                            PageTitle: 'View Entry',
                            ResponseCardTitle: 'Response Card',
                            ResponseCardVersion: 'Version',
                            ServiceDateTime: 'Worship Service',
                            Show: 'Show',
                        },
                        ResponseCardEntryOverview: {
                            Address: 'Address',
                            DataEntryNotes: 'Data Entry Notes',
                            Name: 'Name',
                        },
                    },
                    Security: {
                        AuditLog: {
                            Additions: 'Additions',
                            After: 'After',
                            Before: 'Before',
                            Changes: 'Changes',
                            Name: 'Name',
                            Permission: 'Permission',
                            Removed: 'Removed',
                            Removals: 'Removals',
                            SecurityRoleName: 'Security Role Name',
                        },
                        SecurityRoleBlade: {
                            TopLevel: 'Top Level',
                        },
                        SecurityRoleBladeSearch: {
                            SearchResults: 'Search Results',
                        },
                        SecurityRoleManager: {
                            AddPermissions: 'Add Permissions',
                            Back: 'Back',
                            Cancel: 'Cancel',
                            Description: 'Description',
                            Edit: 'Edit',
                            Name: 'Name',
                            Permissions: 'Permissions',
                            PleaseFillInName: 'Please fill in the role name.',
                            RoleName: 'Role Name',
                            SearchForPermissions: 'Search for Permissions',
                            SecurityRoles: 'Security Roles',
                            Title: 'Security Roles',
                        },
                        SecurityRoleSearch: {
                            AddRole: 'Add Role',
                            Description: 'Description',
                            NoSecurityRoles: 'There are currently no security roles.',
                            Or: 'or',
                            RoleName: 'Role Name',
                            Title: 'Security Roles',
                        },
                        SecurityRoleView: {
                            Back: 'Back',
                            Title: 'Security Roles',
                            RoleName: 'Role Name',
                            Name: 'Name',
                            Description: 'Description',
                            Permissions: 'Permissions',
                            SearchForPermissions: 'Search for Permissions',
                            Edit: 'Edit',
                        },
                        SecurityScopeManager: {
                            Back: 'Back',
                            Cancel: 'Cancel',
                            Description: 'Description',
                            Edit: 'Edit',
                            Name: 'Name',
                            PleaseFillInName: 'Please fill in the scope name.',
                            ScopeName: 'Scope Name',
                            SecurityScopes: 'Security Scopes',
                            Title: 'Security Scopes',
                            Regions: 'Regions',
                            ChurchEntities: 'Locations',
                            Campuses: 'Campuses',
                            Venues: 'Venues',
                            Locations: 'Locations',
                            Ministries: 'Ministries',
                            AddMinistry: 'Add Ministry',
                            AddScope: 'Add Scope',
                            AllLocations: 'All Locations',
                            AllMinistries: 'All Ministries',
                            Total: 'Total',
                            NotAssignedChurchEntities: 'Include records with \'Does Not Attend\' and \'Unknown\' as their campus',
                            Scopes: 'Scoped Roles',
                            ScopeOfRole: 'Scope of Role(s)',
                            Remove: 'Remove',
                            Change: 'Change',
                        },
                    },
                    User: {
                        UserAddFromPersonDrawer: {
                            Title: 'Add User',
                            Person: 'Person',
                            New: 'New',
                            Search: 'Search',
                            Cancel: 'Cancel',
                            Save: 'Save',
                        },
                        UserManager: {
                            AddUser: 'Add User',
                            AllRegions: 'All Regions',
                            Back: 'Back',
                            Campus: 'Campus',
                            Cancel: 'Cancel',
                            ChurchStructure: 'Church Structure Security',
                            Email: 'Email',
                            FirstName: 'First Name',
                            ID: 'ID',
                            Information: 'Information',
                            LastName: 'Last Name',
                            Level: 'Level',
                            NotApplicable: 'N/A',
                            PersonForbidden: 'You are not authorized to access Person {0}',
                            PersonNotFound: 'Person {0} could not be found',
                            PleaseFillInEmail: 'Please fill in Email',
                            PleaseFillInFirstName: 'Please fill in First Name',
                            PleaseFillInLastName: 'Please fill in Last Name',
                            PleaseFillInPersonId: 'Please fill in Person ID',
                            PleaseSelectAnOptionForLevel: 'Please select an option for level',
                            Save: 'Save',
                            Security: 'Security',
                            SecurityRoles: 'Roles & Permissions',
                            Select: 'Select',
                            User: 'User',
                            UserManagement: 'User Management',
                            Users: 'Users',
                            WasUpdated: 'Was Updated',
                            RolesAndPermissions: 'Roles & Permissions',
                        },
                        UserNotificationPreferences: {
                            SaveButton: 'Save & Update',
                            CancelButton: 'Cancel',
                            Title: 'Notification Preferences',
                            Subtitle1: 'Receive Notifications by:',
                            Subtitle2: 'Emails',
                            Subtitle3: 'InApp',
                            Subtitle4: 'Text',
                            TaskAssignments: 'Task Assignments',
                            TaskReassignments: 'Task Reassignments',
                            TaskComments: 'Task Comments',
                            BlockedTasks: 'Blocked Tasks',
                            OverdueTasks: 'Overdue Tasks',
                            MinistryMember: 'Ministry Member Email',
                        },
                        UserSearch: {
                            ActiveUsers: 'Active Users',
                            AddUserButton: 'Add User',
                            Church: 'Campus',
                            Email: 'Email',
                            FirstName: 'First Name',
                            Id: 'ID',
                            InActiveUsers: 'Disabled Accounts',
                            LastName: 'Last Name',
                            LoadMore: 'Load More',
                            RolesAssignedTo: 'Role(s) Assigned To',
                            Search: 'Search',
                            Subtitle: 'User Search and Management',
                            Title: 'Users',
                            WhoLookingFor: 'Who are you looking for?',
                        },
                        UserView: {
                            Back: 'Back',
                            Campus: 'Campus',
                            ChurchStructure: 'Church Structure',
                            Edit: 'Edit',
                            Email: 'Email',
                            ID: 'ID',
                            Information: 'Information',
                            Level: 'Level',
                            Name: 'Name',
                            NotApplicable: 'N/A',
                            RoleName: 'Role Name',
                            Roles: 'Roles',
                            SecurityRoles: 'Roles & Permissions',
                            Title: 'View User',
                            AllRegions: 'All Regions',
                        },
                    },
                    EventSelector: {
                        Date: 'Date',
                        Month: 'Month',
                        Select: 'Select',
                        ServiceTime: 'Service Time',
                        Time: 'Time',
                        Type: 'Event Type',
                    },
                    Home: {
                        Title: 'Church Management',
                        Subtitle: 'A Place For Staff To Connect',
                    },
                    InnerNavBar: {
                        Back: 'Back',
                    },
                    InnerNavBarSave: {
                        NotSaved: 'Not Saved',
                        Saving: 'Saving',
                        Save: 'Save',
                    },
                    InputDoc: {
                        Cancel: 'Cancel',
                        Delete: 'Delete',
                        DeleteDocument: 'Delete Document',
                        DeleteConfirmation: 'You are you about to delete a document. Are you sure you want to do this?',
                        Ok: 'Ok',
                        ViewDocument: 'View Document',
                    },
                    InputFile: {
                        AddNote: 'Add a Note (optional)',
                        Cancel: 'Cancel',
                        DisplayName: 'Display Name',
                        FileTriedUploading: 'The file you tried uploading (',
                        GenericErrorMessage: 'There was a problem while trying to upload your file. Possibly it is too large. You can only upload 10mb files.',
                        AlreadyUploadedMessage: 'The file is already uploaded. Would you like to overwrite it?',
                        Yes: 'Yes',
                        No: 'No',
                        Retry: 'Retry',
                        Save: 'Save',
                        SaveAndAttach: 'Save & Attach',
                        SelectFile: 'Select File',
                        SuccessMessage: 'Success! Your file has been uploaded.',
                        TooLarge: ') is too large. You can only upload 10mb files.',
                        UploadFailed: 'Upload Failed',
                        UploadFile: 'Upload A File',
                        UnsupportedType: ') is an unsupport file type. Supported file types: .bmp, .doc, .docx, .gif, .ico, .jpg,, .pdf, .png, .xlsx, and .zip',
                    },
                    InputFileBox: {
                        FileTriedUploading: 'The file you tried uploading (',
                        GenericErrorMessage: 'There was a problem while trying to upload your file. Possibly it is too large. You can only upload 10mb files.',
                        Retry: 'Retry',
                        TooLarge: ') is too large. You can only upload 10mb files.',
                        UploadFailed: 'Upload Failed',
                        UploadFile: 'Upload A File',
                        UnsupportedType: ') is an unsupport file type. Supported file types: .bmp, .doc, .docx, .gif, .ico, .jpg,, .pdf, .png, .xlsx, and .zip',
                    },
                    ChangePassword: {
                        Password: 'Password',
                        PleaseEnterPassword: 'Please enter a password.',
                        ConfirmPassword: 'Confirm Password',
                        PasswordsMustMatch: 'Password and confirmation password must match.',
                    },
                    DataEntryStore: {
                        // these are the names of all the metrics
                        // this is probably a temporary solution
                        // eventually might have to i18n / l10n the metric names some other way...???
                        Attendance: 'Attendance',
                        Baptisms: 'Baptisms',
                        Salvations: 'Salvations',
                        'Children\'s Attendance': 'Children\'s Attendance',
                        'Children\'s Baptisms': 'Children\'s Baptisms',
                        'Children\'s Salvations': 'Children\'s Salvations',
                        'College Attendance': 'College Attendance',
                        'College Baptisms': 'College Baptisms',
                        'College Salvations': 'College Salvations',
                        'High School Attendance': 'High School Attendance',
                        'High School Baptisms': 'High School Baptisms',
                        'High School Salvations': 'High School Salvations',
                        'Junior High Attendance': 'Junior High Attendance',
                        'Junior High Baptisms': 'Junior High Baptisms',
                        'Junior High Salvations': 'Junior High Salvations',
                        Parking: 'Parking',
                        Volunteers: 'Volunteers',
                    },
                    BrowseFactsStore: {
                        DefaultDataGridMessage: 'Data Entries Awaiting Verification',
                        NoResultsMessage: 'No Data Entries Awaiting Verification found',
                    },
                    BrowseFactsSearchOptionStore: {
                        ChurchLocation: 'Church Location',
                        Month: 'Month',
                        DayAndTime: 'Day & Time',
                    },
                    DateUtils: {
                        Today: 'Today',
                        Tomorrow: 'Tomorrow',
                        Yesterday: 'Yesterday',
                    },
                    EnumerationStore: {
                        /* eslint-disable sort-keys */
                        // Some i18n for Enums
                        // this is probably a temporary solution
                        // eventually might have to i18n / l10n enums served from DB/API some other way...???
                        //    Genders
                        Male: 'Male',
                        Female: 'Female',

                        // Grade Levels
                        PreK: 'PreK',
                        Kindergarten: 'Kindergarten',
                        First: 'First',
                        Second: 'Second',
                        Third: 'Third',
                        Fourth: 'Fourth',
                        Fifth: 'Fifth',
                        Sixth: 'Sixth',
                        Seventh: 'Seventh',
                        Eighth: 'Eighth',
                        Ninth: 'Ninth',
                        Tenth: 'Tenth',
                        Eleventh: 'Eleventh',
                        Twelfth: 'Twelfth',

                        //     Marital Statuses
                        Single: 'Single',
                        Married: 'Married',
                        Divorced: 'Divorced',
                        Widowed: 'Widowed',

                        //    Membership Statuses
                        Unknown: 'Unknown',
                        unknown: 'Unknown',
                        NonMember: 'Non-Member',
                        'Non-Member': 'Non-Member',
                        Pending: 'Pending',
                        Member: 'Member',
                        'Disengaged Member': 'Disengaged Member',
                        'Membership Review': 'Membership Review',
                        Deceased: 'Deceased',

                        //     Messaging Platforms
                        Facebook: 'Facebook',
                        Instagram: 'Instagram',
                        'QQ Mobile': 'QQ Mobile',
                        Skype: 'Skype',
                        Twitter: 'Twitter',
                        Viber: 'Viber',
                        WeChat: 'WeChat',
                        WhatsApp: 'WhatsApp',

                        //     Milestone Types
                        'First Attended Date': 'First Attended Date',
                        'Accepted Christ': 'Accepted Christ',
                        Baptism: 'Baptism',
                        'Became a Member': 'Became a Member',
                        'Children\'s Membership': 'Children\'s Membership',
                        'CL Quick Start': 'CL Quick Start',
                        'Class 101': 'Class 101',
                        'Class 201': 'Class 201',
                        'Class 301': 'Class 301',
                        'Class 401': 'Class 401',
                        'Completed SHAPE Discovery Session': 'Completed SHAPE Discovery Session',
                        'Data Covenant': 'Data Covenant',
                        'Data Entry': 'Data Entry',
                        'DOJ Cleared': 'DOJ Cleared',
                        'FBI Cleared': 'FBI Cleared',
                        Fingerprinted: 'Fingerprinted',
                        'First Contact Date': 'First Contact Date',
                        'Healthy Church Data Covenant': 'Healthy Church Data Covenant',
                        'Leadership Training 1': 'Leadership Training 1',
                        'Leadership Training 2 - Character': 'Leadership Training 2 - Character',
                        'Leadership Training 2 - Empower': 'Leadership Training 2 - Empower',
                        'Leadership Training 2 - Health': 'Leadership Training 2 - Health',
                        'Leadership Training 2 - PEACE': 'Leadership Training 2 - PEACE',
                        'Leadership Training 2 - Reconciliation': 'Leadership Training 2 - Reconciliation',
                        'Leadership Training 2 - Skills': 'Leadership Training 2 - Skills',
                        'Leadership Training 2 - Tools': 'Leadership Training 2 - Tools',
                        'Leadership Training 5 - Advanced Individuals': 'Leadership Training 5 - Advanced Individuals',
                        'Ministry Manager Training': 'Ministry Manager Training',
                        'New Host Gathering': 'New Host Gathering',
                        'Prayer Ministry Covenant & Orientation': 'Prayer Ministry Covenant & Orientation',
                        'Saddleback Kids Baptism Class': 'Saddleback Kids Baptism Class',
                        'SCC - Email to Staff': 'SCC - Email to Staff',
                        'SCC - Interview': 'SCC - Interview',
                        'SCC - Megan\'s Law': 'SCC - Megan\'s Law',
                        'SCC - Name Badge Issued': 'SCC - Name Badge Issued',
                        'SCC - Orientation': 'SCC - Orientation',
                        'SCC - Volunteer Training 1': 'SCC - Volunteer Training 1',
                        'SCC - Volunteer Training 2': 'SCC - Volunteer Training 2',
                        'Signed Maturity Covenant': 'Signed Maturity Covenant',
                        'Signed Membership Agreement': 'Signed Membership Agreement',
                        'Signed Ministry Covenant': 'Signed Ministry Covenant',
                        'Signed Mission Commitment': 'Signed Mission Commitment',
                        'SK - Email to Staff': 'SK - Email to Staff',
                        'SK - Interview': 'SK - Interview',
                        'SK - Leader Training - Part 1': 'SK - Leader Training - Part 1',
                        'SK - Leader Training - Part 2': 'SK - Leader Training - Part 2',
                        'SK - Megan\'s Law': 'SK - Megan\'s Law',
                        'SK - Name Badge Issued': 'SK - Name Badge Issued',
                        'SK - Orientation': 'SK - Orientation',
                        'SSM - Email to Staff': 'SSM - Email to Staff',
                        'SSM - Interview': 'SSM - Interview',
                        'SSM - Megan\'s Law': 'SSM - Megan\'s Law',

                        //    Ministry Statuses
                        Closed: 'Closed',
                        Open: 'Open',
                        'Pending Approval': 'Pending Approval',

                        //  Occupation Types
                        Agriculture: 'Agriculture',
                        Architecture: 'Architecture',
                        'Biological and Biomedical Sciences': 'Biological and Biomedical Sciences',
                        Business: 'Business',
                        'Communications and Journalism': 'Communications and Journalism',
                        'Computer Sciences': 'Computer Sciences',
                        'Culinary Arts and Personal Services': 'Culinary Arts and Personal Services',
                        Education: 'Education',
                        Engineering: 'Engineering',
                        Legal: 'Legal',
                        'Liberal Arts and Humanities': 'Liberal Arts and Humanities',
                        'Mechanic and Repair Technologies': 'Mechanic and Repair Technologies',
                        'Medical and Health Professions': 'Medical and Health Professions',
                        'Physical Sciences': 'Physical Sciences',
                        Psychology: 'Psychology',
                        'Transportation and Distribution': 'Transportation and Distribution',
                        'Visual and Performing Arts': 'Visual and Performing Arts',

                        //     Event Types
                        'Serving Opportunity': 'Serving Opportunity',
                        'Worship Service': 'Worship Service',

                        //    Phone Types
                        Cell: 'Cell',
                        'Cell Phone': 'Cell Phone',
                        Home: 'Home',
                        'Home Phone': 'Home Phone',
                        Other: 'Other',
                        Work: 'Work',
                        'Work Phone': 'Work Phone',

                        //    Recurrence Frequencies
                        Daily: 'Daily',
                        Weekly: 'Weekly',
                        'Bi-Weekly': 'Bi-Weekly',
                        'Tri-Weekly': 'Tri-Weekly',
                        'First Week of Month': 'First Week of Month',
                        'Second Week of Month': 'Second Week of Month',
                        'Third Week of Month': 'Third Week of Month',
                        'Fourth Week of Month': 'Fourth Week of Month',
                        'Last Week of Month': 'Last Week of Month',

                        //    Ministry Member Statuses
                        Interested: 'Interested',
                        'Not Contacted': 'Not Contacted',
                        'First Contact': 'First Contact',
                        'Second Contact': 'Second Contact',
                        // "Pending" is also a Church-level Membership Status
                        InTraining: 'In Training',
                        'In Training': 'In Training',
                        Inactive: 'Inactive',
                        Active: 'Active',

                        Minutes: 'Minutes',
                        Hours: 'Hours',
                        Days: 'Days',
                        Weeks: 'Weeks',
                        /* eslint-enable sort-keys */
                    },
                    MinistrySearchStore: {
                        DefaultDataGridMessage: 'Please use the search above to render some results.',
                        NoResultsMessage: 'No records were returned. Please try again.',
                    },
                    PageBanner: {
                        Expand: 'Expand',
                    },
                    PersonDuplicateReportStore: {
                        DefaultDataGridMessage: 'Please use the search above to render some results.',
                        NoResultsMessage: 'No records were returned. Please try again.',
                    },
                    PersonDuplicateReportSearchOptionStore: {
                        Actions: 'Actions',
                        Id: 'ID',
                        LastName: 'Last Name',
                        Name: 'Name',
                        Modified: 'Modified',
                        Church: 'Church',
                        Contact: 'Contact',
                        DOB: 'DOB',
                    },
                    PersonSearchStore: {
                        DefaultDataGridMessage: 'Please use the search above to render some results.',
                        NoResultsMessage: 'No records were returned. Please try again.',
                    },
                    PersonSearchOptionStore: {
                        Id: 'ID',
                        LastName: 'Last Name',
                        FirstName: 'First Name',
                        Church: 'Church',
                        Contact: 'Contact',
                        DOB: 'DOB',
                    },
                    ResponseCardSearchStore: {
                        Title: 'Title',
                        Version: 'Version',
                        LastUpdated: 'Last Updated',
                        DefaultDataGridMessage: 'Browse Response Cards',
                        NoResultsMessage: 'No Response Cards found',
                    },
                    ResponseCardSearchOptionStore: {
                        Campus: 'Campus',
                        LastUpdated: 'Last Updated',
                        Published: 'Published',
                        Title: 'Title',
                        Version: 'Version',
                    },
                    ResponseCardManagerStore: {
                        ForbiddenForEdit: 'You are not authorized to edit this Response Card',
                        ForbiddenForUpdate: 'You are not authorized to update this Response Card',
                        ForbiddenForCreate: 'You are not authorized to create this Response Card',
                    },
                    ResponseCardViewStore: {
                        Forbidden: 'You are not authorized to view this Response Card',
                        Version: 'Version',
                    },
                    ResponseCardFieldMetaDataStore: {
                        // field types - labels for selector widget
                        text: 'Single Line Text',
                        textarea: 'Paragraph Text',
                        date: 'Date',
                        name: '(Full) Name Template Fields',
                        name_abbreviated: '(Short) Name Template Fields', // eslint-disable-line
                        address: 'Address Template Fields',
                        phone: 'Phone Template Fields',
                        email: 'Email Template Field',
                        number: 'Number',
                        checkboxes: 'Checkbox(es)',
                        radio: 'Multiple Choice',
                        select: 'Dropdown Menu',
                        gender: 'Gender Selector Template',
                        home_church_entity: 'Home Church Checkbox Template', // eslint-disable-line
                        next_steps: 'Next Steps Checkboxes Template', // eslint-disable-line
                        already_beginning_considering_dontever: '"A-B-C-D" Radio Buttons Template', // eslint-disable-line
                        class_signup: 'DYP Signup Template', // eslint-disable-line
                        subscriptions: 'Subscriptions Template',

                        // field types - labels for editable field on response card manager
                        editorText: 'Single Line Text',
                        editorTextarea: 'Paragraph Text',
                        editorDate: 'Date',
                        editorName: '(Full) Name Template',
                        editorName_abbreviated: '(Short) Name Template',
                        editorAddress: 'Address Template',
                        editorPhone: 'Phone Template',
                        editorEmail: 'Email Template',
                        editorNumber: 'Number',
                        editorCheckboxes: 'Checkbox(es)',
                        editorRadio: 'Multiple Choice',
                        editorSelect: 'Dropdown Menu',
                        editorGender: 'Gender Selector Template',
                        editorHome_church_entity: 'Home Church Checkbox Template', // eslint-disable-line
                        editorNext_steps: 'Next Steps Checkboxes Template', // eslint-disable-line
                        editorAlready_beginning_considering_dontever: '"A-B-C-D" Radio Buttons Template', // eslint-disable-line
                        editorClass_signup: 'DYP Signup Template', // eslint-disable-line
                        editorSubscriptions: 'Subscriptions Template',

                        // sub-field and choice labels for Name, Address and Phone Number built-in automatic multi-part fields
                        //    Name
                        Title: 'Title',
                        FirstName: 'First Name',
                        LastName: 'Last Name',
                        MiddleName: 'Middle Name',
                        Suffix: 'Suffix',
                        //    Address
                        Address1: 'Address 1',
                        Address2: 'Address 2',
                        City: 'City',
                        Region: 'State/Province',
                        PostalCode: 'Postal Code',
                        Country: 'Country',
                        //    Phone
                        CellPhone: 'Mobile Phone',
                        HomePhone: 'Home Phone',
                        WorkPhone: 'Work Phone',
                        //    Email
                        Email: 'Email',
                        //    Gender
                        Gender: 'Gender',
                        Female: 'Female',
                        Male: 'Male',
                        //    Home Campus
                        MakeMyHomeCampus: 'Make {0} my home campus', // allowing for some poor man's interpolation...
                        //    Next Step Checkboxes
                        AcceptedChrist: 'I made the decision to follow Christ today.',
                        LearnMoreAboutChrist: 'I want to learn more about accepting Jesus Christ as my Savior.',
                        FirstTime: 'This is my first time at Saddleback.',
                        ReadyForBaptism: 'I want to be baptized.',
                        ReadyForMembership: 'I am ready to become a member and join the Saddleback family.',
                        JoinSmallGroup: 'I am interested in belonging to a small group.',
                        //    "A-B-C-D" Radio Buttons
                        AlreadyTrustJesus: 'a. I ALREADY put my trust in Jesus',
                        BeginningToTrustJesus: 'b. I\'m BEGINNING to trust Jesus',
                        ConsideringJesus: 'c. I\'m CONSIDERING opening my life to Jesus',
                        DontEver: 'd. I DON\'T ever intend to accept what Jesus did for me',
                        //    CLASS Signup
                        Class101: 'DYP 1',
                        Class201: 'DYP 2',
                        Class301: 'DYP 3',
                        Class401: 'DYP 4',
                        //    Subscriptions
                        ENews: 'eNews',
                        DailyHope: 'Daily Hope',
                        DrivetimeDevotions: 'Drive Time Devotions',
                        MessageActionPlan: 'Message Action Plan',

                        // process fields and section with empty (`null`) labels that is allowed by design
                        null: '',
                    },
                    ResponseCardEntrySearchStore: {
                        DefaultDataGridMessage: 'Browse Response Card Entries',
                        NoResultsMessage: 'No Response Card Entries Found',
                    },
                    ResponseCardEntryByCardSearchOptionsStore: {
                        Id: 'Id',
                        Name: 'Name',
                        CreateDate: 'Created',
                        ModifyDate: 'Updated',
                        CreatedOrUpdated: 'Created/Updated',
                        WorshipService: 'Worship Service',
                    },
                    ResponseCardEntryByPersonSearchOptionsStore: {
                        Id: 'Id',
                        ResponseCardName: 'Response Card',
                        CreateDate: 'Created',
                        ModifyDate: 'Updated',
                        Church: 'Church',
                        WorshipService: 'Worship Service',
                        CreatedOrUpdated: 'Created/Updated',
                    },
                    ResponseCardEntryManagerStore: {
                        ForbiddenForResponseCard: 'You are not authorized to enter data on this response card',
                        ForbiddenForResponseCardEntryRead: 'You are not authorized to view this response card entry',
                        ForbiddenForResponseCardEntryCreate: 'You are not authorized to enter data on this response card',
                        ForbiddenForResponseCardEntryUpdate: 'You are not authorized to enter data on this response card',
                    },
                    ResponseCardEntryViewStore: {
                        Forbidden: 'You are not authorized to view this response card entry',
                    },
                    SecurityRoleManagerStore: {
                        Role: 'Role',
                        WasUpdated: 'was updated successfully',
                        WasCreated: 'was created successfully',
                    },
                    TimelineDropdown: {
                        AllTime: 'All Time',
                        LastWeek: 'Last Week',
                        LastMonth: 'Last Month',
                        Cancel: 'Cancel',
                        Custom: 'Custom',
                        CustomPeriod: 'Custom Period',
                        From: 'From',
                        OK: 'OK',
                        To: 'To',
                    },
                    ToolTipNote: {
                        SaidOn: ' said on ',
                    },
                    UserManagerStore: {
                        User: 'User',
                        WasUpdated: 'was updated successfully',
                        WasCreated: 'was created successfully',
                        TheUser: 'The user',
                        TheUserWithEmail: 'The user with email',
                        AlreadyInSystem: 'is already in the system',
                        ResetEmailSent: 'A reset password email has been sent.',
                        NotAuthorizedForCreate: 'You are not authorized to create this user with these security settings',
                        NotAuthorizedForUpdate: 'You are not authorized to make changes to the user',
                        ContactSysAdmin: '.  Contact your system administrator if you believe you have received this message in error.',
                    },
                    PersonSearchFieldEngine: {
                        FullName: 'Full Name',
                        Address: 'Address',
                        DOB: 'DOB',
                        Email: 'Email',
                        Id: 'Id',
                        Phone: 'Phone',
                    },
                    UserSearchFieldEngine: {
                        FullName: 'Full Name',
                        Email: 'Email',
                        Id: 'Id',
                    },
                    Notification: {
                        BannerNotificationStore: {
                            NotificationTitle: 'You have a new notification',
                            SummaryTitle: '{0} messages received while offline',
                        },
                        BannerNotificationAPIUtils: {
                            ConnectError: 'There was a problem while trying to connect to notification server, attempt {0}',
                            Disconnected: 'Client was disconnected from notification server',
                        },
                    },
                    AdminImport: {
                        AdminImport: {
                            SubTitle: 'Admin',
                            Title: 'Import',
                            NewImport: 'New Import',
                            Refresh: 'Refresh',
                            ImportAudit: 'Import Audit',
                            ImportDetails: 'Import Details',
                            Step1: 'Upload',
                            Step2: 'File Preview',
                            Step3: 'Import Preview',
                            Step4: 'Finished',
                        },
                        NewImportModal: {
                            Title: 'Start New Import',
                            SelectDataSource: 'Select External Data Source',
                            SelectFile: 'Select File to Import',
                            Select: 'Select',
                        },
                        ConfirmImportModal: {
                            Title: 'Confirm Import',
                            Msg: '{0} total records ({1} new, {2} updated and {3} skipped)',
                            CancelButton: 'Cancel',
                            ContinueButton: 'Continue',
                        },
                        LeaveImportModal: {
                            Title: 'Are You Sure',
                            Msg: 'Are you sure you want to leave a New Import? Your changes will not be saved.',
                            CancelButton: 'Cancel',
                            LeaveButton: 'Leave',
                        },
                        History: {
                            TabHistory: 'Import History',
                            TabCurrent: 'Import Current',
                            StartedOn: 'Started',
                            CompletedOn: 'Completed',
                            StartedBy: 'User',
                            Imported: 'Imported',
                            Status: 'Status',
                            NoHistory: 'No Import History',
                            NoCurrent: 'No Imports In Progress',
                            StartTime: 'Started',
                            TimeElapsed: 'Time Elapsed',
                            RecordsProcessed: 'Records Processed',
                            RecordsImported: 'Imported',
                            Status: 'Status',
                        },
                        FilePreview: {
                            Title: 'Field Matching',
                            Notes: '*This is a Default Template. Different templates for varying data is not yet available.',
                            ResetButton: 'Default Template',
                            Col1: 'External Source Name',
                            Col2: 'Church Management Field',
                            StartImport: 'Next',
                            CancelButton: 'Cancel',
                            SwitcherLabel: 'Preview of Record ',
                            FieldsLabel: 'Fields: ',
                            FileSizeLabel: 'File Size: ',
                            NoData: 'No Data',
                        },
                        ValidationFailed: {
                            Title: 'The file you selected could not be imported.',
                            CancelButton: 'Cancel',
                            TryAgainButton: 'Try Again',
                        },
                        ValidationProgress: {
                            Title: 'Validating File...',
                        },
                        ProcessFile: {
                            Title: 'Importing...',
                            CancelButton: 'Cancel',
                            RefreshButton: 'Refresh',
                        },
                        ParsingResults: {
                            TabNew: 'New',
                            TabChanges: 'Changes',
                            TabAddMilestones: 'Add Milestones',
                            TabUnchanged: 'Unchanged',
                            TabError: 'Error',
                            TabDuplications: 'Possible Duplications',
                            CancelButton: 'Cancel',
                            NextButton: 'Next',
                            NoDataNew: 'No New Person Information has been detected.',
                            NoDataAddMilestones: 'No Add Milestones Information has been detected.',
                            NoDataChanges: 'No Changes have been detected.',
                            NoDataUnchanged: 'No Unchanged Information has been detected.',
                            NoDataError: 'No Person Errors have been detected.',
                            HelpTabNew: 'Select which new person records to import into the church management system. Unchecked records will not be imported and their households will not be created.',
                            HelpTabChanges: '',
                            HelpTabAddMilestones: 'Select the person records below to add the Milestones to their Person Record. The Milestone information is displayed below the person record. Unchecked records will be ignored.',
                            HelpTabUnchanged: '',
                            HelpTabError: '',
                        },
                        ImportComplete: {
                            Title: 'Import Complete!',
                            AdminHome: 'Admin Home',
                            NewImport: 'New Import',
                            Duplications: 'Duplicates',
                        },
                        ProcessFinalization: {
                            Title: 'Importing...',
                            CancelButton: 'Cancel',
                            RefreshButton: 'Refresh',
                        },
                        ImportFileInput: {
                            Browse: 'Browse',
                        },
                        AdminFieldMappingErrorModal: {
                            Title: 'Field Matching Error',
                            CloseButton: 'Close',
                        },
                        CompareDuplications: {
                            Title1: 'CSV Record',
                            Title2: 'Church Management Record',
                            NoNotes: 'No Notes',
                            NA: 'N/A',
                        },
                        CompareMilestones: {
                            Title: 'Milestone',
                            Date: 'Date Completed',
                        },
                    },
                    AdminExport: {
                        AdminExport: {
                            People: 'People',
                            SubTitle: 'Insights',
                            Title: 'Person Data Extracts',
                            NewExtract: 'New Extract',
                        },
                        ExportHistory: {
                            Col1: 'User',
                            Col2: 'Completed',
                            Col3: 'Exported',
                            NoHistory: 'No History',
                            Title: 'Export History',
                        },
                    },
                    AdminOccurrenceTool: {
                        Title: 'Occurrence Tool',
                        NA: 'N/A',
                        DateRange: 'Date Range',
                        Campus: 'Campus',
                        SelectCampus: 'Select Campus',
                        Status: 'Status',
                        SelectStatus: 'Select Status',
                        Search: 'Search',
                        ID: 'ID',
                        ScheduleID: 'Schedule ID',
                        Date: 'Date',
                        NewDate: 'New Date',
                        Duration: 'Duration',
                        TimeZone: 'Time Zone',
                        ScheduleEntityType: 'Schedule Entity Type',
                        SelectScheduleEntityType: 'Select Schedule Entity Type',
                    },
                    AdminQrTool: {
                        Title: 'QR Code Tool',
                        GetQRCodePNG: 'Get QR Code (PNG)',
                        GetQRCodeSVG: 'Get QR Code (SVG)',
                        UniqueIdPlaceholder: 'Person Unique ID',
                        Person: 'Person',
                        Attendee: 'Attendee',
                        Id: 'ID',
                        EventId: 'Event ID',
                        OccurrenceId: 'Occurrence ID',
                        SubVenueId: 'Sub-Venue ID',
                        PersonId: 'Person ID',
                        Error: 'Error',
                        AttendeeIdPlaceholder: 'Attendee ID',
                    },
                    AdminWorshipService: {
                        Overview: 'Overview',
                        Settings: 'Settings & Preferences',
                        Title: 'Worship Service Setup',
                        NewService: 'New Service',
                        SuccessfullyFlushed: 'Event Cache has been Successfully Flushed',
                        TabRecurring: 'Recurring',
                        TabOneTime: 'One-Time',
                        TabClosed: 'Closed',
                        FindService: 'Find Service',
                        Filters: 'Filters',
                        Campus: 'Campus',
                        Venue: 'Venue',
                        Where: 'Where',
                        Date: 'Date',
                        Times: 'Times',
                        NextOccurrence: 'Next Occurrence',
                        EndedOn: 'Ended On',
                        When: 'When',
                        LoadMore: 'Load More',
                        ApplyFilters: 'Apply Filters',
                        Location: 'Location',
                        AreYouSure: 'Are you sure?',
                        DeleteService: 'Close this Service',
                        DeleteEvent: 'Delete this Occurrence',
                        AddServiceCategory: 'Add a Service Category',
                        Actions: 'Actions',
                        ServiceSuccessfullyDeleted: 'Worship Service Successfully Closed',
                        EventSuccessfullyDeleted: 'Event Occurrence Successfully Deleted',
                        WorshipServiceDrawer: {
                            Title: 'Create Service',
                            Cancel: 'Cancel',
                            Save: 'Save',
                            AreYouSure: 'Are you sure?',
                            What: 'What',
                            Name: 'Name',
                            Custom: 'Custom',
                            Where: 'Where',
                            When: 'When',
                            OneTime: 'One Time',
                            Recurring: 'Recurring',
                            SuccessfullyAdded: 'Successfully Added Service',
                            SuccessfullyUpdated: 'Successfully Updated Service',
                            AddVenue: 'Add Venue',
                            CloseService: 'Close Service',
                            Actions: 'Actions',
                            Remove: 'Remove',
                            StartEvent1: 'This event starts on <b>{0}</b> from <b>{1}</b> and continues until <b>{2} {3}</b>',
                            StartEvent3: 'and will take place {0}',
                            StartTime: 'Start Time',
                            StartDate: 'Start Date',
                            EndTime: 'End Time',
                            EndDate: 'End Date',
                            Timezone: 'Time Zone',
                            SelectTimezone: 'Select Time Zone',
                            RepeatingEvent: 'Repeating Event?',
                            CustomRepeat: 'Custom Repeat',
                            ServiceCategory: 'Service Category',
                            AddCategory: 'Add a Service Category',
                            Date: 'Date',
                            IncorrectRecurringRule: 'Fewer than two occurrences will happen before {0}',
                        },
                        VenueDrawer: {
                            Title: 'Create Venue',
                            Cancel: 'Cancel',
                            Save: 'Save',
                            Custom: 'Custom',
                            Where: 'Where',
                            Metrics: 'Metrics',
                            AddMetric: 'Add Metric',
                            Category: 'Category',
                            SelectCategory: 'Select Category',
                            Remove: 'Remove',
                        },
                        SelectWorshipServiceCategory: {
                            SelectCategory: 'Select Category',
                            Select: 'Select...',
                            NoCategory: 'No category',
                        },
                        WorshipServiceCategoryDrawer: {
                            Title: 'Worship Service Category',
                            Save: 'Save',
                            Cancel: 'Cancel',
                            ServiceCategory: 'Service Category',
                            SelectDate: 'Select a date',
                            SuccessfullyUpdated: 'Worship service category was successfully updated',
                        },
                        Categories: 'Categories',
                        AddCategory: 'Add Category',
                        PleaseEnterValue: 'Please enter a value.',
                        AlreadyExists: 'already exists',
                        CategorySample: 'e.g. Celebrate Recovery',
                    },
                },
            },
        ];

        this.updateMessagesWithEnglishFallback('en-us');
    }

    updateLCID(lcid) {
        this._lcid = lcid;
        moment.locale(lcid); // Set the locale globally for the app.
    }

    addLanguage(language) {
        this._languages.push(language);
    }

    getDefaultLanguage() {
        return this._languages[0];
    }

    // I want this in i18nUtils, but for some strange reason I can't call it from the
    // registered callback with AppDispatcher.
    downloadLanguageFromS3(lcid) {
        if (lcid === 'en-us') {
            return;
        }

        // eslint-disable-next-line no-undef
        const bucketUrl = __S3_LANGUAGE_BUCKET__; // NOSONAR

        request
            .get(`${bucketUrl + lcid}.json`)
            .then((res) => {
                i18nActionCreators.loadLanguageCompleted(res.data, lcid);
            })
            .catch((err) => {
                throw new Error(`Could not load language file for ${lcid}!`);
            });
    }

    updateMessagesWithEnglishFallback(lcid) {
        if (typeof lcid === 'undefined') {
            return;
        }

        const currentLanguage = _.chain(this._languages)
            .filter((language) => language.lcid === lcid)
            .head()
            .value();

        if (typeof currentLanguage === 'undefined') {
            return;
        }

        _.merge(this._messages, this._languages[0].messages, currentLanguage.messages);
    }

    getMessages() {
        return this._messages;
    }

    getLCID() {
        return this._lcid;
    }

    emitChange() {
        this.emit(CHANGE_EVENT);
    }

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    }

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
}

export const dispatcherCallback = (payload) => {
    let lcid = null;

    switch (payload.actionType) {
        case BootstrapConstants.GET_BOOTSTRAP:
            if (!payload.data.userPreference) {
                break;
            }

            lcid = payload.data.userPreference.localeLCID;
            i18nMessagesStore.updateLCID(lcid);
            i18nMessagesStore.emitChange();
            break;

        case UserPreferenceConstants.USER_PREFERENCE_INSERT_COMPLETED:

            lcid = payload.preference.localeLCID;
            i18nMessagesStore.updateMessagesWithEnglishFallback(lcid);
            i18nMessagesStore.updateLCID(lcid);
            i18nMessagesStore.emitChange();
            break;

        case i18nConstants.LOAD_LANGUAGE:
            i18nMessagesStore.addLanguage(payload.language);
            i18nMessagesStore.updateMessagesWithEnglishFallback(payload.lcid);
            i18nMessagesStore.updateLCID(payload.lcid);
            i18nMessagesStore.emitChange();
            break;

        default:
        // no op
    }
};

AppDispatcher.register(dispatcherCallback);

let i18nMessagesStore = new i18nMessagesStoreClass(); // eslint-disable-line
export default i18nMessagesStore;
