/* eslint-disable no-undef */
export const isCmDemoEnv = typeof isCmDemo === 'function' && isCmDemo(); // NOSONAR
export const isCmDevelopmentEnv = typeof isCmDevelopment === 'function' && isCmDevelopment(); // NOSONAR
export const isCmFeatureEnv = typeof isCmFeature === 'function' && isCmFeature(); // NOSONAR
export const isCmLocalEnv = typeof isCmLocal === 'function' && isCmLocal(); // NOSONAR
export const isCmLocalUiEnv = typeof isCmLocalUi === 'function' && isCmLocalUi(); // NOSONAR
export const isCmProductionEnv = typeof isCmProduction === 'function' && isCmProduction(); // NOSONAR
export const isCmQaEnv = typeof isCmQa === 'function' && isCmQa(); // NOSONAR
export const isCmStagingEnv = typeof isCmStaging === 'function' && isCmStaging(); // NOSONAR
export const isCmStaging2Env = typeof isCmStaging2 === 'function' && isCmStaging2(); // NOSONAR
export const isInMobileAppMode = typeof isMobileAppMode === 'function' && isMobileAppMode(); // NOSONAR
/* eslint-enable no-undef */
