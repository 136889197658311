import AppDispatcher from '../dispatcher/AppDispatcher';
import i18nConstants from '../constants/i18nConstants';

export default class i18nActionCreators {
    static loadLanguageCompleted(language, lcid) {
        AppDispatcher.dispatch({
            actionType: i18nConstants.LOAD_LANGUAGE,
            language,
            lcid,
        });
    }
}
