export const CONTEXTUAL_HELP_OPENED = 'Contextual Help Opened';
export const CONTEXTUAL_HELP_CLOSED = 'Contextual Help Closed';
export const CONTEXTUAL_HELP_ARTICLE_OPENED = 'Contextual Help Article Opened';
export const CONTEXTUAL_HELP_ARTICLE_CLOSED = 'Contextual Help Article Closed';
export const CONTEXTUAL_HELP_ARTICLE_NEW_TAB = 'Contextual Help Article New Tab';

const eventsToTrack = {
    // a sample redux event
    'UserActions.ON_FAILED_UPDATE': {
        category: 'UserActions',
        action: 'get',
        storeDataPath: 'security.users.user.id',
    },
    // a sample flux event
    'SET_SEARCH_OPTIONS': {
        category: 'PersonSearch',
        action: 'get',
        storeDataPath: null,
    },
    [CONTEXTUAL_HELP_OPENED]: {
        category: 'Help',
        action: 'mouse_event',
        storeDataPath: 'Action',
    },
    [CONTEXTUAL_HELP_CLOSED]: {
        category: 'Help',
        action: 'mouse_event',
        storeDataPath: 'Action',
    },
    [CONTEXTUAL_HELP_ARTICLE_OPENED]: {
        category: 'Help',
        action: 'mouse_event',
        storeDataPath: 'Action',
    },
    [CONTEXTUAL_HELP_ARTICLE_CLOSED]: {
        category: 'Help',
        action: 'mouse_event',
        storeDataPath: 'Action',
    },
    [CONTEXTUAL_HELP_ARTICLE_NEW_TAB]: {
        category: 'Help',
        action: 'mouse_event',
        storeDataPath: 'Action',
    },
};

export default eventsToTrack;
