import request, {
    AxiosError,
    AxiosResponse,
} from 'axios';
import {
    isEmpty,
    isNil,
} from 'lodash';
import {
    apiHostsDirectory,
} from '../apiHostsDirectory';
import {
    getEnvironment,
} from './apiEnvironment';
import {
    ServiceType,
} from '../models';
import ServiceRequestParameters from './serviceRequestParameters.model';

const DEFAULT_HTTP_HEADERS = {
    'Content-Type': 'application/json',
};

const resolvedApiHostsCache: Map<ServiceType, string> = new Map<ServiceType, string>();

export const getApiHost = (service: ServiceType): string => {
    if (resolvedApiHostsCache.has(service)) {
        return resolvedApiHostsCache[service];
    }

    const environment = getEnvironment();
    const serviceHostUrl = apiHostsDirectory[environment][service];

    if (!isEmpty(serviceHostUrl)) {
        resolvedApiHostsCache[service] = serviceHostUrl;
    }

    return serviceHostUrl;
};

export const serviceRequest = async ({
    url,
    method = 'GET',
    data = null,
    headers = {},
    accessToken = null,
    expectedErrorTypes = [],
}: ServiceRequestParameters): Promise<AxiosResponse<any, any>> => {
    try {
        const finalHeaders: any = {
            ...DEFAULT_HTTP_HEADERS,
            ...headers,
        };

        if (!isEmpty(accessToken)) {
            finalHeaders.Authorization = `Bearer ${accessToken}`;
        }

        const response = await request({
            url,
            method,
            headers: finalHeaders,
            data,
        });

        return response;
    } catch (error) {
        const axiosError = error as AxiosError;

        if (!isNil(axiosError) &&
            !isNil(axiosError.response?.status) &&
            expectedErrorTypes.some((e) => e === axiosError.response.status)) {
            return Promise.resolve({
                ...axiosError.response,
                data: null, // TODO: Sometimes we might have data we need to pass back, e.g. data that describes conflicts or validation problems
            });
        }

        console.error(`Healthy Church API: Request failed - [${method} ${url}] -`, error.message);
        throw error;
    }
};
