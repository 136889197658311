import {
    map,
    isNull,
    sortBy,
} from 'lodash';
import {
    Campus,
    CampusSelectOption,
} from '../models';

const transformCampusesToSelectOptions = (campuses: Campus[]): CampusSelectOption[] => (
    map(
        sortBy(
            campuses,
            // sort root node ('Central') first, then alpha by name
            (c) => (isNull(c.parentEntityId) ? 0 : c.name),
        ),
        (c:Campus): CampusSelectOption => ({
            /* eslint-disable sort-keys */
            label: c.name,
            value: c.id,
            region: c.region,
            country: c.country,
            timeZone: c.timeZone,
            disabled: !c.isActive,
            gradeSchoolYear: c.gradeSchoolYear,
            /* eslint-enable sort-keys */
        }),
    ));

export default transformCampusesToSelectOptions;
