import { combineReducers } from 'redux';

import campusList from './campusList.reducer';
import connectionForm from './connectionForm.reducer';
import contactForm from './contactForm.reducer';
import countriesList from './countriesList.reducer';
import eventOccurrence from './eventOccurrence.reducer';
import personDetails from './personDetails.reducer';
import personalForm from './personalForm.reducer';
import regionByCountry from './regionByCountry.reducer';
import saveConnectionForm from './saveConnectionForm.reducer';
import worshipService from './worshipService.reducer';

const reducers = combineReducers({
    campusList,
    connectionForm,
    contactForm,
    countriesList,
    eventOccurrence,
    personDetails,
    personalForm,
    regionByCountry,
    saveConnectionForm,
    worshipService,
});

export default reducers;
