import { isNil } from 'lodash';
import ActionType from './connectionForm.actionTypes';
import PersonalDetails from './models/personDetails.model';

export type PersonDetailsState = {
    person: PersonalDetails | {};
    isFetchingPersonDetails: boolean;
};

export const DEFAULT_STATE: PersonDetailsState = {
    person: {},
    isFetchingPersonDetails: false,
};

interface RequestPersonDetailsAction {
    type: ActionType.REQUEST_PERSON_DETAILS;
}

interface ReceivePersonDetailsAction {
    type: ActionType.RECEIVE_PERSON_DETAILS;
    result: PersonalDetails;
}

type KnownActionType = RequestPersonDetailsAction | ReceivePersonDetailsAction;

export default (
    state: PersonDetailsState | undefined,
    incomingAction: any,
): PersonDetailsState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_PERSON_DETAILS:
                return {
                    ...state,
                    isFetchingPersonDetails: true,
                };

            case ActionType.RECEIVE_PERSON_DETAILS:
            {
                const specificAction = action as ReceivePersonDetailsAction;

                return {
                    ...state,
                    person: specificAction.result,
                    isFetchingPersonDetails: false,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
