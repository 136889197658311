import { PersonalFormState } from './personalForm.reducer';
import ActionType from './personalForm.actionTypes';
import Gender from './models/gender.model';

export const updateCampus = (campus: number) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_CAMPUS,
        value: campus,
    });
};

export const updateFirstName = (firstName: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_FIRSTNAME,
        value: firstName,
    });
};

export const updateGender = (gender: Gender | null) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_GENDER,
        value: gender,
    });
};

export const updateLastName = (lastName: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_LASTNAME,
        value: lastName,
    });
};

export const updatePersonalData = (personalData: PersonalFormState) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_PERSONAL_DATA,
        value: personalData,
    });
};

export const resetData = () => (dispatch: Function) => {
    dispatch({
        type: ActionType.RESET_DATA,
    });
};
