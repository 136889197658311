/**
 * Months 0-11 January-December so they can be used
 * for MomentJS calculations while also being pretty
 * for typing
 */
enum Month {
    January = 0,
    February = 1,
    March = 2,
    April = 3,
    May = 4,
    June = 5,
    July = 6,
    August = 7,
    September = 8,
    October = 9,
    November = 10,
    December = 11,
}

export default Month;
