import { isNil } from 'lodash';
import ActionType from './connectionForm.actionTypes';
import ConnectionFormTemplate from './models/connectionFormTemplate.model';

export type ConnectionFormPublicState = {
    formTemplate: ConnectionFormTemplate | null;
    hasFormTemplateError: boolean;
    isFetchingFormTemplate: boolean;
    status: number | null;
};

export const DEFAULT_STATE: ConnectionFormPublicState = {
    formTemplate: null,
    hasFormTemplateError: false,
    isFetchingFormTemplate: false,
    status: null,
};

interface RequestConnectionFormTemplateAction {
    type: ActionType.REQUEST_CONNECTION_FORM_TEMPLATE;
}

interface ReceiveConnectionFormTemplateAction {
    type: ActionType.RECEIVE_CONNECTION_FORM_TEMPLATE;
    formTemplate: ConnectionFormTemplate;
}

interface ReceiveConnectionFormTemplateErrorAction {
    type: ActionType.RECEIVE_CONNECTION_FORM_TEMPLATE_ERROR;
    status: number;
}

type KnownActionType =
    RequestConnectionFormTemplateAction |
    ReceiveConnectionFormTemplateAction |
    ReceiveConnectionFormTemplateErrorAction;

export default (
    state: ConnectionFormPublicState | undefined,
    incomingAction: any,
): ConnectionFormPublicState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_CONNECTION_FORM_TEMPLATE:
                return {
                    ...state,
                    isFetchingFormTemplate: true,
                };

            case ActionType.RECEIVE_CONNECTION_FORM_TEMPLATE:
            {
                const specificAction = action as ReceiveConnectionFormTemplateAction;

                return {
                    ...state,
                    formTemplate: specificAction.formTemplate,
                    hasFormTemplateError: false,
                    isFetchingFormTemplate: false,
                };
            }

            case ActionType.RECEIVE_CONNECTION_FORM_TEMPLATE_ERROR:
                return {
                    ...state,
                    formTemplate: null,
                    hasFormTemplateError: true,
                    isFetchingFormTemplate: false,
                    status: action.status,
                };

            default:
                return state;
        }
    }

    return state;
};
