import { CampusSelectOption } from '../../global/models';

export const BEM_BLOCK_NAME = 'connection_form_public';

export const DOES_NOT_ATTEND_CHURCH_ENTITY = -20;
export const UNKNOWN_CHURCH_ENTITY = -10;

export const DOES_NOT_ATTEND_OPTION: CampusSelectOption = {
    country: 'N/A',
    disabled: false,
    label: 'Does Not Attend',
    region: 'N/A',
    timeZone: null,
    value: DOES_NOT_ATTEND_CHURCH_ENTITY,
};

export const UNKNOWN_OPTION: CampusSelectOption = {
    country: 'N/A',
    disabled: false,
    label: 'Unknown',
    region: 'N/A',
    timeZone: null,
    value: UNKNOWN_CHURCH_ENTITY,
};

// IMPORTANT NOTE: `title` and `subtitle` properties of the message objects below
// must be i18n translation file keys!
// See /client/src/connectionForms/public/translations

export const formEntrySaveErrorMessages = [{
    code: 200,
    iconColor: 'success',
    iconType: 'check',
    subTitle: 'common.messages.successfullySubmittedFormEntry',
    title: 'common.terms.success',
}, {
    code: 400,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.genericErrorMessage',
    title: 'common.messages.errorWithStatusCode',
}, {
    code: 403,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.accessDenied',
    title: 'common.messages.errorWithStatusCode',
}, {
    code: 404,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.genericErrorMessage',
    title: 'common.messages.errorWithStatusCode',
}, {
    code: 500,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.genericErrorMessage',
    title: 'common.messages.errorWithStatusCode',
}];

export const formTemplateFetchErrorMessages = [{
    code: 400,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.genericErrorMessage',
    title: 'common.messages.errorWithStatusCode',
}, {
    code: 403,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.formClosed.body',
    title: 'common.messages.formClosed.title',
}, {
    code: 404,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.notFound',
    title: 'common.messages.errorWithStatusCode',
}, {
    code: 500,
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'common.messages.genericErrorMessage',
    title: 'common.messages.errorWithStatusCode',
}];
