import React from 'react';
import {
    Grid,
    Input,
    Select,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import ClassNames from 'classnames';
import {
    includes,
    map,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Region } from '../../global/models';
import { PhoneTypeProps } from '../../global/models/phoneType.model';
import Utils from '../../global/utils/utils.js';
import { PersonalInformationPanelField } from '../models';
import {
    resetData,
    setAddress1,
    setAddress2,
    setCity,
    setCountry,
    setEmail,
    setPhone,
    setPhoneExtension,
    setPhoneType,
    setPostalCode,
    setRegion,
} from './contactForm.actions';
import { ContactFormState } from './contactForm.reducer';
import ContactPhoneNumbers from './contactPhoneNumbers';
import Country from './models/country.model';

interface PropTypes {
    /**
     * Contact Form Section data from Redux
    */
    contactForm: ContactFormState;
    /**
     * Determines countries list
     */
    countriesListData: Country[];
    /**
     * Boolean flag indicating whether or not the Form uses the 'lite' Personal Panel type.
     * The Lite Personal Panel's Contact Form should not show the inputs for the Mailing Address.
     */
    isLite?: boolean;
    /**
     * legacy i18n translation function
     */
    legacyI18N?: (key: string) => string;
    /**
     * Boolean flag indicating whether the component is used publicly or not.
     */
    shouldUseNewTranslations?: boolean;
    /**
     * RegionByCountry data from Redux
     */
    regionByCountryData: Region[];
    /**
     * List of required fields (if any) for the Form's Personal Panel
     */
    requiredFields: PersonalInformationPanelField[];
    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;
    /**
     * Update address 1 for contact form
     */
    setAddress1: Function;
    /**
     * Update address 2 for contact form
     */
    setAddress2: Function;
    /**
     * Update city for contact form
     */
    setCity: Function;
    /**
     * Update country for contact form
     */
    setCountry: Function;
    /**
     * Update email for contact form
     */
    setEmail: Function;
    /**
     * Update phone for contact form
     */
    setPhone: Function;
    /**
     * Update phone extension for contact form
     */
    setPhoneExtension: (phoneExtension: string) => void;
    /**
     * Update phone type for contact form
     */
    setPhoneType: (phoneType: PhoneTypeProps) => void;

    /**
     * Update postal code for contact form
     */
    setPostalCode: Function;
    /**
     * Update region for contact form
     */
    setRegion: Function;

    /**
     * Hide phone number field in contact form
     * FIXME: Temporary code to change the way the form renders for a campaign,
     * remove this and all related code when not longer needed
     * see: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87959
     * and: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87960
     */
    hidePhoneNumber?: boolean;
}

const defaultProps: Pick<PropTypes, 'isLite'> = {
    isLite: false,
};

const mapStateToProps = (state) => {
    const {
        connectionForms: {
            publicForm: {
                contactForm,
            },
        },
    } = state;

    return {
        contactForm,
    };
};

const mapDispatchToProps = {
    resetData,
    setAddress1,
    setAddress2,
    setCity,
    setCountry,
    setEmail,
    setPhone,
    setPhoneExtension,
    setPhoneType,
    setPostalCode,
    setRegion,
};

const useStyles = makeStyles(({
    palette,
    spacing,
}) => ({
    container: {
        marginBottom: spacing(2),
    },
    grid: {
        marginBottom: [-16.5, '!important'],
        marginTop: [-16.6, '!important'],
    },
    gridColumn: {
        paddingBottom: [16.5, '!important'],
        paddingTop: [16.6, '!important'],
    },
    labelColor: {
        '& label': {
            color: palette.grey[500],
        },
    },
    sectionTitle: {
        paddingBottom: [0, '!important'],
    },
}));

export const BEM_BLOCK_NAME = 'connection_form_public--contact_form';

function ContactForm(props: PropTypes) {
    const {
        contactForm,
        countriesListData,
        hidePhoneNumber = false,
        isLite,
        legacyI18N,
        shouldUseNewTranslations,
        regionByCountryData,
        requiredFields,
        setAddress1: setAddress1Action,
        setAddress2: setAddress2Action,
        setCity: setCityAction,
        setCountry: setCountryAction,
        setEmail: setEmailAction,
        setPhone: setPhoneAction,
        setPhoneExtension: setPhoneExtensionAction,
        setPhoneType: setPhoneTypeAction,
        setPostalCode: setPostalCodeAction,
        setRegion: setRegionAction,
        showValidationErrors,
    } = props;

    const {
        address: {
            address1: address1Value,
            address2: address2Value,
            city: cityValue,
            isAddressValid,
            isPostalCodeValid,
            postalCode: postalCodeValue,
            isPostalCodeRequired: isPostalCodeRequiredPerAddressValidator,
            countryAlpha3: countryAlpha3Value,
            regionCode: regionCodeValue,
        },
        email: {
            isEmailValid,
            value: emailValue,
        },
        phone,

    } = contactForm;

    const classes = useStyles();

    const { t } = useTranslation();

    const localizedRequiredText = shouldUseNewTranslations ? t('common.terms.required') : legacyI18N('Required');
    const localizedSelectText = shouldUseNewTranslations ? t('common.terms.select') : legacyI18N('Select');

    const sectionTitleClasses = ClassNames(classes.gridColumn, classes.sectionTitle);

    const countryOptions = map(countriesListData, (item) => ({
        label: item.longName,
        value: item.shortName,
        code: item.code,
    }));

    const regionByCountryOptions = map(regionByCountryData, (item) => ({
        label: item.longName,
        value: item.shortName,
    }));

    const isEmailRequired = includes(requiredFields, PersonalInformationPanelField.Email);
    const hasEmail = !Utils.isStringNullOrWhiteSpace(emailValue);
    const hasErrorOnEmail = hasEmail && !isEmailValid;
    let emailInputError = null;

    if (hasErrorOnEmail) {
        emailInputError = shouldUseNewTranslations ? t('contactForm.invalidEmailAddress') : legacyI18N('Invalid email address');
    } else if (showValidationErrors && isEmailRequired && !hasEmail) {
        emailInputError = localizedRequiredText;
    }

    const isPostalCodeRequiredPerFormSpec = includes(
        requiredFields,
        PersonalInformationPanelField.PostalCode,
    );

    const hasPostalCode = !Utils.isStringNullOrWhiteSpace(postalCodeValue);
    const hasErrorOnPostalCode = hasPostalCode && !isPostalCodeValid;

    const isAddress1Required = includes(requiredFields, PersonalInformationPanelField.Address1);
    const hasAddress1 = !Utils.isStringNullOrWhiteSpace(address1Value);

    const isAddress2Required = includes(requiredFields, PersonalInformationPanelField.Address2);
    const hasAddress2 = !Utils.isStringNullOrWhiteSpace(address2Value);

    const isCityRequired = includes(requiredFields, PersonalInformationPanelField.City);
    const hasCity = !Utils.isStringNullOrWhiteSpace(cityValue);

    const isCountryRequired = includes(requiredFields, PersonalInformationPanelField.Country);
    const hasCountry = !!countryAlpha3Value;
    const hasNonDefaultCountry = hasCountry && countryAlpha3Value !== 'USA'; // USA is a default value

    const isRegionRequired = includes(requiredFields, PersonalInformationPanelField.Region);
    const hasRegion = !!regionCodeValue;

    const hasAddressValues = hasCity ||
        hasPostalCode ||
        hasAddress1 ||
        hasNonDefaultCountry ||
        hasRegion;

    const hasErrorOnAddress = hasAddressValues && !isAddressValid;

    const isPostalCodeRequired = countryAlpha3Value === 'USA' &&
        (
            isPostalCodeRequiredPerFormSpec ||
            (hasAddressValues && isPostalCodeRequiredPerAddressValidator)
        );

    let address1InputError = null;

    if (showValidationErrors && isAddress1Required && !hasAddress1) {
        address1InputError = localizedRequiredText;
    } else {
        address1InputError = hasErrorOnAddress;
    }

    let cityInputError = null;

    if (showValidationErrors && isCityRequired && !hasCity) {
        cityInputError = localizedRequiredText;
    } else {
        cityInputError = hasErrorOnAddress;
    }

    let postalCodeInputError = null;

    if (showValidationErrors && isPostalCodeRequired && !hasPostalCode) {
        postalCodeInputError = localizedRequiredText;
    } else {
        postalCodeInputError = hasErrorOnPostalCode;
    }

    let countrySelectError = null;

    if (showValidationErrors && isCountryRequired && !hasCountry) {
        countrySelectError = localizedRequiredText;
    } else {
        countrySelectError = hasErrorOnAddress;
    }

    let regionSelectError = null;

    if (showValidationErrors && isRegionRequired && !hasRegion) {
        regionSelectError = localizedRequiredText;
    } else {
        regionSelectError = hasErrorOnAddress;
    }

    return (
        <div className={classes.container}>
            <Grid
                className={classes.grid}
                spacing={2}
            >
                <Grid.Column
                    className={sectionTitleClasses}
                    sm={12}
                >
                    <Typography
                        gutterBottom
                        variant="h3"
                    >
                        {shouldUseNewTranslations ? t('contactForm.contact') : legacyI18N('Contact')}
                    </Typography>
                </Grid.Column>

                <Grid.Column
                    className={classes.gridColumn}
                    sm={12}
                >
                    <Input
                        className={classes.labelColor}
                        dataTestId={`${BEM_BLOCK_NAME}_field_email`}
                        error={emailInputError}
                        fluid
                        id={`${BEM_BLOCK_NAME}_field_email`}
                        label={shouldUseNewTranslations ? t('contactForm.email') : legacyI18N('Email')}
                        onChange={setEmailAction}
                        required={isEmailRequired}
                        tabIndex={0}
                        type="text"
                        value={emailValue}
                    />
                </Grid.Column>

                <ContactPhoneNumbers
                    hidePhoneNumber={hidePhoneNumber}
                    legacyI18N={legacyI18N}
                    phone={phone}
                    requiredFields={requiredFields}
                    setPhoneAction={setPhoneAction}
                    setPhoneExtensionAction={setPhoneExtensionAction}
                    setPhoneTypeAction={setPhoneTypeAction}
                    shouldUseNewTranslations={shouldUseNewTranslations}
                    showValidationErrors={showValidationErrors}
                />

                {!isLite && (
                    <React.Fragment>
                        <Grid.Column
                            className={classes.gridColumn}
                            sm={12}
                        >
                            <Input
                                className={classes.labelColor}
                                dataTestId={`${BEM_BLOCK_NAME}_field_address1`}
                                error={address1InputError}
                                fluid
                                id={`${BEM_BLOCK_NAME}_field_address1`}
                                label={shouldUseNewTranslations ? t('contactForm.address1') : legacyI18N('Address 1')}
                                onChange={setAddress1Action}
                                required={isAddress1Required}
                                tabIndex={0}
                                type="text"
                                value={address1Value}
                            />
                        </Grid.Column>

                        <Grid.Column
                            className={classes.gridColumn}
                            sm={12}
                        >
                            <Input
                                className={classes.labelColor}
                                dataTestId={`${BEM_BLOCK_NAME}_field_address2`}
                                error={
                                    showValidationErrors && isAddress2Required && !hasAddress2 ?
                                        localizedRequiredText :
                                        undefined
                                }
                                fluid
                                id={`${BEM_BLOCK_NAME}_field_address2`}
                                label={shouldUseNewTranslations ? t('contactForm.address2') : legacyI18N('Address 2')}
                                onChange={setAddress2Action}
                                required={isAddress2Required}
                                tabIndex={0}
                                type="text"
                                value={address2Value}
                            />
                        </Grid.Column>

                        <Grid.Column
                            className={classes.gridColumn}
                            md={6}
                            sm={12}
                        >
                            <Input
                                className={classes.labelColor}
                                dataTestId={`${BEM_BLOCK_NAME}_field_city`}
                                error={cityInputError}
                                fluid
                                id={`${BEM_BLOCK_NAME}_field_city`}
                                label={shouldUseNewTranslations ? t('contactForm.city') : legacyI18N('City')}
                                onChange={setCityAction}
                                required={isCityRequired}
                                tabIndex={0}
                                type="text"
                                value={cityValue}
                            />
                        </Grid.Column>

                        <Grid.Column
                            className={classes.gridColumn}
                            md={6}
                            sm={12}
                        >
                            <Select
                                className={classes.labelColor}
                                clearable
                                data-testid={`${BEM_BLOCK_NAME}_field_country`}
                                error={countrySelectError}
                                fluid
                                id={`${BEM_BLOCK_NAME}_field_country`}
                                label={shouldUseNewTranslations ? t('contactForm.country') : legacyI18N('Country')}
                                onChange={setCountryAction}
                                options={countryOptions}
                                placeholder={localizedSelectText}
                                required={isCountryRequired || hasErrorOnAddress}
                                searchable
                                tabIndex={0}
                                value={countryAlpha3Value}
                            />
                        </Grid.Column>

                        <Grid.Column
                            className={classes.gridColumn}
                            md={6}
                            sm={12}
                        >
                            <Input
                                className={classes.labelColor}
                                dataTestId={`${BEM_BLOCK_NAME}_field_postal_code`}
                                error={postalCodeInputError}
                                fluid
                                id={`${BEM_BLOCK_NAME}_field_postal_code`}
                                label={shouldUseNewTranslations ? t('contactForm.postalCode') : legacyI18N('Postal Code')}
                                onChange={setPostalCodeAction}
                                required={isPostalCodeRequired}
                                tabIndex={0}
                                type="text"
                                value={postalCodeValue}
                            />
                        </Grid.Column>

                        <Grid.Column
                            className={classes.gridColumn}
                            md={6}
                            sm={12}
                        >
                            <Select
                                className={classes.labelColor}
                                clearable
                                data-testid={`${BEM_BLOCK_NAME}_field_region`}
                                error={regionSelectError}
                                fluid
                                id={`${BEM_BLOCK_NAME}_field_region`}
                                label={shouldUseNewTranslations ? t('contactForm.region') : legacyI18N('Region')}
                                onChange={setRegionAction}
                                options={regionByCountryOptions}
                                placeholder={localizedSelectText}
                                required={isRegionRequired || hasErrorOnAddress}
                                searchable
                                tabIndex={0}
                                value={regionCodeValue}
                            />
                        </Grid.Column>
                    </React.Fragment>
                )}
            </Grid>
        </div>
    );
}

ContactForm.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
