import ReactDOM from 'react-dom/client';
import { HealthyChurchEnvironment } from '../../src/global/api/models';
import { CONSUMER_DATA_PARAMS_PREFIX } from './constants.js';

export const createWidgetLoader = (htmlConsumer) => {
    const WidgetLoader = ReactDOM.createRoot(htmlConsumer);

    return WidgetLoader;
};

/**
 *
 * @param {String} str data param coming from the HTML consumer (snake case), e.g. some_param_for_the_widget
 * @returns {String} data param in camel case so the widget can work properly without changing any variable declaraion in it.
 */
export const snakeToCamelCase = (str: string): string => (
    str
        .toLowerCase()
        .replace(
            /[-_][a-z0-9]/g,
            (group) => group
                .slice(-1)
                .toUpperCase(),
        )
);

export const getHealthyChurchEnvironment =
    (): HealthyChurchEnvironment => (process.env.ENVIRONMENT_NAME as HealthyChurchEnvironment);

export const getWidgetConsumerParams = (htmlConsumer) => {
    const attributes = Object.getOwnPropertyNames(htmlConsumer?.attributes);
    const dataAttributeNames = attributes.filter(
        (attribute) => (attribute.startsWith(CONSUMER_DATA_PARAMS_PREFIX)),
    );

    return dataAttributeNames.reduce(
        (currentAttributesObject, attributeName) => {
            let attributeValue = htmlConsumer?.getAttribute(attributeName);

            // If the data attribute value looks like an integer, let's send it in _as a number_ (instead of a string).
            // The typical use cases for numeric parameters are to pass in Person IDs and other Healthy Church entity IDs
            // so right now, this is limited to positive integers (though a trivial adjustment to the regex could allow us
            // to handle negative ones too if needed or desired).
            // We have no current use cases requiring handling of floating point (decimal) numeric values.
            if (/^\d+$/.test(attributeValue)) {
                attributeValue = parseInt(attributeValue, 10);
            }

            // Handle Boolean literals coming in as strings; convert them to actual Booleans
            if (/^true$/i.test(attributeValue)) {
                attributeValue = true;
            }

            if (/^false/i.test(attributeValue)) {
                attributeValue = false;
            }

            // If in the future we need to convert other kinds of values from strings to other types for correct handling,
            // we can add additional checks like this.

            const propertyName = snakeToCamelCase(attributeName.replace(CONSUMER_DATA_PARAMS_PREFIX, ''));

            const nextCurrentAttributeObject = {
                ...currentAttributesObject,
                [propertyName]: attributeValue,
            };

            return nextCurrentAttributeObject;
        },
        {},
    );
};
