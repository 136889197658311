/* eslint-disable no-nested-ternary, linebreak-style */
import _ from 'lodash';
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { ReactPlugin, withAITracking } from "@microsoft/applicationinsights-react-js";
import {
    isCmDemoEnv,
    isCmLocalEnv,
    isCmLocalUiEnv,
    isCmProductionEnv,
    isCmQaEnv,
    isCmStagingEnv,
    isCmStaging2Env,
} from '../../global/utils/environments.js';
import eventsToTrack from './eventsToTrack.js';

// standardized tracking fields
// currently only used internally, export it if needed
class TrackingProperties {
    constructor() { }

    CampusId = null;
    Data = null;
    HCLoggedIn = null;  // user has to be logged in to use this app, setting to null, so it will be filtered out of transmitted data
}

const configurations = {
    development: {
        key: 'a9df6417-fa88-4088-b8f9-385bd27a9778',
    },
    qa: {
        key: '46dbee46-c3aa-4c93-8400-cf7961486790',
    },
    staging: {
        key: '396ea989-f67d-4d8a-8e87-1dd8e6ecf611',
    },
    staging2: {
        key: 'ba1d7039-65d9-4fff-a2ae-227ea3b3074b',
    },
    demo: {
        key: '4010f62b-3444-4c43-9234-185f060c957d',
    },
    production: {
        key: '9051cde2-fb33-4f4e-baeb-6d951c627c19',
    }
};

const getConfig = () => {
    if (isCmQaEnv) {
        return configurations['qa'];
    }

    if (isCmStagingEnv) {
        return configurations['staging'];
    }

    if (isCmStaging2Env) {
        return configurations['staging2'];
    }

    if (isCmDemoEnv) {
        return configurations['demo'];
    }

    if (isCmProductionEnv) {
        return configurations['production'];
    }

    // Fallback to dev environment app insights for all other scenarios such as local
    return configurations['development'];
};

const BrowserHistory = createBrowserHistory({ basename: '' });
const ReactPluginInstance = new ReactPlugin();

const dummyAnalyticsTool = {
    addTelemetryInitializer: _.noop,
    loadAppInsights: _.noop,
    trackEvent: _.noop,
    trackPageView: _.noop,
}

let analyticsConfig = getConfig();
let analyticsKey = analyticsConfig && analyticsConfig.key;
let analyticsTool = null;

if (analyticsKey) {
    analyticsTool = isCmLocalEnv || isCmLocalUiEnv ? dummyAnalyticsTool : new ApplicationInsights({
        config: {
            extensionConfig: {
                [ReactPluginInstance.identifier]: { history: BrowserHistory }
            },
            extensions: [ReactPluginInstance],
            instrumentationKey: analyticsKey,
            // more config options: https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#configuration
            // in my experience, i didn't try all of them, but the ones i did try (disableCorrelationHeaders, enableCorsCorrelation) didn't work
            // this could be due to not having the latest versions of the web & react libs due to other dependencies
            // however, much of the functionality documented in the javascript Sdk is provided (ex: tracking dependency & exceptions)
            // but in fairness, it (https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin) does say
            // it enables: Tracking of route changes & React components usage statistics
        }
    });
}

// currently only used internally, export it if needed
const createTrackingProperties = () => { return new TrackingProperties() };

const getElementName = (el) => (
    el === window ? 'window' :
        (el === document ? 'document' :
            (el.id ? el.id : (
                el.name ? el.name :
                    (el.localName ? el.localName : el.tagName)))));

const removeNullValueProperties = (properties) => {
    if (properties) {
        // console.log("removeNullValueProperties - b4", properties);
        // this only does the top level
        for (var propName in properties) {
            if (properties[propName] === null || properties[propName] === undefined) {
                delete properties[propName];
            }
        }
        // console.log("removeNullValueProperties -after", properties);
    }
}

const _trackEvent = (name, settings, state) => {
    if (!analyticsTool) {
        return;
    }

    // translate the event path from HTML objects to just their ids or names
    // this is simple and as such doesn't provide much help, but it could be built out

    let path;

    if (!window.event || !window.event.composedPath) {
        path = 'unknown';
    } else {
        path = window
            .event
            .composedPath()
            .reduce((total, current) => total.concat('[', getElementName(current), ']', '|'), '');
    }

    const properties = createTrackingProperties();

    // assign "standard properties" values here
    // use the Data object property for additional custom properties
    properties.Data = {
        EventType: window.event?.type ?? 'unknown',
        EventCategory: settings.category,
        EventAction: settings.action,
        EventSourcePath: path,
        ...properties.Data,
    };

    properties.Data[settings.storeDataPath] = settings.storeDataPath?.split('.').reduce((a, v) => a[v], state);

    // console.log("_trackEvent properties b4", properties);
    removeNullValueProperties(properties);
    // console.log("_trackEvent properties after", properties);

    // Duck typing the IEventTelemetry since we don't have typescript
    const trackingPayload = {
        name,
        properties,
    };

    analyticsTool.trackEvent(trackingPayload);
};

const _trackPageView = (name, state) => {
    if (!analyticsTool)
        return;

    const properties = createTrackingProperties();
    // assign "standard properties" values here
    // use the Data object property for additional custom properties
    /* currently unused, so commented out
    properties.Data = {
        // add state items here
        ...properties.Data,
    };
    properties.Data[settings.storeDataPath] = settings.storeDataPath?.split('.').reduce((a, v) => a[v], state);
    */

    // console.log("_trackPageView properties b4", properties);
    removeNullValueProperties(properties);
    // console.log("_trackPageView properties after", properties);

    // Duck typing the IPageViewTelemetry since we don't have typescript
    const trackingPayload = {
        name,
        properties,
    };

    analyticsTool.trackPageView(trackingPayload);
};

/* not currently used, so commented out
export const getPluginInstance = () => {
    if (analyticsKey && analyticsTool) {
        return analyticsTool;
    }
};
*/

export const getReactPluginInstance = () => {
    return ReactPluginInstance;
};

export const initializeAnalytics = config => {
    if (analyticsKey && analyticsTool) {
        analyticsTool.loadAppInsights();

        // filter out OPTIONS requests to reduce bandwidth costs
        const optionsFilteringFunction = (envelope) => (envelope?.baseData?.properties?.HttpMethod?.toUpperCase() !== 'OPTIONS');
        analyticsTool.addTelemetryInitializer(optionsFilteringFunction);

        // filter out null properties to reduce bandwidth costs
        const nullPropertiesFilteringFunction = (envelope) => {
            // console.log("nullPropertiesFilteringFunction - b4", envelope?.baseData?.properties);
            removeNullValueProperties(envelope?.baseData?.properties);
            // console.log("nullPropertiesFilteringFunction -after", envelope?.baseData?.properties);

            // the below (replaced w/ above) used to work, but doesn't appear to any longer, viewing the console.log output you can see that the
            // null valued properties are removed, however they are still transmitted in the call to AI :(
            // so, centralized the code in removeNullValueProperties, so it can be called in _trackEvent & _trackPageView prior to this
            /*
            if (envelope?.baseData?.properties) {
                // console.log("nullPropertiesFilteringFunction - b4", envelope?.baseData?.properties);
                // this only does the top level
                for (var propName in envelope.baseData.properties) {
                    if (envelope.baseData.properties[propName] === null || envelope.baseData.properties[propName] === undefined) {
                        delete envelope.baseData.properties[propName];
                    }
                }
                // console.log("nullPropertiesFilteringFunction -after", envelope?.baseData?.properties);
            }
            */
            return true;
        };
        analyticsTool.addTelemetryInitializer(nullPropertiesFilteringFunction);
    }
};

export const isAnalyticsAvailable = () => {
    return analyticsKey ? true : false;
};

export const reduxTrackingMiddleware = store => next => action => {
    // console.log("action", action);
    trackEvent(action.type, store.getState());

    // we could also do page view tracking on this @@router/LOCATION_CHANGE, but we already do it on the router w/ the react plugin
    // I think it would be nicer to keep it all in here, also not sure if all route changes go thru redux

    return next(action);
};

export const trackEvent = (actionType, state) => {
    if (isAnalyticsAvailable() && eventsToTrack[actionType]) {
        _trackEvent(actionType, eventsToTrack[actionType], state);
    }
}

export const trackPageView = (pageName, state) => {
    if (isAnalyticsAvailable()) {
        _trackPageView(pageName, state);
    }
}

export const withAnalytics = withAITracking;

export default {
    getReactPluginInstance,
    isAnalyticsAvailable,
    initializeAnalytics,
    withAnalytics,
};
