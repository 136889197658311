import {
    translationFactory,
} from './i18nUtils.js';

export const i18n = translationFactory();

export const DEFAULT_LOCALE = 'en-US';

export const ENTER_KEY_CODE = 13;

// Campus ("Church Entity") null values
export const UNKNOWN_CHURCH_ENTITY = -10;
export const DOES_NOT_ATTEND_CHURCH_ENTITY = -20;

/* eslint-disable no-undef */
export const API_EVENT_HOST = __API_EVENT_HOST__; // NOSONAR
export const API_HOST = __API_HOST__; // NOSONAR
export const API_JOURNEY_HOST = __API_JOURNEY_HOST__;
export const API_NOTIFICATION_HOST = __API_NOTIFICATION_HOST__; // NOSONAR
export const CM_CORE_FE_BRANCH = __CM_CORE_FE_BRANCH__; // NOSONAR
export const CM_CORE_FE_VERSION = __CM_CORE_FE_VERSION__; // NOSONAR
/* eslint-enable no-undef */

export const HCUI_CLASS_NAME = 'hcui';

export const DEFAULT_MODAL_CONFIRMATION_DIALOG_WIDTH = 442;

export const EDITOR_MODE = Object.freeze({
    add: Symbol('Add'),
    edit: Symbol('Edit'),
});

export const SCHEDULE_ENTITY_TYPE = Object.freeze({
    worshipService: 1,
    servingOpportunity: 2,
    event: 3,
    reminder: 4,
});

export const FOLLOW_UP_RULES_ENTITY_TYPE = Object.freeze({
    connectionCardField: 'response_card_field',
    connectionCardSubField: 'response_card_sub_field',
    connectionCardTemplate: 'response_card',
    connectionFormTemplate: 'connection_form_template',
    connectionQuestion: 'question',
    milestone: 'milestone',
});

export const FOLLOW_UP_RULES_ENTITY_TYPE_FRIENDLY_NAME = Object.freeze({
    [FOLLOW_UP_RULES_ENTITY_TYPE.connectionCardField]: i18n('Connection Card Field'),
    [FOLLOW_UP_RULES_ENTITY_TYPE.connectionCardSubField]: i18n('Connection Card Field'),
    [FOLLOW_UP_RULES_ENTITY_TYPE.connectionCardTemplate]: i18n('Connection Card Template'),
    [FOLLOW_UP_RULES_ENTITY_TYPE.connectionFormTemplate]: i18n('Connection Form Template'),
    [FOLLOW_UP_RULES_ENTITY_TYPE.connectionQuestion]: i18n('Connection Question'),
    [FOLLOW_UP_RULES_ENTITY_TYPE.milestone]: i18n('Milestone'),
});

export const HC_APP_URL_SCHEME = 'healthychurch';

export const IMAGE_GROUPS_CONFIG =
  [
      {
          id: 'Desktop',
          title: i18n('Web Header'),
          titleIconType: 'template',
          width: '50%',
          imageContainer: {
              id: '1',
              fileName: '',
              src: '',
              width: '2800',
              height: '780',
          },
      },
      {
          id: 'Mobile',
          width: '25%',
          title: i18n('Companion App Header'),
          titleIconType: 'phone-cell',
          imageContainer: {
              id: '2',
              fileName: '',
              src: '',
              width: '375',
              height: '250',

          },
      },
      {
          id: 'Thumbnail',
          width: '25%',
          title: i18n('Thumbnail'),
          titleIconType: false,
          imageContainer: {
              id: '3',
              fileName: '',
              src: '',
              width: '120',
              height: '120',
          },
      },
  ];
