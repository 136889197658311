import { isNil } from 'lodash';
import ActionType from './connectionForm.actionTypes';
import EventOccurrence from './models/eventOccurrence.model';

export type EventOccurrenceState = {
    data: EventOccurrence | null;
    isFetchingEventOccurrence: boolean;
};

export const DEFAULT_STATE: EventOccurrenceState = {
    data: null,
    isFetchingEventOccurrence: false,
};

interface RequestEventOccurrenceAction {
    type: ActionType.REQUEST_EVENT_OCCURRENCE;
}

interface ReceiveEventOccurrenceAction {
    type: ActionType.RECEIVE_EVENT_OCCURRENCE;
    result: EventOccurrence;
}

type KnownActionType = RequestEventOccurrenceAction | ReceiveEventOccurrenceAction;

export default (
    state: EventOccurrenceState | undefined,
    incomingAction: any,
): EventOccurrenceState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_EVENT_OCCURRENCE:
                return {
                    ...state,
                    isFetchingEventOccurrence: true,
                };

            case ActionType.RECEIVE_EVENT_OCCURRENCE:
            {
                const specificAction = action as ReceiveEventOccurrenceAction;

                return {
                    ...state,
                    data: specificAction.result,
                    isFetchingEventOccurrence: false,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
