enum HealthyChurchEnvironment {
    // NOTE: Enum values are _deliberately_ sorted from 'lower' to 'upper' environment order, not alphabetically!
    Local = 'Local',
    Feature = 'Feature',
    Dev = 'Dev',
    QA = 'QA',
    Staging = 'Staging',
    Staging2 = 'Staging2',
    Demo = 'Demo',
    Production = 'Production',
}

export default HealthyChurchEnvironment;
