// eslint-disable-next-line import/no-unresolved
import 'css-cm-ui';
import React, {
    // useLayoutEffect, // `useLayoutEffect()` not working on Giving History widget - Geoffrey Roberts - 2024-06-19
    useMemo,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StylesProvider } from '@material-ui/core/styles';
import {
    theme,
    ThemeProvider,
} from '@saddlebackchurch/react-cm-ui/core/styles';
import { Provider } from 'react-redux';
import {
    AnyAction,
    Store,
} from 'redux';
import { HealthyChurchEnvironment } from '../../src/global/api/models';
import { setEnvironment } from '../../src/global/api/public/apiEnvironment';
import jssPreset from '../../src/tools/jssPreset.js';

type PropTypes = {
    children: JSX.Element;
    environment: HealthyChurchEnvironment;
    generateClassName?: () => string;
    reduxStore?: Store<any, AnyAction> | null;
    withReduxStore?: boolean;
};

const defaultProps: Pick<PropTypes, 'generateClassName' | 'reduxStore' | 'withReduxStore'> = {
    generateClassName: () => '',
    reduxStore: null,
    withReduxStore: false,
};

function WidgetWrapper(props: PropTypes) {
    const {
        children,
        environment,
        reduxStore,
        withReduxStore,
        generateClassName,
    } = props;

    // Using `useLayoutEffect()` instead of `useEffect()` so that we are guaranteed that it happens earlier, before child components are rendered.
    // See: https://react.dev/reference/react/useLayoutEffect
    // NOT WORKING - Geoffrey Roberts - 2024-06-19.
    // useLayoutEffect(() => {
    //     setEnvironment(environment);
    // }, [environment]);

    // So, doing this instead
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const hasTheEnvironmentBeenSet = useMemo(() => {
        setEnvironment(environment);
        return true;
    }, [environment]);

    const widgetContent = (
        <div className="hc-ui">
            <div className="widget-content">
                {children}
            </div>
        </div>
    );

    const jss = useMemo(() => jssPreset(), []);

    const wrappedWidgetContent = withReduxStore ? (
        <Provider store={reduxStore}>
            {widgetContent}
        </Provider>
    ) : (
        widgetContent
    );

    return (
        <StylesProvider
            generateClassName={generateClassName}
            jss={jss}
        >
            <ThemeProvider theme={theme}>
                {wrappedWidgetContent}
            </ThemeProvider>
        </StylesProvider>
    );
}

WidgetWrapper.defaultProps = defaultProps;

export default WidgetWrapper;
