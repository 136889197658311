/**
 * This enumeration contains the Action Type string constants for Redux for the
 * contact form section on public-facing connection form.
 */
enum ActionType {
    RESET_DATA = 'ContactFormActions.Public.ON_RESET_DATA',
    SET_ADDRESS1 = 'ContactFormActions.Public.ON_UPDATE_ADDRESS_1',
    SET_ADDRESS2 = 'ContactFormActions.Public.ON_UPDATE_ADDRESS_2',
    SET_CITY = 'ContactFormActions.Public.ON_UPDATE_CITY',
    SET_CONTACT_DATA = 'ContactFormActions.Public.ON_UPDATE_CONTACT_DATA',
    SET_COUNTRY = 'ContactFormActions.Public.ON_UPDATE_COUNTRY',
    SET_EMAIL = 'ContactFormActions.Public.ON_UPDATE_EMAIL',
    SET_PHONE = 'ContactFormActions.Public.ON_UPDATE_PHONE',
    SET_PHONE_EXTENSION = 'ContactFormActions.Public.ON_UPDATE_PHONE_EXTENSION',
    SET_PHONE_TYPE = 'ContactFormActions.Public.ON_UPDATE_PHONE_TYPE',
    SET_POSTAL_CODE = 'ContactFormActions.Public.ON_UPDATE_POSTAL_CODE',
    SET_REGION = 'ContactFormActions.Public.ON_UPDATE_REGION',
}

export default ActionType;
