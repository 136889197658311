import { useEffect } from 'react';

const useComponentDidMount = (onMount) => {
    useEffect(() => {
        onMount();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useComponentDidMount;
