import { isNil } from 'lodash';
import ActionType from './personalForm.actionTypes';
import Gender from './models/gender.model';

export type PersonalFormState = {
    firstName: string;
    lastName: string;
    gender: Gender | null;
    campus: number | null;
};

export const DEFAULT_STATE: PersonalFormState = {
    campus: null,
    firstName: '',
    gender: null,
    lastName: '',
};

export default (
    state: PersonalFormState | undefined,
    action: any,
): PersonalFormState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.SET_FIRSTNAME: {
                const { value: firstName } = action;
                return {
                    ...state,
                    firstName,
                };
            }

            case ActionType.SET_LASTNAME: {
                const { value: lastName } = action;
                return {
                    ...state,
                    lastName,
                };
            }

            case ActionType.SET_GENDER: {
                const { value: gender } = action;
                return {
                    ...state,
                    gender,
                };
            }

            case ActionType.SET_PERSONAL_DATA: {
                const { value: personalData } = action;
                return {
                    ...state,
                    ...personalData,
                };
            }

            case ActionType.SET_CAMPUS: {
                const { value: campus } = action;
                return {
                    ...state,
                    campus,
                };
            }

            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            default:
                return state;
        }
    }

    return state;
};
