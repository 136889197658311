// eslint-disable-next-line import/no-extraneous-dependencies
import { createGenerateClassName } from '@material-ui/core/styles';
import ConnectionForm from '../../../src/connectionForms/public/connectionForm';
import i18nResources from '../../../src/connectionForms/public/translations';
import WidgetType from '../../models/widgetType.model';
import loadWidgetsIntoHtmlConsumers from '../../shared/widgetLoaderUtil';
import connectionFormRootReducer from './reducers';

const generateClassName = createGenerateClassName({ productionPrefix: WidgetType.ConnectionForm });

loadWidgetsIntoHtmlConsumers({
    App: ConnectionForm,
    widgetType: WidgetType.ConnectionForm,
    generateClassName,
    rootReducer: connectionFormRootReducer,
    i18nResources,
});
