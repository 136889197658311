import {
    flatMap,
    isEmpty,
} from 'lodash';
import { DateISOString } from '../../global/models';
import Answer from './models/answer.model';
import ConnectionFormContainerOrField from './models/connectionFormContainerOrField.model';
import ConnectionFormField from './models/connectionFormField.model';
import ConnectionFormSection from './models/connectionFormSection.model';
import ConnectionFormTemplate from './models/connectionFormTemplate.model';
import ContainerOrFieldType from './models/containerOrFieldType.model';
import ExpectedAnswerType from './models/expectedAnswerType.model';

/**
 * Extracts the Answer Value, given an Answer object and the Expected Answer Type.
 */
export const getAnswer = (answerInfo: Answer, answerType: ExpectedAnswerType): boolean | DateISOString | string | number | null => { // eslint-disable-line max-len
    if (isEmpty(answerInfo) || !answerType) {
        return null;
    }

    let answer = null;

    if (answerType === ExpectedAnswerType.Boolean) {
        answer = answerInfo.booleanValue;
    } else if (answerType === ExpectedAnswerType.DateTime) {
        answer = answerInfo.dateTimeValue;
    } else if (answerType === ExpectedAnswerType.Numeric) {
        answer = answerInfo.numericValue;
    } else if (answerType === ExpectedAnswerType.SelectedChoice) {
        answer = answerInfo.selectedAnswerChoiceId;
    } else if (answerType === ExpectedAnswerType.Text) {
        answer = answerInfo.textValue;
    }

    return answer;
};

/**
 * Flattens the Form Template structure into an array of the individual
 * Fields/Questions.
 */
export const getFlattenedFormFields = (formTemplate: ConnectionFormTemplate): ConnectionFormField[] => { // eslint-disable-line max-len
    // first pass to combine the Fields/Containers for allSections
    const unifiedFieldList: ConnectionFormContainerOrField[] = flatMap(
        formTemplate.sections,
        (s: ConnectionFormSection) => s.fields,
    );

    // second pass to flatten/consolidate the Fields in any Containers and end up with a collection of all the individual Fields
    const result: ConnectionFormField[] = flatMap(
        unifiedFieldList,
        (f: ConnectionFormContainerOrField) => {
            if (f.type === ContainerOrFieldType.Container) {
                return f.fields;
            }

            const transformedField: ConnectionFormField = {
                agreementId: f.agreement?.id,
                type: f.type,
                title: f.title,
                order: f.order,
                questionId: f.questionId,
                questionClassification: f.questionClassification,
                questionType: f.questionType,
                expectedAnswerType: f.expectedAnswerType,
                isRequired: f.isRequired,
                placeholder: f.placeholder,
                answerChoices: f.answerChoices,
            };

            return transformedField;
        },
    );

    return result;
};
