import { combineReducers } from 'redux';
import publicConnectionFormReducers from '../../../src/connectionForms/public/publicConnectionForm.reducers';

const connectionForms = combineReducers({
    publicForm: publicConnectionFormReducers,
});

const finalReducers = combineReducers({
    connectionForms,
});

export default finalReducers;
