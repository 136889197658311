enum PhoneType {
    Home = 'Home Phone',
    Work = 'Work Phone',
    Cell = 'Cell Phone',
}

export type PhoneTypeProps = {
    isHome: boolean;
    isMobile: boolean;
    isWork: boolean;
};

export default PhoneType;
