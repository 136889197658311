import {
    useEffect,
    useRef,
} from 'react';

const useComponentDidUpdate = (
    fn,
    deps,
) => {
    const prevRef = useRef(true);

    useEffect(() => {
        if (prevRef.current) {
            prevRef.current = false;
        } else {
            fn();
        }
    }, deps);
};

export default useComponentDidUpdate;
