/**
 * Intended to be the definitive enumeration for identifying the various states or modes for a CRUD-centric UI component.
 *
 * Supports `Add`, `Edit` and `View` states/values.
 */
enum UIMode {
    /**
     * UI is in `Add` or `Create New` mode
     */
    Add = 1,
    /**
     * UI is in `Edit` mode
     */
    Edit = 2,
    /**
     * UI is in `View` mode
     */
    View = 3,
}

export default UIMode;
