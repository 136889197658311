import {
    ActivityIndicator,
    Box,
} from '@saddlebackchurch/react-cm-ui';
import React from 'react';

type PropTypes = {
    offsetTop?: number;
};

function StyledActivityIndicator({ offsetTop = 70 }: PropTypes) {
    return (
        <Box
            alignItems="center"
            display="flex"
            height={`calc(100vh - ${offsetTop}px)`}
            justifyContent="center"
            mt={`-${offsetTop}px`}
        >
            <ActivityIndicator />
        </Box>
    );
}

export default StyledActivityIndicator;
