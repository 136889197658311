enum QuestionTypeId {
    Agreement = 1000,
    Checkbox = 3,
    Date = 4,
    DropDown = 6,
    MultipleChoice = 5,
    ParagraphText = 2,
    SingleLineText = 1,
}

export default QuestionTypeId;
