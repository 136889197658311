import {
    startCase,
    toLower,
} from 'lodash';
import {
    EnvironmentApiHostDirectory,
    HealthyChurchEnvironment,
    ServiceType,
} from './models';

export const apiHostsDirectory: Record<HealthyChurchEnvironment, EnvironmentApiHostDirectory> = {
    [HealthyChurchEnvironment.Local]: {
        [ServiceType.Core]: 'http://localhost:5000',
        [ServiceType.Events]: 'http://localhost:5100',
        [ServiceType.Giving]: 'http://localhost:5400',
        [ServiceType.Journey]: 'http://localhost:5500',
        [ServiceType.Notifications]: 'http://localhost:5300',
        [ServiceType.SmallGroups]: 'http://localhost:5600',
        [ServiceType.Workflows]: 'http://localhost:5200',
    },
    [HealthyChurchEnvironment.Dev]: {
        [ServiceType.Core]: 'https://hc-dev.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-dev.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-dev.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-dev.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-dev.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-dev.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-dev.azurewebsites.net',
    },
    [HealthyChurchEnvironment.Feature]: {
        [ServiceType.Core]: 'https://hc-feature.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-feature.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-feature.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-feature.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-feature.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-feature.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-feature.azurewebsites.net',
    },
    [HealthyChurchEnvironment.QA]: {
        [ServiceType.Core]: 'https://hc-qa.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-qa.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-qa.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-qa.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-qa.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-qa.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-qa.azurewebsites.net',
    },
    [HealthyChurchEnvironment.Staging]: {
        [ServiceType.Core]: 'https://hc-stage.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-stage.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-stage.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-stage.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-stage.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-stage.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-stage.azurewebsites.net',
    },
    [HealthyChurchEnvironment.Staging2]: {
        [ServiceType.Core]: 'https://hc-stage2.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-stage2.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-stage2.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-stage2.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-stage2.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-stage2.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-stage2.azurewebsites.net',
    },
    [HealthyChurchEnvironment.Demo]: {
        [ServiceType.Core]: 'https://hc-demo.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-demo.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-demo.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-demo.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-demo.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-demo.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-demo.azurewebsites.net',
    },
    [HealthyChurchEnvironment.Production]: {
        [ServiceType.Core]: 'https://hc.saddleback.com',
        [ServiceType.Events]: 'https://hc-eventservice-prod.azurewebsites.net',
        [ServiceType.Giving]: 'https://hc-givingservice-prod.azurewebsites.net',
        [ServiceType.Journey]: 'https://hc-journeyservice-prod.azurewebsites.net',
        [ServiceType.Notifications]: 'https://hc-notificationservice-prod.azurewebsites.net',
        [ServiceType.SmallGroups]: 'https://hc-smallgroupservice-prod.azurewebsites.net',
        [ServiceType.Workflows]: 'https://hc-workflowsservice-prod.azurewebsites.net',
    },
};

export const getTitleCasedEnvironmentName = (environment: string): string => {
    switch (environment.toUpperCase()) {
        case 'QA':
            return 'QA';
        case 'STAGING2':
            return 'Staging2';
        default:
            return startCase(toLower(environment));
    }
};

// eslint-disable-next-line max-len
export const isValidEnvironment = (environment: string): boolean => HealthyChurchEnvironment[environment] !== undefined;

// eslint-disable-next-line max-len
export const isValidService = (service: string): boolean => ServiceType[service] !== undefined;
