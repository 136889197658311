enum QuestionTypeName {
    Checkbox = 'Checkbox',
    Date = 'Date',
    DropDown = 'Drop Down',
    MultipleChoice = 'Multiple Choice',
    ParagraphText = 'Paragraph Text',
    SingleLineText = 'Single Line Text',
}

export default QuestionTypeName;
