enum PersonalInformationPanelField {
    FirstName = 'FirstName',
    LastName = 'LastName',
    Email = 'Email',
    SinglePhoneNumberWithSpecifiedType = 'SinglePhoneNumberWithSpecifiedType',
    Gender = 'Gender',
    Campus = 'ChurchEntityId',
    Address1 = 'Address1',
    Address2 = 'Address2',
    City = 'City',
    Region = 'Region',
    PostalCode = 'PostalCode',
    Country = 'Country',
}

export default PersonalInformationPanelField;
