import React, { useEffect } from 'react';
import {
    Button,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import {
    forEach,
    intersection,
    isEmpty,
    isEqual,
    isFunction,
    isNil,
    isNumber,
} from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { QuestionType } from '../../connectionQuestions/global/models';
import { ENTER_KEY_CODE } from '../../global/keyCodeConstants.js';
import {
    useComponentDidMount,
    useComponentDidUpdate,
} from '../../global/lifeCycleHooks';
import { CampusSelectOption } from '../../global/models';
import PhoneType from '../../global/models/phoneType.model';
import PhoneTypeId from '../../global/models/phoneTypeId.model';
import StyledActivityIndicator from '../../global/styledActivityIndicator';
import Utils, { usePrevious } from '../../global/utils/utils.js';
import {
    ConnectionFormType,
    PersonalInformationPanelField,
} from '../models';
import {
    getCampusList as getCampusListAction,
    getConnectionFormTemplate as getConnectionFormTemplateAction,
    getCountryList as getCountryListAction,
    getEventOccurrence as getEventOccurrenceAction,
    getPersonInfo as getPersonInfoAction,
    getRegionsByCountry as getRegionsByCountryAction,
    getWorshipService as getWorshipServiceAction,
    saveConnectionFormEntry as saveConnectionFormEntryAction,
} from './connectionForm.actions';
import { ConnectionFormPublicState } from './connectionForm.reducer';
import ConnectionFormSectionList from './connectionFormSectionList';
import ConnectionFormStatus from './connectionFormStatus';
import {
    BEM_BLOCK_NAME,
    DOES_NOT_ATTEND_CHURCH_ENTITY,
    formTemplateFetchErrorMessages,
    formEntrySaveErrorMessages,
} from './constants';
import {
    resetData as resetDataContactFormAction,
    setContactData as updateContactDataAction,
} from './contactForm.actions';
import { ContactFormState } from './contactForm.reducer';
import { CountriesListState } from './countriesList.reducer';
import MissingRequiredFieldsModal from './missingRequiredFieldsModal';
import AgreementEntry from './models/agreementEntry.model';
import Answer from './models/answer.model';
import ConnectionFormField from './models/connectionFormField.model';
import ContainerOrFieldType from './models/containerOrFieldType.model';
import EventOccurrence from './models/eventOccurrence.model';
import ExpectedAnswerType from './models/expectedAnswerType.model';
import Gender from './models/gender.model';
import PersonalDetails from './models/personDetails.model';
import WorshipService from './models/worshipService.model';
import {
    resetData as resetDataPersonalFormAction,
    updatePersonalData as updatePersonalDataAction,
} from './personalForm.actions';
import { PersonalFormState } from './personalForm.reducer';
import { RegionByCountryState } from './regionByCountry.reducer';
import { EntryFormSaveState } from './saveConnectionForm.reducer';
import SignInRequiredModal from './signInRequiredModal';
import {
    getAnswer,
    getFlattenedFormFields,
} from './utils';

type PropTypes = {
    /**
     * If the user is authenticated, then this prop should contain the user's
     * access token, to allow the Connection Form component to make
     * authorized API calls.
     *
     * If user is not authenticated, then this should be `null`.
     */
    accessToken?: string | null;

    /**
     * Campus List data from Redux
     */
    campusSelectOptions: CampusSelectOption[];

    /**
     * Campus Id from query, should be used if user is not authenticated
     */
    campusId?: number | null;

    /**
     * integer - ID of the Connection Form Template
     */
    connectionFormTemplateId: number;

    /**
     * Contact Form Section data from Redux
    */
    contactForm: ContactFormState;

    /**
     * Country List data from Redux
     */
    countriesList: CountriesListState;

    /**
     * Connection Form data from Redux
     */
    connectionFormData: ConnectionFormPublicState;

    /**
     * Event date for Worship Service & Regular Event Occurrence determined from URL params (Expected Format:- YYYY-MM-DD format)
     */
    eventDate?: string | null;

    /**
     * Event Id for worship service or event occurence determined from URL params
     */
    eventId?: number | null;

    /**
     * Event occurence details for connection form
     */
    eventOccurrence: EventOccurrence | null;

    /**
     * State related to saving the form entry (e.g. `isSaving` flag while call
     * in in progress, and status code once call completes).
     */
    formEntrySaveState: EntryFormSaveState;

    /**
     * Redux Action function to fetch the campus list data from the API
     */
    getCampusList: () => void;

    /**
     * Redux Action function to fetch the Form Template data from the API
     */
    getConnectionFormTemplate: (formTemplateId: number) => void;

    /**
     * Redux Action function to fetch the country list data from the API
     */
    getCountryList: (locale?: string) => void;

    /**
     * Redux Action function to fetch event occurrence from the API
     */
    getEventOccurrence: Function;

    /**
     * Redux Action function to fetch the person details for authenticated users
     */
    getPersonInfo: Function;

    /**
     * Redux Action function to fetch the region list data by country from the API
     */
    getRegionsByCountry: Function;

    /**
     * Redux Action function to fetch worship service from the API
     */
    getWorshipService: Function;

    /**
     * Boolean flag indicating whether parent component is currently fetching data
     * from API
     */
    isParentFetchingData?: boolean;

    /**
     * legacy i18n translation function
     */
    legacyI18N?: (key: string, params?: { [param: string]: number; }) => string;

    /**
     * Boolean flag indicating whether the component is used publicly or not.
     */
    shouldUseNewTranslations?: boolean;

    /**
     * i18n locale (e.g. `en-US`, `es-US`, `es-AR`, `de-DE`, etc.)
     */
    locale?: string;

    /**
     * Handler for a request to Log In.
     *
     * Used by the 'Sign In Required' modal that is displayed to unauthenticated users
     * if the Form being filled out requires an authenticated user (e.g. to accept an Agreement).
     *
     * Can be an ordinary function or can be externally defined JavaScript
     * (to help widget consumers handle this event appropriately for their website).
     */
    onRequestSignIn?: (() => void) | string;

    /**
     * If user is authenticated, then this prop should contain the user's HC
     * Person Unique ID (a positive integer), to allow the user to be identified
     * by ID when the the Form Entry is submitted.  Also, this will allow us to
     * fetch the user's data and prepopulate the Form Entry's Personal Panel.
     *
     * If user is not authenticated, then this should be `null`.
     */
    personId?: number | null;

    /**
     * Person Details from Redux
     */
    person: PersonalDetails;

    /**
     * Personal Form Section data from Redux
     */
    personalForm: PersonalFormState;

    /**
     * Redux Action function to reset person panel contact form data
     */
    resetDataContactForm: Function;

    /**
     * Redux Action function to reset person panel personal form data
     */
    resetDataPersonalForm: Function;

    /**
     * Region by country list data from Redux
     */
    regionByCountry: RegionByCountryState;

    /**
     * Redux Action function to save connection form entry
     */
    saveConnectionFormEntry: Function;

    /**
     * Name of Tenant Church, for "branding".
     * Optional; may be `undefined` or `null`.
     */
    tenantChurchName?: string | null;

    /**
     * URL for tenant's logo image, for "branding".
     * Optional; may be `undefined` or `null`.
     */
    tenantLogoImageUrl?: string | null;

    /**
     * Redux Action function to to populate contact section data on personal panel
     */
    updateContactData: Function;

    /**
     * Redux Action function to populate personal section data on personal panel
     */
    updatePersonalData: Function;

    /**
     * Worship service details for connection form
     */
    worshipService: WorshipService | null;

    /**
     * cancel entry for connection form
     */
    onCancelConnectionFormEntry: () => void;

    /**
     * Optional props related to personal details transfer between native and webview
     */
    webviewPersonalInfoData?: Record<string, any>;
    requestPersonalDetailsFromNative?: () => void;
    savePersonalDetailsToNative?: (data: Record<string, any>) => void;

    // TODO: Add more props (including data coming from Redux)
};

export type ConnectionFormPropTypes = PropTypes;

export const defaultProps: Pick<PropTypes, 'accessToken' | 'campusId' | 'eventDate' | 'eventId' | 'isParentFetchingData' | 'legacyI18N' | 'locale' | 'personId' | 'shouldUseNewTranslations' | 'tenantChurchName' | 'tenantLogoImageUrl'> = {
    accessToken: null,
    eventDate: null,
    eventId: null,
    isParentFetchingData: false,
    legacyI18N: (key: string, params?: { [param: string]: number; }) => (key), // eslint-disable-line @typescript-eslint/no-unused-vars
    locale: 'en-US',
    personId: null,
    shouldUseNewTranslations: true,
    tenantChurchName: null,
    tenantLogoImageUrl: null,
    campusId: null,
};

const mapStateToProps = (state) => {
    const {
        connectionForms: {
            publicForm: {
                campusList: {
                    campusSelectOptions,
                },
                connectionForm: connectionFormData,
                contactForm,
                countriesList,
                eventOccurrence: {
                    data: eventOccurrence,
                },
                personDetails: {
                    person,
                },
                personalForm,
                regionByCountry,
                saveConnectionForm: formEntrySaveState,
                worshipService: {
                    data: worshipService,
                },
            },
        },
    } = state;

    return {
        campusSelectOptions,
        connectionFormData,
        contactForm,
        countriesList,
        eventOccurrence,
        formEntrySaveState,
        person,
        personalForm,
        regionByCountry,
        worshipService,
    };
};

const mapDispatchToProps = {
    getConnectionFormTemplate: getConnectionFormTemplateAction,
    getCampusList: getCampusListAction,
    getCountryList: getCountryListAction,
    getEventOccurrence: getEventOccurrenceAction,
    getPersonInfo: getPersonInfoAction,
    getRegionsByCountry: getRegionsByCountryAction,
    getWorshipService: getWorshipServiceAction,
    resetDataContactForm: resetDataContactFormAction,
    resetDataPersonalForm: resetDataPersonalFormAction,
    saveConnectionFormEntry: saveConnectionFormEntryAction,
    updatePersonalData: updatePersonalDataAction,
    updateContactData: updateContactDataAction,
};

const useStyles = makeStyles(({
    breakpoints,
    palette,
    spacing,
}) => ({
    actionsBtn: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        width: '100%',
    },
    cancelButton: {
        width: '100%',
        '& span': {
            color: palette.cyan[600],
        },
    },
    container: {
        backgroundColor: palette.common.white,
        margin: '0 auto',
        padding: spacing(3),
        width: 600,
        '& .input-error-message': {
            marginTop: spacing(0.5),
        },
        [breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    defaultChurchLogo: {
        margin: `0 auto ${spacing(2)}px`,
        width: 200,
    },
    defaultChurchLogoWithImage: {
        margin: `0 auto ${spacing(2)}px`,
        width: '100%',
    },
    publicTitle: {
        marginBottom: spacing(3),
        textAlign: 'center',
    },
    imageHeader: {
        width: 'calc(100% + 65px)', // Full width of the container plus negative margins
        position: 'relative',
        marginLeft: '-33px',
        top: '-32px',
    },
    headerTextColor: {
        color: `${palette.grey[400]} !important`,
        fontStyle: 'italic',

    },
}));

const PERSONAL_FIELDS: PersonalInformationPanelField[] = [
    PersonalInformationPanelField.FirstName,
    PersonalInformationPanelField.LastName,
    PersonalInformationPanelField.Campus,
    PersonalInformationPanelField.Gender,
];

const CONTACT_FIELDS: PersonalInformationPanelField[] = [
    PersonalInformationPanelField.Email,
    PersonalInformationPanelField.SinglePhoneNumberWithSpecifiedType,
    PersonalInformationPanelField.Address1,
    PersonalInformationPanelField.Address2,
    PersonalInformationPanelField.City,
    PersonalInformationPanelField.Region,
    PersonalInformationPanelField.PostalCode,
    PersonalInformationPanelField.Country,
];

// FIXME: Temporary code to change the way the form renders for a campaign,
// remove this and all related code when not longer needed
// see: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87959
// and: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87960
const WHO_IS_YOUR_ONE_FORM_ID = 148;

/**
 * This component is the top-level shared component that powers both the
 * standalone public-facing page that hosts the Published Connection Form
 * as well as the widget.
 */
function ConnectionForm(props: PropTypes) {
    const {
        accessToken,
        campusSelectOptions,
        campusId,
        connectionFormData,
        connectionFormTemplateId,
        contactForm,
        countriesList: {
            data: countriesListData,
        },
        eventDate,
        eventId,
        eventOccurrence,
        formEntrySaveState,
        getCampusList,
        getConnectionFormTemplate,
        getCountryList,
        getEventOccurrence,
        getPersonInfo,
        getRegionsByCountry,
        getWorshipService,
        isParentFetchingData,
        shouldUseNewTranslations = true,
        legacyI18N,
        locale,
        onRequestSignIn,
        person,
        personalForm,
        personId,
        regionByCountry: {
            data: regionByCountryData,
        },
        resetDataContactForm,
        resetDataPersonalForm,
        saveConnectionFormEntry,
        tenantChurchName,
        tenantLogoImageUrl,
        updateContactData,
        updatePersonalData,
        worshipService,
        onCancelConnectionFormEntry,
        webviewPersonalInfoData,
        requestPersonalDetailsFromNative,
        savePersonalDetailsToNative,
    } = props;

    const {
        address: {
            countryAlpha3: countryAlpha3Value,
        },
    } = contactForm;

    const prevConnectionFormData = usePrevious(connectionFormData);
    const prevPerson = usePrevious(person);

    const classes = useStyles();

    const {
        i18n,
        t,
    } = useTranslation();

    const isSavingFormEntry = formEntrySaveState.isSaving;

    const isFetching = isParentFetchingData ||
        connectionFormData.isFetchingFormTemplate ||
        isSavingFormEntry;

    const isPersonalPanelLite = connectionFormData?.formTemplate?.personalInformationPanel?.type === 'Lite';
    const personalPanelRequiredFields = connectionFormData
        ?.formTemplate
        ?.personalInformationPanel
        ?.requiredFields ?? [];

    // look for the requiresAuthentication field on the form
    // OR see if any of the fields have agreements that require it
    /* eslint-disable arrow-body-style */
    const isAuthenticationRequired = connectionFormData?.formTemplate?.requiresAuthentication ??
        connectionFormData?.formTemplate?.sections.some((s) => {
            return s.fields?.some((f) => {
                return f.agreement?.requiresAuthentication;
            });
        }) ?? false;
    /* eslint-enable arrow-body-style */

    const areAnyPersonalFieldsRequired = !isEmpty(
        intersection(PERSONAL_FIELDS, personalPanelRequiredFields),
    );

    const areAnyContactFieldsRequired = !isEmpty(
        intersection(CONTACT_FIELDS, personalPanelRequiredFields),
    );

    const personalPanelOrder = connectionFormData
        ?.formTemplate
        ?.personalInformationPanel
        .personalPanelOrder ?? 0;

    const [agreements, setAgreements] = React.useState<AgreementEntry[]>([]);
    const [answers, setAnswers] = React.useState<Answer[]>([]);
    const [canSaveAnswers, setCanSaveAnswers] = React.useState<boolean>(true);
    const [hasAnyAgreements, setHasAnyAgreements] = React.useState<boolean>(false);
    const [hasAnyAnswers, setHasAnyAnswers] = React.useState<boolean>(false);

    const [
        canSaveContactForm,
        setCanSaveContactForm,
    ] = React.useState<boolean>(!areAnyContactFieldsRequired);

    const [
        hasAnyContactInfo,
        setHasAnyContactInfo,
    ] = React.useState<boolean>(false);

    const [
        canSavePersonalForm,
        setCanSavePersonalForm,
    ] = React.useState<boolean>(!areAnyPersonalFieldsRequired);

    const [
        hasAnyPersonalInfo,
        setHasAnyPersonalInfo,
    ] = React.useState<boolean>(false);

    const [
        wasFormSubmitted,
        setWasFormSubmitted,
    ] = React.useState<boolean>(false);

    const [
        isMissingRequiredFieldsModalOpen,
        setIsMissingRequiredFieldsModalOpen,
    ] = React.useState<boolean>(false);

    const [
        requiredFormFields,
        setRequiredFormFields,
    ] = React.useState<ConnectionFormField[]>([]);

    const isWhoIsYourOneForm = connectionFormTemplateId === WHO_IS_YOUR_ONE_FORM_ID;

    // See if we need to change the locale
    useEffect(() => {
        if (!isNil(i18n) &&
            isFunction(i18n.changeLanguage) &&
            locale !== i18n.language) {
            // eslint-disable-next-line no-console
            console.log('Healthy Church Forms Widget: i18n - Changing language to:', locale);
            i18n.changeLanguage(locale);
        }
    }, [
        i18n,
        locale,
    ]);

    // Fetch Form Template and Campus and Country Lookup Values
    useComponentDidMount(() => {
        getConnectionFormTemplate(connectionFormTemplateId);
        getCampusList();
        getCountryList(locale);

        if (countryAlpha3Value) {
            getRegionsByCountry(countryAlpha3Value, locale);
        }
    });

    // When Form Template is fetched, get flattened list of required fields
    useComponentDidUpdate(() => {
        if (connectionFormData !== prevConnectionFormData &&
            !isNil(connectionFormData.formTemplate)) {
            const requiredFields = getFlattenedFormFields(connectionFormData.formTemplate)
                .filter((f) => f.isRequired);

            setRequiredFormFields(requiredFields);
        }
    }, [
        connectionFormData,
        prevConnectionFormData,
    ]);

    // Get person details
    useEffect(() => {
        if (accessToken && personId) {
            getPersonInfo(accessToken, personId);
        }
    }, [
        accessToken,
        getPersonInfo,
        personId,
    ]);

    // Get Worship Service / Event occurrence details
    useComponentDidUpdate(() => {
        if (!isEmpty(connectionFormData) &&
            !isEqual(prevConnectionFormData, connectionFormData) &&
            !connectionFormData.isFetchingFormTemplate &&
            (!isEmpty(connectionFormData.formTemplate) &&
            !isNil(connectionFormData.formTemplate.formType)) &&
            !isNil(eventId) &&
            !isNil(eventDate)
        ) {
            const eventDateMoment = moment(eventDate);
            const eventDateRangeStart = eventDateMoment.startOf('day').format('YYYY-MM-DDTHH:mm:ss');
            const eventDateRangeEnd = eventDateMoment.endOf('day').format('YYYY-MM-DDTHH:mm:ss');

            if (connectionFormData.formTemplate.formType === ConnectionFormType.Regular) {
                getEventOccurrence(
                    eventDateRangeStart,
                    eventDateRangeEnd,
                    eventId,
                );
            } else {
                getWorshipService(
                    connectionFormData.formTemplate.churchEntityId,
                    eventDateRangeStart,
                    eventDateRangeEnd,
                    eventId,
                );
            }
        }
    }, [connectionFormData, prevConnectionFormData]);

    useEffect(() => {
        if (!webviewPersonalInfoData) {
            return;
        }

        const updatePersonalDataResult: Record<string, any> = {
            firstName: webviewPersonalInfoData[PersonalInformationPanelField.FirstName],
            lastName: webviewPersonalInfoData[PersonalInformationPanelField.LastName],
        };

        if (webviewPersonalInfoData[PersonalInformationPanelField.Campus]) {
            updatePersonalDataResult.campus =
                webviewPersonalInfoData[PersonalInformationPanelField.Campus];
        } else if (!isNil(campusId)) {
            updatePersonalDataResult.campus = campusId;
        }

        if (!isEmpty(webviewPersonalInfoData[PersonalInformationPanelField.Gender])) {
            if (webviewPersonalInfoData[PersonalInformationPanelField.Gender] === Gender.Male) {
                updatePersonalDataResult.gender = Gender.Male;
            } else {
                updatePersonalDataResult.gender = Gender.Female;
            }
        }

        updatePersonalData(updatePersonalDataResult);

        const email = webviewPersonalInfoData[PersonalInformationPanelField.Email];

        const phoneTypeId = webviewPersonalInfoData.PhoneType;

        const phoneResult: Record<string, any> = {
            isHome: false,
            isMobile: false,
            isWork: false,
            extension: webviewPersonalInfoData.Extension,
            phoneTypeId,
            isValid: true,
        };

        if (phoneTypeId === PhoneTypeId.Home) {
            phoneResult.isHome = true;
            phoneResult.phoneNumber = webviewPersonalInfoData.HomePhone;
            phoneResult.phoneType = PhoneType.Home;
        } else if (phoneTypeId === PhoneTypeId.Cell) {
            phoneResult.isMobile = true;
            phoneResult.phoneNumber = webviewPersonalInfoData.CellPhone;
            phoneResult.phoneType = PhoneType.Cell;
        } else {
            phoneResult.isWork = true;
            phoneResult.phoneNumber = webviewPersonalInfoData.WorkPhone;
            phoneResult.phoneType = PhoneType.Work;
        }

        const countryResult: Record<string, any> = {};

        const alpha3 = webviewPersonalInfoData[PersonalInformationPanelField.Country];

        if (alpha3 && countriesListData.length > 0) {
            const country = countriesListData.find((value) => value.shortName === alpha3);

            if (country) {
                countryResult.country = country.longName;
                countryResult.countryAlpha2 = country.code;
                countryResult.countryAlpha3 = country.shortName;
            }
        }

        const regionResult: Record<string, any> = {};

        const regionCode = webviewPersonalInfoData[PersonalInformationPanelField.Region];

        if (regionCode) {
            regionResult.regionCode = regionCode;
        }

        updateContactData({
            email,
            phone: phoneResult,
            ...(!isPersonalPanelLite ? {
                address: {
                    address1: webviewPersonalInfoData[PersonalInformationPanelField.Address1],
                    address2: webviewPersonalInfoData[PersonalInformationPanelField.Address2],
                    city: webviewPersonalInfoData[PersonalInformationPanelField.City],
                    postalCode: webviewPersonalInfoData[PersonalInformationPanelField.PostalCode],
                    ...countryResult,
                    ...regionResult,
                },
            } : {
                address: {},
            }),
        });
    }, [
        campusId,
        countriesListData,
        isPersonalPanelLite,
        updateContactData,
        updatePersonalData,
        webviewPersonalInfoData,
    ]);

    // Pre-poulate person info
    useComponentDidUpdate(() => {
        if (accessToken && !isEmpty(person) && !isEqual(prevPerson, person)) {
            // Pre-populate personal form data
            let gender;
            let campus = person.churchEntityKnown ?
                DOES_NOT_ATTEND_CHURCH_ENTITY :
                null;

            if (person.churchEntityId) {
                campus = person.churchEntityId;
            } else if (!isNil(campusId)) {
                campus = campusId;
            }

            if (!isEmpty(person.gender)) {
                if (person.gender === 'M') {
                    gender = Gender.Male;
                } else {
                    gender = Gender.Female;
                }
            }

            updatePersonalData({
                firstName: person.firstName,
                lastName: person.lastName,
                gender,
                campus,
            });

            // Pre-populate contact form data
            const primaryAddress = person.addresses.find((item) => item.isPrimary);
            const primaryEmail = person.emails.find((item) => item.isPrimary);
            const primaryPhone = person.phones.find((item) => item.isPrimary);

            let isHome = false;
            let isMobile = false;
            let isWork = false;

            const displayPhoneNumber = primaryPhone?.displayPhoneNumber;
            const extension = primaryPhone?.extension;
            const phoneNumber = primaryPhone?.phoneNumber;
            const phoneTypeId = primaryPhone?.phoneTypeId;
            const phoneType = primaryPhone?.phoneType;

            if (primaryPhone?.phoneTypeId === PhoneTypeId.Home) {
                isHome = true;
            } else if (primaryPhone?.phoneTypeId === PhoneTypeId.Cell) {
                isMobile = true;
            } else {
                isWork = true;
            }

            updateContactData({
                email: primaryEmail?.email,
                phone: {
                    isHome,
                    isMobile,
                    isValid: true,
                    isWork,
                    countryCode: primaryPhone?.countryCode,
                    displayPhoneNumber,
                    extension,
                    phoneNumber,
                    phoneType,
                    phoneTypeId,
                },
                address: !isPersonalPanelLite ? {
                    address1: primaryAddress?.address1,
                    address2: primaryAddress?.address2,
                    city: primaryAddress?.city,
                    postalCode: primaryAddress?.postalCode,
                    country: primaryAddress?.country,
                    countryAlpha2: primaryAddress?.countryAlpha2,
                    countryAlpha3: primaryAddress?.countryAlpha3,
                    region: primaryAddress?.region,
                    regionCode: primaryAddress?.regionCode,
                } : {},
            });
        } else if (
            !accessToken &&
            isEmpty(person) &&
            (window as any)?.ReactNativeWebView?.postMessage &&
            requestPersonalDetailsFromNative
        ) {
            requestPersonalDetailsFromNative();
        } else if (!isNil(campusId)) {
            updatePersonalData({
                campus: campusId,
            });
        }
    }, [accessToken, person, prevPerson]);

    // Fetching regions by country
    useComponentDidUpdate(() => {
        if (!isEmpty(countryAlpha3Value)) {
            getRegionsByCountry(countryAlpha3Value, locale);
        }
    }, [
        countryAlpha3Value,
        getRegionsByCountry,
    ]);

    // Validate answers (check required form fields)
    useComponentDidUpdate(() => {
        setHasAnyAnswers(!isEmpty(answers));
        setHasAnyAgreements(!isEmpty(agreements));

        const canSave = requiredFormFields.every(
            (reqdField) => {
                // look through both the answers and agreements
                const answer = answers.find((a) => a.questionId === reqdField.questionId);
                const agreement = agreements.find((a) => a.agreementId === reqdField.agreementId);

                // if you can't find either, something isn't filled out
                if (isNil(answer) && isNil(agreement)) {
                    return false;
                }

                const answerValue = getAnswer(answer, reqdField.expectedAnswerType);
                let answerTypeOk = false;

                // figure out if the type of answer is correct
                switch (reqdField.expectedAnswerType) {
                    case ExpectedAnswerType.Text:
                        answerTypeOk = !Utils.isStringNullOrWhiteSpace(answerValue as string);
                        break;

                    case ExpectedAnswerType.Boolean:
                        answerTypeOk = answerValue === true;
                        break;

                    default:
                        answerTypeOk = !isNil(answerValue);
                        break;
                }

                // if one or the other is good, this field is ok
                return answerTypeOk || agreement?.agreementConsented;
            },
        );

        setCanSaveAnswers(canSave);
    }, [agreements, answers, requiredFormFields]);

    // Validate Contact Form when its data has changed
    useComponentDidUpdate(() => {
        const {
            address: {
                address1: address1Value,
                address2: address2Value,
                city: cityValue,
                countryAlpha3,
                isAddressValid,
                postalCode: postalCodeValue,
                regionCode: regionCodeValue,
            },
            email: {
                isEmailValid,
                value: emailValue,
            },
            phone: {
                phoneNumber: phoneNumberValue,
                isPhoneValid,
            },
        } = contactForm;

        const hasEmail = !Utils.isStringNullOrWhiteSpace(emailValue);
        const hasErrorOnEmail = hasEmail && !isEmailValid;

        const hasPhone = !Utils.isStringNullOrWhiteSpace(phoneNumberValue);
        const hasErrorOnPhone = hasPhone && !isPhoneValid;

        const hasAddressValues = !!cityValue ||
        !!postalCodeValue ||
        !!address1Value ||
        (!!countryAlpha3 && countryAlpha3 !== 'USA') || // Since USA is a default value here, that value does not automatically signify that the user has entered any address data on the form
        !!regionCodeValue;

        const hasErrorOnAddress = !isPersonalPanelLite && hasAddressValues && !isAddressValid;
        const hasContactFormError = hasErrorOnEmail || hasErrorOnPhone || hasErrorOnAddress;

        let canSave = !hasContactFormError;

        if (canSave && !isEmpty(personalPanelRequiredFields)) {
            forEach(personalPanelRequiredFields, (requiredField) => { /* We want to bail out early as soon as we discover a problem; hence the need to suppress `consistent-return` */ // eslint-disable-line consistent-return
                switch (requiredField) {
                    case PersonalInformationPanelField.Email:
                        canSave = canSave && hasEmail && isEmailValid;
                        break;

                    case PersonalInformationPanelField.SinglePhoneNumberWithSpecifiedType:
                        canSave = canSave && hasPhone && isPhoneValid;
                        break;

                    case PersonalInformationPanelField.Address1:
                        canSave = canSave && !Utils.isStringNullOrWhiteSpace(address1Value);
                        break;

                    case PersonalInformationPanelField.Address2:
                        canSave = canSave && !Utils.isStringNullOrWhiteSpace(address2Value);
                        break;

                    case PersonalInformationPanelField.City:
                        canSave = canSave && !Utils.isStringNullOrWhiteSpace(cityValue);
                        break;

                    case PersonalInformationPanelField.Region:
                        canSave = canSave &&
                            !Utils.isStringNullOrWhiteSpace(regionCodeValue);
                        break;

                    case PersonalInformationPanelField.PostalCode:
                        canSave = canSave && !Utils.isStringNullOrWhiteSpace(postalCodeValue);
                        break;

                    case PersonalInformationPanelField.Country:
                        canSave = canSave && !Utils.isStringNullOrWhiteSpace(countryAlpha3Value);
                        break;

                    default:
                        break;
                }

                if (!canSave) {
                    return false;
                }
            });
        }

        setHasAnyContactInfo(hasEmail || hasPhone || hasAddressValues);
        setCanSaveContactForm(canSave);
    }, [contactForm, connectionFormData]);

    // Validate Personal Form when its data has changed
    useComponentDidUpdate(() => {
        const {
            firstName,
            lastName,
            gender,
            campus,
        } = personalForm;

        const hasFirstName = !Utils.isStringNullOrWhiteSpace(firstName);
        const hasLastName = !Utils.isStringNullOrWhiteSpace(lastName);
        const hasGender = !Utils.isStringNullOrWhiteSpace(gender);
        const hasCampus = campus > 0 || campus === DOES_NOT_ATTEND_CHURCH_ENTITY;

        let canSave = true;

        if (!isEmpty(personalPanelRequiredFields)) {
            forEach(personalPanelRequiredFields, (requiredField) => { /* We want to bail out early as soon as we discover a problem; hence the need to suppress `consistent-return` */ // eslint-disable-line consistent-return
                switch (requiredField) {
                    case PersonalInformationPanelField.FirstName:
                        canSave = canSave && hasFirstName;
                        break;

                    case PersonalInformationPanelField.LastName:
                        canSave = canSave && hasLastName;
                        break;

                    case PersonalInformationPanelField.Gender:
                        canSave = canSave && hasGender;
                        break;

                    case PersonalInformationPanelField.Campus:
                        canSave = canSave && hasCampus;
                        break;

                    default:
                        break;
                }

                if (!canSave) {
                    return false;
                }
            });
        }

        setHasAnyPersonalInfo(hasFirstName || hasLastName || hasGender || hasCampus);
        setCanSavePersonalForm(canSave);
    }, [personalForm, connectionFormData]);

    const onFieldDetailsChanged = (
        field,
        selectedAnswer,
        expectedAnswerType,
        groupCheckboxQuestionId = null,
    ) => {
        const {
            questionId,
            questionType,
        } = field;

        const isGroupedCheckbox = !isNil(groupCheckboxQuestionId);
        const isMatchingAnswer =
            // eslint-disable-next-line max-len
            (a: Answer): boolean => (isGroupedCheckbox && a.questionId === groupCheckboxQuestionId) ||
                (!isGroupedCheckbox && a.questionId === questionId);

        const isAnswerAlreadyFilled = answers.some(isMatchingAnswer);

        // Update question answers which are already been answered
        if (isAnswerAlreadyFilled) {
            let modifiedAnswers: Answer[];

            if ((isGroupedCheckbox || questionType === QuestionType.Checkbox) &&
                selectedAnswer === false) { // un-selecting a previously selected checkbox
                modifiedAnswers = answers.filter((a) => !isMatchingAnswer(a));
            } else {
                modifiedAnswers = answers.map((item) => {
                    if (item.questionId === questionId ||
                        item.questionId === groupCheckboxQuestionId) {
                        const newAnswer = {
                            ...item,
                            ...(expectedAnswerType === ExpectedAnswerType.Boolean &&
                                { booleanValue: selectedAnswer }),
                            ...(expectedAnswerType === ExpectedAnswerType.DateTime &&
                                { dateTimeValue: selectedAnswer }),
                            ...(expectedAnswerType === ExpectedAnswerType.Numeric &&
                                { numericValue: selectedAnswer }),
                            ...(expectedAnswerType === ExpectedAnswerType.SelectedChoice &&
                                { selectedAnswerChoiceId: selectedAnswer }),
                            ...(expectedAnswerType === ExpectedAnswerType.Text &&
                                { textValue: selectedAnswer }),
                        };

                        return newAnswer;
                    }

                    return item;
                });
            }

            setAnswers(modifiedAnswers);
        } else {
            // Add new question answer to answers array
            const newAnswer = {
                questionId: field.type === ContainerOrFieldType.Container ?
                    groupCheckboxQuestionId : questionId,
                ...(expectedAnswerType === ExpectedAnswerType.Boolean &&
                    { booleanValue: selectedAnswer }),
                ...(expectedAnswerType === ExpectedAnswerType.DateTime &&
                    { dateTimeValue: selectedAnswer }),
                ...(expectedAnswerType === ExpectedAnswerType.Numeric &&
                    { numericValue: selectedAnswer }),
                ...(expectedAnswerType === ExpectedAnswerType.SelectedChoice &&
                    { selectedAnswerChoiceId: selectedAnswer }),
                ...(expectedAnswerType === ExpectedAnswerType.Text &&
                    { textValue: selectedAnswer }),
            };

            const modifiedAnswers = [
                ...answers,
                newAnswer,
            ];

            setAnswers(modifiedAnswers);
        }
    };

    const onAgreementConsentChanged = (
        field,
        consented,
    ) => {
        const {
            agreement,
        } = field;

        // eslint-disable-next-line max-len
        const isMatchingAgreement = (a: AgreementEntry): boolean => (a.agreementId === agreement?.id);
        const isAgreementAlreadyFilled = agreements.some(isMatchingAgreement);

        if (isAgreementAlreadyFilled) {
            const modifiedAgreements: AgreementEntry[] = agreements.map((item) => {
                if (item.agreementId === agreement?.id) {
                    const newAgreement = {
                        ...item,
                        agreementConsented: consented,
                    };

                    return newAgreement;
                }

                return item;
            });

            setAgreements(modifiedAgreements);
        } else {
            const newAgreement = {
                agreement,
                agreementId: agreement.id,
                agreementConsented: consented,
            };

            const modifiedAgreements = [
                ...agreements,
                newAgreement,
            ];

            setAgreements(modifiedAgreements);
        }
    };

    const resetConnectionEntryForm = () => {
        resetDataContactForm();
        resetDataPersonalForm();
        setAnswers([]);
    };

    if (isFetching) {
        return (
            <StyledActivityIndicator offsetTop={0} />
        );
    }

    const receivedErrorFetchingFormTemplate = !isFetching &&
        connectionFormData.hasFormTemplateError;

    /*
     * Display error related to fetching connection form details
     */
    if (receivedErrorFetchingFormTemplate) {
        const formTemplateFetchErrorInfo =
            formTemplateFetchErrorMessages.find((item) => item.code === connectionFormData.status);

        if (isEmpty(formTemplateFetchErrorInfo)) {
            return (
                <ConnectionFormStatus displayAtTheBottom={isWhoIsYourOneForm} />
            );
        }

        return (
            <ConnectionFormStatus
                displayAtTheBottom={isWhoIsYourOneForm}
                iconColor={formTemplateFetchErrorInfo.iconColor}
                iconType={formTemplateFetchErrorInfo.iconType}
                subTitle={shouldUseNewTranslations ? t(formTemplateFetchErrorInfo.subTitle) :
                    legacyI18N(formTemplateFetchErrorInfo.subTitle)}
                title={shouldUseNewTranslations ?
                    t(
                        formTemplateFetchErrorInfo.title,
                        { errorCode: connectionFormData.status },
                    ) :
                    legacyI18N(
                        formTemplateFetchErrorInfo.title,
                        { errorCode: connectionFormData.status },
                    )}
            />
        );
    }

    /*
     * Display success or error message related to saving the connection entry form
     */
    if (isNumber(formEntrySaveState.status)) {
        const saveEntryResponseInfo =
            formEntrySaveErrorMessages.find((item) => item.code === formEntrySaveState.status);

        if (isEmpty(saveEntryResponseInfo)) {
            return (
                <ConnectionFormStatus displayAtTheBottom={isWhoIsYourOneForm} />
            );
        }

        let iconUrl;
        let messageHTML;

        if (saveEntryResponseInfo.code === 200) {
            if (!isEmpty(connectionFormData?.formTemplate?.confirmationIconUrl)) {
                iconUrl = connectionFormData?.formTemplate?.confirmationIconUrl;
            }

            if (!isEmpty(connectionFormData?.formTemplate?.confirmationMessage)) {
                messageHTML = connectionFormData?.formTemplate?.confirmationMessage;
            }
        }

        return (
            <ConnectionFormStatus
                customIconUrl={iconUrl}
                customMessageHtml={messageHTML}
                displayAtTheBottom={isWhoIsYourOneForm}
                iconColor={saveEntryResponseInfo.iconColor}
                iconType={saveEntryResponseInfo.iconType}
                subTitle={shouldUseNewTranslations ? t(saveEntryResponseInfo.subTitle) :
                    legacyI18N(saveEntryResponseInfo.subTitle)}
                title={shouldUseNewTranslations ?
                    t(
                        saveEntryResponseInfo.title,
                        { errorCode: connectionFormData.status },
                    ) :
                    legacyI18N(
                        saveEntryResponseInfo.title,
                        { errorCode: connectionFormData.status },
                    )}
            />
        );
    }

    const onCancel = () => {
        resetConnectionEntryForm();
        setWasFormSubmitted(false);
        onCancelConnectionFormEntry();
    };

    const onSubmit = () => {
        setWasFormSubmitted(true);

        const hasAnyData = hasAnyAnswers ||
            hasAnyAgreements ||
            hasAnyContactInfo ||
            hasAnyPersonalInfo;

        const canSave = canSaveAnswers &&
            canSaveContactForm &&
            canSavePersonalForm;

        // Check if form entry is valid to be saved.
        // It must have at least same data (an answer or personal or contact info)
        // and all required fields must be filled in.
        if (!hasAnyData || !canSave) {
            // If form entry is not valid ...
            // 1) scroll to the top of the page
            window.scrollTo(0, 0);
            // 2) Open the modal
            setIsMissingRequiredFieldsModalOpen(true);
            // 3) Bail out
            return;
        }

        const {
            firstName,
            lastName,
            gender,
            campus,
        } = personalForm;

        const {
            address: {
                address1,
                address2,
                city,
                postalCode,
                countryAlpha3,
                regionCode,
            },
            email: {
                value: emailValue,
            },
            phone: {
                isHome,
                isMobile,
                isWork,
                phoneNumber: phoneNumberValue,
                extension,
            },
        } = contactForm;

        const hasAddressValues = !!city ||
            !!postalCode ||
            !!address1 ||
            (!!countryAlpha3 && countryAlpha3 !== 'USA') || // TODO: We might need to separate checking "does this have a value?" from "is this equal to the default value?"
            !!regionCode;

        // Modify personal data to save
        const personalData = {
            personId: personId ?? null,
            ...(address1 && hasAddressValues && { address1 }),
            ...(address2 && hasAddressValues && { address2 }),
            ...(campus && { churchEntityId: campus > 0 ? campus : null }),
            ...(campus && {
                churchEntityKnown: campus > 0 || campus === DOES_NOT_ATTEND_CHURCH_ENTITY,
            }),
            ...(city && hasAddressValues && { city }),
            ...(countryAlpha3 && hasAddressValues && { country: countryAlpha3 }),
            ...(emailValue && { email: emailValue }),
            ...(isHome && !!phoneNumberValue && { homePhone: phoneNumberValue }),
            ...(isMobile && !!phoneNumberValue && { cellPhone: phoneNumberValue }),
            ...(isWork && !!phoneNumberValue && !extension && { workPhone: phoneNumberValue }),
            ...(isWork && !!phoneNumberValue && !!extension && { workPhone: `${phoneNumberValue} ext. ${extension}` }),
            ...(firstName && { firstName }),
            ...(gender && { gender }),
            ...(lastName && { lastName }),
            ...(postalCode && hasAddressValues && { postalCode }),
            ...(regionCode && hasAddressValues && { region: regionCode }),
        };

        let occurrence = null;

        const isFormTypeWorshipService =
            connectionFormData?.formTemplate?.formType === ConnectionFormType.WorshipService;

        if (isFormTypeWorshipService && !isEmpty(worshipService)) {
            const {
                date,
                duration,
                occurrenceId: worshipServiceOccurenceId,
                scheduleId,
                startTime,
                timeZone,
            } = worshipService;

            occurrence = {
                date,
                duration,
                id: worshipServiceOccurenceId,
                scheduleId,
                startTime,
                timeZone,
            };
        } else if (!isEmpty(eventOccurrence)) {
            const {
                id,
                date,
                duration,
                startTime,
                timeZone,
                scheduleId,
            } = eventOccurrence;

            occurrence = {
                date,
                duration,
                id,
                scheduleId,
                startTime,
                timeZone,
            };
        }

        const data = {
            connectionFormTemplateId,
            entryTimeZone: moment.tz.guess(),
            eventId: !isEmpty(occurrence) ? eventId : null,
            occurrence,
            personalInfo: personalData,
            answers,
            agreements,
        };

        if ((window as any).ReactNativeWebView?.postMessage && savePersonalDetailsToNative) {
            const phoneFields: Record<string, any> = {};

            if (isHome && personalData.homePhone) {
                phoneFields.HomePhone = personalData.homePhone;
                phoneFields.PhoneType = PhoneTypeId.Home;
            } else if (isWork && personalData.workPhone) {
                phoneFields.WorkPhone = personalData.workPhone;
                phoneFields.PhoneType = PhoneTypeId.Work;

                if (extension) {
                    phoneFields.Extension = extension;
                }
            } else if (isMobile && personalData.cellPhone) {
                phoneFields.CellPhone = personalData.cellPhone;
                phoneFields.PhoneType = PhoneTypeId.Cell;
            }

            const personalInfoForWebview = {
                [PersonalInformationPanelField.Address1]: personalData.address1,
                [PersonalInformationPanelField.Address2]: personalData.address2,
                [PersonalInformationPanelField.Campus]: personalData.churchEntityId,
                [PersonalInformationPanelField.City]: personalData.city,
                [PersonalInformationPanelField.Country]: personalData.country,
                [PersonalInformationPanelField.Email]: personalData.email,
                [PersonalInformationPanelField.FirstName]: personalData.firstName,
                [PersonalInformationPanelField.LastName]: personalData.lastName,
                [PersonalInformationPanelField.Gender]:
                    personalData.gender === Gender.Male ? Gender.Male : Gender.Female,
                [PersonalInformationPanelField.Region]: personalData.region,
                [PersonalInformationPanelField.PostalCode]: personalData.postalCode,
                ...phoneFields,
            };

            savePersonalDetailsToNative(personalInfoForWebview);
        }

        saveConnectionFormEntry(accessToken, data);
    };

    const onSubmitKeyDown = (event) => {
        if (event.keyCode === ENTER_KEY_CODE) {
            onSubmit();
        }
    };

    return (
        <React.Fragment>
            {
                (!isAuthenticationRequired ||
                    (isAuthenticationRequired && !isNil(accessToken))) && (
                    <div className={classes.container}>
                        {/* Apply top of page branding elements (currently only the Tenant Logo image) */}
                        {
                            connectionFormData?.formTemplate?.imageUrl ?
                                (
                                    <div className={classes.defaultChurchLogoWithImage}>
                                        <img
                                            alt={tenantChurchName ?? ''}
                                            className={classes.imageHeader}
                                            src={connectionFormData?.formTemplate?.imageUrl}
                                        />
                                    </div>
                                ) :
                                !isNil(tenantLogoImageUrl) && (
                                <div className={classes.defaultChurchLogo}>
                                    <img
                                        alt={tenantChurchName ?? ''}
                                        src={tenantLogoImageUrl}
                                    />
                                </div>
                                )
                        }

                        {/* Add description */}
                        <div
                            className={classes.headerTextColor}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: connectionFormData?.formTemplate?.description ?? null,
                            }}
                        />

                        {/* Add the public title */}
                        {!isNil(connectionFormData?.formTemplate?.publicTitle) && (
                            <Typography
                                className={classes.publicTitle}
                                variant="h2"
                            >
                                {connectionFormData.formTemplate.publicTitle}
                            </Typography>
                        )}

                        <ConnectionFormSectionList
                            agreements={agreements}
                            answers={answers}
                            campusSelectOptions={campusSelectOptions}
                            countriesListData={countriesListData}
                            isPersonalPanelLite={isPersonalPanelLite}
                            isWhoIsYourOneForm={isWhoIsYourOneForm}
                            legacyI18N={legacyI18N}
                            onChangeAgreementConsent={onAgreementConsentChanged}
                            onFieldChanged={onFieldDetailsChanged}
                            personalPanelOrder={personalPanelOrder}
                            personalPanelRequiredFields={personalPanelRequiredFields}
                            regionByCountryData={regionByCountryData}
                            sections={connectionFormData?.formTemplate?.sections}
                            shouldUseNewTranslations={shouldUseNewTranslations}
                            showValidationErrors={wasFormSubmitted}
                        />

                        <div className={classes.actionsBtn}>
                            <Button
                                className={classes.cancelButton}
                                color="primary"
                                data-testid={`${BEM_BLOCK_NAME}--cancel_btn`}
                                designVersion={2}
                                id={`${BEM_BLOCK_NAME}--cancel_btn`}
                                onClick={onCancel}
                                pill
                                variant="outlined"
                            >
                                {shouldUseNewTranslations ? t('common.buttons.cancel') : legacyI18N('Cancel') }
                            </Button>

                            <Button
                                className={classes.button}
                                color="primary"
                                data-testid={`${BEM_BLOCK_NAME}--submit_btn`}
                                designVersion={2}
                                id={`${BEM_BLOCK_NAME}--submit_btn`}
                                onClick={onSubmit}
                                onKeyDown={onSubmitKeyDown}
                                pill
                                tabIndex={0}
                            >
                                {shouldUseNewTranslations ? t('common.buttons.submit') : legacyI18N('Submit') }
                            </Button>
                        </div>
                    </div>
                )
            }

            <MissingRequiredFieldsModal
                hasAnyRequiredFields={
                    !isEmpty(personalPanelRequiredFields) || !isEmpty(requiredFormFields)
                }
                isOpen={isMissingRequiredFieldsModalOpen}
                legacyI18N={legacyI18N}
                onClose={() => { setIsMissingRequiredFieldsModalOpen(false); }}
                shouldUseNewTranslations={shouldUseNewTranslations}
            />

            <SignInRequiredModal
                isOpen={isAuthenticationRequired && isEmpty(accessToken)}
                onRequestSignIn={onRequestSignIn}
            />
        </React.Fragment>
    );
}

ConnectionForm.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionForm);
