/**
 * This enumeration contains the Action Type string constants for Redux for the
 * public-facing Connection Form components.
 *
 * For places where we're using existing Healthy Church client application
 * reducers, it is important to use the same action type constant values that
 * are generated by the shared API Utils
 * (i.e. `[ClassName].ON_[METHOD_NAME_IN_ALL_CAPS_SNAKE_CASE]`).
 *
 * The idea is that public-facing Connection Form components can use Redux
 * either for complete Healthy Church client application, or a stripped-down
 * separate Redux store provided by the Widget front-end that has the same
 * structure, but only the necessary subset.
 */
enum ActionType {
    // App Settings (for metadata for tenant "branding")
    REQUEST_APP_SETTINGS = 'AppSettingsActions.ON_BEFORE_GETAPPSETTINGS',
    RECEIVE_APP_SETTINGS = 'AppSettingsActions.ON_GETAPPSETTINGS',
    // Connection Form Template
    REQUEST_CONNECTION_FORM_TEMPLATE = 'ConnectionForms.Public.REQUEST_CONNECTION_FORM_TEMPLATE',
    RECEIVE_CONNECTION_FORM_TEMPLATE = 'ConnectionForms.Public.RECEIVE_CONNECTION_FORM_TEMPLATE',
    RECEIVE_CONNECTION_FORM_TEMPLATE_ERROR = 'ConnectionForms.Public.RECEIVE_CONNECTION_FORM_TEMPLATE_ERROR',
    // Campus List
    REQUEST_CAMPUS_LIST = 'ConnectionForms.Public.REQUEST_CAMPUS_LIST',
    RECEIVE_CAMPUS_LIST = 'ConnectionForms.Public.RECEIVE_CAMPUS_LIST',
    // Countries List
    REQUEST_COUNTRIES_LIST = 'ConnectionForms.Public.REQUEST_COUNTRIES_LIST',
    RECEIVE_COUNTRIES_LIST = 'ConnectionForms.Public.RECEIVE_COUNTRIES_LIST',
    // Regions List
    REQUEST_REGIONS_LIST = 'ConnectionForms.Public.REQUEST_REGIONS_LIST',
    RECEIVE_REGIONS_LIST = 'ConnectionForms.Public.RECEIVE_REGIONS_LIST',
    // Person Details
    REQUEST_PERSON_DETAILS = 'ConnectionForms.Public.REQUEST_PERSON_DETAILS',
    RECEIVE_PERSON_DETAILS = 'ConnectionForms.Public.RECEIVE_PERSON_DETAILS',
    // Event Occurrence
    REQUEST_EVENT_OCCURRENCE = 'ConnectionForms.Public.REQUEST_EVENT_OCCURRENCE',
    RECEIVE_EVENT_OCCURRENCE = 'ConnectionForms.Public.RECEIVE_EVENT_OCCURRENCE',
    // Worship Service
    REQUEST_WORSHIP_SERVICE = 'ConnectionForms.Public.REQUEST_WORSHIP_SERVICE',
    RECEIVE_WORSHIP_SERVICE = 'ConnectionForms.Public.RECEIVE_WORSHIP_SERVICE',
    // Form Entry Save
    REQUEST_FORM_ENTRY_SAVE = 'ConnectionForms.Public.REQUEST_FORM_ENTRY_SAVE',
    FORM_ENTRY_SAVE_COMPLETED = 'ConnectionForms.Public.FORM_ENTRY_SAVE_COMPLETED',
    FORM_ENTRY_SAVE_ERROR = 'ConnectionForms.Public.FORM_ENTRY_SAVE_ERROR',
    RESET_FORM_STATUS = 'ConnectionForms.Public.RESET_FORM_STATUS',
}

export default ActionType;
