enum ResponseStatus {
    /**
     * 200 OK
     */
    OK = 200,
    /**
     * 400 Bad Request
     */
    BAD_REQUEST = 400,
    /**
     * 401 Unuathorized
     */
    UNAUTHORIZED = 401,
    /**
     * 403 Forbidden
     */
    FORBIDDEN = 403,
    /**
     * 404 Not Found
     */
    NOT_FOUND = 404,
    /**
     * 409 Conflict
     */
    CONFLICT = 409,
    /**
     * 500 Internal Server Error
     */
    INTERNAL_SERVER_ERROR = 500,
}

export default ResponseStatus;
