import React from 'react';
import {
    Icon,
    Image,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import { isNil } from 'lodash';

type PropTypes = {
    /**
     * The icon URL, if we want to customize it.
     */
    customIconUrl?: string;
    /**
     * The HTML text, if we want to customize the content, instead of using `title` and `subTitle` and the default layout.
     */
    customMessageHtml?: string;
    /**
     * Color of icon error/success message
     */
    iconColor?: string;
    /**
     * Icon type for error/success message
     */
    iconType?: string;
    /**
     * Subtitle for error/success message
     */
    subTitle?: string;
    /**
     * title for error/success message
     */
    title?: string;
    /**
     * Show message at the bottom of the page
     * FIXME: Temporary code to change the way the form renders for a campaign,
     * remove this and all related code when not longer needed
     * see: https://dev.azure.com/saddlebackchurch/Church%20Management/_workitems/edit/87959
     * and: https://dev.azure.com/saddlebackchurch/Church Management/_workitems/edit/87960
     */
    displayAtTheBottom?: boolean;
};

export const defaultProps: Pick<PropTypes, 'iconColor' | 'iconType' | 'subTitle' | 'title' | 'displayAtTheBottom'> = {
    iconColor: 'warning',
    iconType: 'info',
    subTitle: 'Oops! Something’s gone wrong on our end. Come back in a bit or try refreshing the page.',
    title: 'Error',
    displayAtTheBottom: false,
};

const useStyles = makeStyles(({
    palette,
    spacing,
    typography,
}) => ({
    container: {
        alignItems: ({ displayAtTheBottom }) => (displayAtTheBottom ? 'flex-end' : 'center'),
        display: 'flex',
        height: '100vh',
    },
    iconIndicator: {
        justifyContent: 'center',
        marginBottom: spacing(2),
        width: '100% !important',
    },
    title: {
        color: palette.text.primary,
        display: 'inline-flex',
        fontSize: '20px',
        fontWeight: typography.fontWeightBold,
        justifyContent: 'center',
        lineHeight: '20px',
        marginBottom: spacing(1),
    },
    subTitle: {
        color: palette.text.secondary,
        display: 'inline-flex',
        fontSize: '16px',
        fontWeight: typography.fontWeightMedium,
        justifyContent: 'center',
        lineHeight: '20px',
        marginBottom: spacing(5),
        textAlign: 'center',
    },
    statusWrapper: {
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        padding: spacing(2),
        width: 326,
    },
}));

export const BEM_BLOCK_NAME = 'connection_form_status';

function ConnectionFormStatus(props: PropTypes) {
    const {
        displayAtTheBottom,
        iconColor,
        iconType,
        customIconUrl,
        customMessageHtml,
        subTitle,
        title,
    } = props;

    const classes = useStyles({ displayAtTheBottom });
    const overrideIcon = !isNil(customIconUrl);
    const overrideMessage = !isNil(customMessageHtml);

    return (
        <div
            className={classes.container}
            id={`${BEM_BLOCK_NAME}--confirmation_container`}
        >
            <div
                className={classes.statusWrapper}
                id={`${BEM_BLOCK_NAME}--confirmation_wrapper`}
            >
                {overrideIcon ? (
                    <Image
                        className={classes.iconIndicator}
                        id={`${BEM_BLOCK_NAME}--confirmation_image`}
                        name="Status"
                        size={64}
                        src={customIconUrl}
                        suppressBackgroundImage
                    />
                ) : (
                    <Icon
                        className={classes.iconIndicator}
                        color={iconColor}
                        id={`${BEM_BLOCK_NAME}--confirmation_icon`}
                        size={64}
                        title="Status"
                        type={iconType}
                    />
                )}

                {overrideMessage ? (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: customMessageHtml }}
                        id={`${BEM_BLOCK_NAME}--confirmation_message`}
                    />
                ) : (
                    <div
                        className={classes.title}
                        id={`${BEM_BLOCK_NAME}--confirmation_title`}
                    >
                        {title}
                    </div>
                )}

                {subTitle && !overrideMessage && (
                    <div
                        className={classes.subTitle}
                        id={`${BEM_BLOCK_NAME}--confirmation_subtitle`}
                    >
                        {subTitle}
                    </div>
                )}
            </div>
        </div>
    );
}

ConnectionFormStatus.defaultProps = defaultProps;

export default ConnectionFormStatus;
