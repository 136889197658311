enum WidgetType {
    ConnectionForm = 'connection-form',
    GivingCampaignCommitment = 'giving-campaign-commitment',
    GivingCampaignDetails = 'giving-campaign-details',
    GivingCheckout = 'giving-checkout',
    GivingDesignationDetails = 'giving-designation-details',
    GivingHistory = 'giving-history',
    GivingMyCommitments = 'giving-my-commitments',
    GivingOpportunities = 'giving-opportunities',
    GivingMyScheduledAndRecurring = 'giving-my-scheduled-and-recurring',
    SavedPaymentMethods = 'saved-payment-methods',
    DigitalProgram = 'digital-program',
}

export default WidgetType;
