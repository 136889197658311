import {
    filter,
    includes,
    isNil,
    isNull,
    memoize,
} from 'lodash';
import {
    Campus,
    CampusSelectOption,
    CampusType,
} from './models';
import {
    DOES_NOT_ATTEND_CHURCH_ENTITY,
    UNKNOWN_CHURCH_ENTITY,
} from './constants.js';
import churchCampusSelectTransform from './reducers/churchCampusSelectTransform';
import IChurchCampusStore from './churchCampusStore.interface';

const CAMPUS_TYPES_EXCLUDING_EXTENSIONS: CampusType[] = [
    CampusType.MainCampus,
    CampusType.RegionalCampus,
    CampusType.OnlineCampus,
];

export const DOES_NOT_ATTEND_OPTION: CampusSelectOption = {
    label: 'Does Not Attend',
    value: DOES_NOT_ATTEND_CHURCH_ENTITY,
    region: 'N/A',
    country: 'N/A',
    timeZone: null,
    disabled: false,
    gradeSchoolYear: null,
};

export const UNKNOWN_OPTION: CampusSelectOption = {
    label: 'Unknown',
    value: UNKNOWN_CHURCH_ENTITY,
    region: 'N/A',
    country: 'N/A',
    timeZone: null,
    disabled: false,
    gradeSchoolYear: null,
};

/*
 * This 'store' encapsulates to global list of Campuses ('Church Entities'),
 * transformed to a useful shape for Select/Dropdown component options.
 * it provides an interface for requesting variations of the list per the
 * contextually specific needs of the callsite.
 */
export default class ChurchCampusStore implements IChurchCampusStore {
    #campuses = [];

    static includeNullOptions(campusSelectOptions) {
        return [
            ...campusSelectOptions,
            { ...UNKNOWN_OPTION },
            { ...DOES_NOT_ATTEND_OPTION },
        ];
    }

    constructor(campuses: Campus[] = []) {
        this.#campuses = isNil(campuses) ? [] : [...campuses];

        this.getAllCampuses = memoize(this.getAllCampuses, (...args) => `${args}`);

        this.getAllCampusesForSelect = memoize(
            this.getAllCampusesForSelect,
            (params) => JSON.stringify(params),
        );
    }

    getAllCampuses(
        includeRoot: boolean = false,
        excludeExtensions: boolean = false,
        includeInactive: boolean = false,
    ): Campus[] {
        const shouldIncludeCampus = (c) => {
            if (!includeRoot && isNull(c.parentEntityId)) {
                return false;
            }

            if (excludeExtensions &&
                !includes(
                    CAMPUS_TYPES_EXCLUDING_EXTENSIONS,
                    c.churchEntityTypeId,
                )
            ) {
                return false;
            }

            if (!includeInactive && !c.isActive) {
                return false;
            }

            return true;
        };

        return filter(this.#campuses, shouldIncludeCampus);
    }

    getAllCampusesForSelect(params?: {
        includeRoot?: boolean;
        includeNulls?: boolean;
        excludeExtensions?: boolean;
        includeInactive?: boolean;
    }): CampusSelectOption[] {
        const {
            includeRoot = false,
            includeNulls = false,
            excludeExtensions = false,
            includeInactive = false,
        } = params ?? {};

        let campusSelectOptions = churchCampusSelectTransform(
            this.getAllCampuses(includeRoot, excludeExtensions, includeInactive),
        );

        if (includeNulls) {
            campusSelectOptions = ChurchCampusStore.includeNullOptions(campusSelectOptions);
        }

        return campusSelectOptions;
    }
}
