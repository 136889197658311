import {
    isNil,
} from 'lodash';
import {
    applyMiddleware,
    createStore,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import multi from 'redux-multi';

const middlewares = [thunkMiddleware, multi];
const enhancer = applyMiddleware(...middlewares);

export default function configureReduxStore(rootReducer) {
    return isNil(rootReducer) ?
        null :
        createStore(rootReducer, enhancer);
}
