import {
    find,
    forEach,
    isEmpty,
    isNil,
    map,
} from 'lodash';
import {
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import React from 'react';
import AgreementEntry from './models/agreementEntry.model';
import AnswerModel from './models/answer.model';
import ConnectionFormSectionModel from './models/connectionFormSection.model';
import ConnectionFormAgreement from './connectionFormAgreement';
import ConnectionFormField from './connectionFormField';
import ConnectionFormContainerOrField from './models/connectionFormContainerOrField.model';
import ContainerOrFieldType from './models/containerOrFieldType.model';
import { getAnswer } from './utils';

type PropTypes = {
    /**
     * Array of agreement entries
     */
    agreements: AgreementEntry[];
    /**
     * Array of questions answer
     */
    answers: AnswerModel[];
    /**
     * Function to update agreement acceptance
     */
    onChangeAgreementConsent: (field: ConnectionFormContainerOrField, consented: boolean) => void;
    /**
     * Function updates question answers
     */
    onFieldChanged: Function;

    /**
     * Connection form section which hold question fields/containers
     */
    section: ConnectionFormSectionModel;

    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    container: {
        marginTop: spacing(2),
        padding: `${spacing(1)}px 0`,
    },
    sectionTitle: {
        marginBottom: 0,
    },
    sectionInstructions: {
        marginLeft: 0,
        marginTop: 0,
        marginBottom: `${spacing(1)}px 0`,
    },
}));

function ConnectionFormSection({
    agreements,
    answers,
    onChangeAgreementConsent,
    onFieldChanged,
    section,
    showValidationErrors,
}: PropTypes) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Typography
                className={classes.sectionTitle}
                variant="h3"
            >
                {section.name}
            </Typography>

            {
                section?.instructions && (
                    <div
                        className={classes.sectionInstructions}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: section.instructions,
                        }}
                    />
                )
            }

            {map(section.fields, (field) => {
                const {
                    expectedAnswerType,
                    fields: containerFields,
                    questionId,
                    type: containerOrField,
                } = field;

                let agreementConsent = null;
                let answer = null;
                const groupedCheckboxAnswers = [];
                const isContainer = containerOrField === ContainerOrFieldType.Container;

                const answerInfo = find(
                    answers,
                    (item) => item.questionId === questionId,
                );

                if (!isNil(answerInfo)) {
                    answer = getAnswer(answerInfo, expectedAnswerType);
                }

                const agreementInfo = find(
                    agreements,
                    (item) => item.agreementId === field?.agreement?.id,
                );

                if (!isNil(agreementInfo)) {
                    agreementConsent = agreementInfo?.agreementConsented;
                }

                // "Containers" (i.e. Grouped Checkboxes) could have multiple Answers
                // (one for each Question within the Container)
                if (isContainer) {
                    forEach(containerFields, (containerField) => {
                        const matchedGroupCheckboxAnswerInfo = find(
                            answers,
                            (a) => a.questionId === containerField.questionId,
                        );

                        if (!isNil(matchedGroupCheckboxAnswerInfo)) {
                            groupedCheckboxAnswers.push(matchedGroupCheckboxAnswerInfo);
                        }
                    });
                }

                return !questionId && !isEmpty(field?.agreement) ? (
                    <ConnectionFormAgreement
                        agreementConsent={agreementConsent}
                        field={field}
                        onChangeAgreementConsent={onChangeAgreementConsent}
                        showValidationErrors={showValidationErrors}
                    />
                ) : (
                    <ConnectionFormField
                        answer={isContainer ? groupedCheckboxAnswers : answer}
                        field={field}
                        onFieldChanged={onFieldChanged}
                        showValidationErrors={showValidationErrors}
                    />
                );
            })}
        </div>
    );
}

export default ConnectionFormSection;
