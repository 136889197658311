import React, { useCallback } from 'react';
import {
    Button,
    Modal,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import ClassNames from 'classnames';
import {
    isEmpty,
    isFunction,
    isString,
} from 'lodash';
import { useTranslation } from 'react-i18next';
import { BEM_BLOCK_NAME } from './constants';

type PropTypes = {
    isOpen: boolean;

    /**
     * Handler for clicking the button, which should
     * redirect user to the login page (and return them to the URL hosting the form).
     *
     * Can be an ordinary function or can externally defined JavaScript
     * (to help widget consumers handle this event appropriately for their website).
     */
    onRequestSignIn?: (() => void) | string;
};

const useStyles = makeStyles(({
    spacing,
}) => ({
    button: {
        marginTop: spacing(2),
    },
    modalMargin: {
        margin: [[spacing(5), spacing(4), 0]],
    },
    modalTitle: {
        marginTop: spacing(2),
    },
    modalContent: {
        marginTop: spacing(1),
    },
}));

function SignInRequiredModal({
    isOpen,
    onRequestSignIn,
}: PropTypes) {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleSignIn = useCallback(() => {
        if (isFunction(onRequestSignIn)) {
            onRequestSignIn();
            return;
        }

        // Deliberately using `eval` here and suppressing the warning.
        // This allows widget consumers to define the handling for this event
        // externally and enable us to call their code.
        if (isString(onRequestSignIn) && !isEmpty(onRequestSignIn)) {
            const callback = eval(onRequestSignIn); // eslint-disable-line no-eval
            callback();
            return;
        }

        console.warn('Healthy Church Forms Widget: No event handler for button in the Sign In Required modal');
    }, [onRequestSignIn]);

    return (
        <Modal
            isOpen={isOpen}
        >
            <Modal.Content>
                <Typography
                    align="center"
                    className={classes.modalTitle}
                    color="textSecondary"
                    variant="h2"
                >
                    {t('signInRequiredModal.title')}
                </Typography>

                <Typography
                    align="center"
                    className={classes.modalContent}
                    color="textSecondary"
                    variant="h3"
                >
                    {t('signInRequiredModal.body')}
                </Typography>

                <Button
                    className={ClassNames(`${BEM_BLOCK_NAME}--sign_in_required_accept_button`, classes.button)}
                    designVersion={2}
                    id={`${BEM_BLOCK_NAME}--sign_in_required_accept_button`}
                    onClick={handleSignIn}
                    tabIndex={0}

                >
                    {t('signInRequiredModal.cta')}
                </Button>
            </Modal.Content>
        </Modal>
    );
}

export default SignInRequiredModal;
