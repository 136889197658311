import i18nMessagesStore from './i18nMessagesStore.js';

export function getMessage(path, optionalMessages) {
    if (!path) {
        return '';
    }

    const messages = optionalMessages || i18nMessagesStore.getMessages();
    const pathParts = path.split('.');
    let message;

    try {
        message = pathParts.reduce((obj, pathPart) => obj[pathPart], messages);
    } finally {
        if (message === undefined) {
            message = pathParts[pathParts.length - 1];
        }
    }

    return message;
}

export function translationFactory(namespace) {
    return (key, optionalMessages) => {
        if (!key) {
            return '';
        }

        if (typeof namespace === 'undefined' ||
            !namespace ||
            key.indexOf(' ') >= 0) {
            return key;
        }

        if (optionalMessages !== null) {
            return getMessage(`${namespace}.${key}`, optionalMessages);
        }

        return getMessage(`${namespace}.${key}`);
    };
}

export default { getMessage, translationFactory };
