import { isNil } from 'lodash';
import ActionType from './connectionForm.actionTypes';
import Country from './models/country.model';

export type CountriesListState = {
    data: Country[];
    isFetchingCountriesList: boolean;
};

export const DEFAULT_STATE: CountriesListState = {
    data: [],
    isFetchingCountriesList: false,
};

interface RequestCountriesListAction {
    type: ActionType.REQUEST_COUNTRIES_LIST;
}

interface ReceiveCountriesListAction {
    type: ActionType.RECEIVE_COUNTRIES_LIST;
    result: Country[];
}

type KnownActionType = RequestCountriesListAction | ReceiveCountriesListAction;

export default (
    state: CountriesListState | undefined,
    incomingAction: any,
): CountriesListState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_COUNTRIES_LIST:
                return {
                    ...state,
                    isFetchingCountriesList: true,
                };

            case ActionType.RECEIVE_COUNTRIES_LIST:
            {
                const specificAction = action as ReceiveCountriesListAction;

                return {
                    ...state,
                    data: specificAction.result,
                    isFetchingCountriesList: false,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
