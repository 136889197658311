/**
 * Follows 0-6 Sunday-Saturday convention, _not_ ISO standard
 * so that we can use these for MomentJS calculations
 */
enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
}

export default DayOfWeek;
