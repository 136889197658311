import React from 'react';
import { isNil } from 'lodash';
import i18next, { setupI18n } from '../../src/global/i18next';
import {
    createWidgetLoader,
    getHealthyChurchEnvironment,
    getWidgetConsumerParams,
} from './utils';
import configureReduxStore from './widgetReduxSetup';
import WidgetWrapper from './widgetWrapper';

const loadWidgetsIntoHtmlConsumers = ({
    App,
    widgetType,
    generateClassName,
    rootReducer = null,
    i18nResources = undefined,
}) => {
    const widgetHtmlConsumers = document.querySelectorAll(`[data-hc-widget-type="${widgetType}"]`);
    // eslint-disable-next-line no-console
    console.log(`Healthy Church Widgets: For widget type '${widgetType}' found ${widgetHtmlConsumers.length} HTML DOM element targets`);
    const hcEnvironment = getHealthyChurchEnvironment();

    if (isNil(hcEnvironment)) {
        throw new Error('Healthy Church Widgets: Unable to determine HC environment for API Configuration');
    }

    const isUsingI18n = !isNil(i18nResources);
    const browserLanguage = navigator.language;

    if (isUsingI18n) {
        // eslint-disable-next-line no-console
        console.log('Healthy Church Widgets: Setting up i18n (internationalization / localization / translations) ...');

        setupI18n(i18nResources);

        // eslint-disable-next-line no-console
        console.log('Healthy Church Widgets: Supported languages w/ current selection:', i18next.languages);

        // eslint-disable-next-line no-console
        console.log('Healthy Church Widgets: Browser language is:', browserLanguage);
    }

    Array.from(widgetHtmlConsumers).forEach((widgetHtmlConsumer) => {
        const reduxStore = configureReduxStore(rootReducer);
        const hasReduxStore = !isNil(reduxStore);
        const widgetizedComponentProps = getWidgetConsumerParams(widgetHtmlConsumer);

        const WidgetLoader = createWidgetLoader(widgetHtmlConsumer);

        WidgetLoader.render(
            <WidgetWrapper
                environment={hcEnvironment}
                generateClassName={generateClassName}
                reduxStore={reduxStore}
                withReduxStore={hasReduxStore}
            >
                <App
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...widgetizedComponentProps}
                />
            </WidgetWrapper>,
        );
    });
};

export default loadWidgetsIntoHtmlConsumers;
