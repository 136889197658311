import { isNil } from 'lodash';
import ActionType from './connectionForm.actionTypes';
import WorshipService from './models/worshipService.model';

export type WorshipServiceState = {
    data: WorshipService | null;
    isFetchingWorshipService: boolean;
};

export const DEFAULT_STATE: WorshipServiceState = {
    data: null,
    isFetchingWorshipService: false,
};

interface RequestWorshipServiceAction {
    type: ActionType.REQUEST_WORSHIP_SERVICE;
}

interface ReceiveWorshipServiceAction {
    type: ActionType.RECEIVE_WORSHIP_SERVICE;
    result: WorshipService;
}

type KnownActionType = RequestWorshipServiceAction | ReceiveWorshipServiceAction;

export default (
    state: WorshipServiceState | undefined,
    incomingAction: any,
): WorshipServiceState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_WORSHIP_SERVICE:
                return {
                    ...state,
                    isFetchingWorshipService: true,
                };

            case ActionType.RECEIVE_WORSHIP_SERVICE:
            {
                const specificAction = action as ReceiveWorshipServiceAction;

                return {
                    ...state,
                    data: specificAction.result,
                    isFetchingWorshipService: false,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
