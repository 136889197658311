import { isNil } from 'lodash';
import ChurchCampusStore from '../../global/churchCampusStore';
import {
    Campus,
    CampusSelectOption,
} from '../../global/models';
import ActionType from './connectionForm.actionTypes';
import { UNKNOWN_CHURCH_ENTITY } from './constants';

export type CampusListState = {
    campusSelectOptions: CampusSelectOption[];
    data: Campus[];
    isFetchingCampusList: boolean;
};

export const DEFAULT_STATE: CampusListState = {
    campusSelectOptions: [],
    data: [],
    isFetchingCampusList: false,
};

export interface RequestCampusListAction {
    type: ActionType.REQUEST_CAMPUS_LIST;
}

export interface ReceiveCampusListAction {
    type: ActionType.RECEIVE_CAMPUS_LIST;
    result: Campus[];
}

type KnownActionType = RequestCampusListAction | ReceiveCampusListAction;

export default (
    state: CampusListState | undefined,
    incomingAction: any,
): CampusListState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const action = incomingAction as KnownActionType;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.REQUEST_CAMPUS_LIST:
                return {
                    ...state,
                    isFetchingCampusList: true,
                };

            case ActionType.RECEIVE_CAMPUS_LIST:
            {
                const campusStore = new ChurchCampusStore(action.result as Campus[]);
                const campusList = campusStore.getAllCampuses(false, true);
                const campusSelectOptions = campusStore
                    .getAllCampusesForSelect({
                        includeNulls: true,
                        excludeExtensions: true,
                    })
                    .filter((campus: CampusSelectOption) => campus.value !== UNKNOWN_CHURCH_ENTITY);

                return {
                    ...state,
                    campusSelectOptions,
                    data: campusList,
                    isFetchingCampusList: false,
                };
            }

            default:
                return state;
        }
    }

    return state;
};
