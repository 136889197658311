import React from 'react';
import {
    Checkbox,
    DatePickerInput,
    Input,
    Grid,
    Radio,
    Select,
    TextArea,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import ClassNames from 'classnames';
import {
    find,
    isEmpty,
    map,
} from 'lodash';
import moment from 'moment-timezone';
import { QuestionType } from '../../connectionQuestions/global/models';
import Utils from '../../global/utils/utils.js';
import ConnectionFormContainerOrField from './models/connectionFormContainerOrField.model';
import ContainerOrFieldType from './models/containerOrFieldType.model';

type PropTypes = {
    /**
     * Answer to question
     */
    answer: any;
    /**
     * Function updates question answers
     */
    onFieldChanged: Function;

    /**
     * Determines connection form question
     */
    field: ConnectionFormContainerOrField;

    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;
};

const useStyles = makeStyles(({
    palette,
    spacing,
    typography,
}) => ({
    gridColumn: {
        paddingBottom: spacing(1.5),
        paddingTop: spacing(1.5),
    },
    checkbox: {
        '&.checkbox-required .checkbox-label-text::after': {
            color: palette.error.main,
            content: '"*"',
            marginLeft: 2,
        },
    },
    checkboxWrapper: {
        marginTop: spacing(1.5),
        marginBottom: spacing(1.5),
    },
    errorMessage: {
        color: palette.error.main,
        fontSize: typography.pxToRem(14),
        marginTop: spacing(0.5),
    },
    label: {
        marginBottom: spacing(0.5),
    },
    labelColor: {
        '& label, & p': {
            color: palette.grey[500],
        },
    },
    labelWrapper: {
        display: 'inline-flex',
    },
    multipleChoiceOption: {
        paddingBottom: spacing(0.5),
        paddingTop: spacing(0.5),
    },
    required: {
        marginLeft: 2,
        color: palette.error.main,
    },
}));

function ConnectionFormField({
    answer,
    onFieldChanged,
    field,
    showValidationErrors,
}: PropTypes) {
    const classes = useStyles();
    let content;

    const isContainer = field.type === ContainerOrFieldType.Container;
    let questionType = field?.questionType;
    let answerType = field?.expectedAnswerType;
    const titleTrimmed = field.title.replace(/\s+/, '');

    const fieldId = isContainer ?
        `connection_form_public--question_container_${titleTrimmed}` :
        `connection_form_public--question_field_${field.questionId ?? field.agreement?.uniqueId}`;

    const onChangeQuestionAnswer = (value, checked = false) => {
        let selectedValue = value;
        let groupCheckboxValue = null;

        if (isContainer) {
            const containerQuestion = find(field.fields, (item) => item.questionId === value);
            questionType = containerQuestion?.questionType;
            answerType = containerQuestion?.expectedAnswerType;
        }

        if (questionType === QuestionType.DropDown) {
            selectedValue = value.value;
        } else if (questionType === QuestionType.Checkbox) {
            selectedValue = checked;

            if (isContainer) {
                groupCheckboxValue = value;
            }
        } else if (questionType === QuestionType.Date) {
            selectedValue = value.date.format('YYYY-MM-DD');
        } else if (!questionType && !isEmpty(field.agreement)) {
            selectedValue = checked;
        }

        onFieldChanged(field, selectedValue, answerType, groupCheckboxValue);
    };

    if (questionType === QuestionType.SingleLineText) {
        content = (
            <Grid.Column
                className={classes.gridColumn}
                sm={12}
            >
                <Input
                    className={classes.labelColor}
                    error={showValidationErrors && field.isRequired && !answer ? 'Required' : undefined}
                    fluid
                    id={fieldId}
                    label={field.title}
                    onChange={onChangeQuestionAnswer}
                    placeholder={field.placeholder || ''}
                    required={field.isRequired}
                    tabIndex={0}
                    type="text"
                    value={answer}
                />
            </Grid.Column>
        );
    } else if (questionType === QuestionType.ParagraphText) {
        content = (
            <Grid.Column
                className={classes.gridColumn}
                sm={12}
            >
                <TextArea
                    autoHeight
                    className={classes.labelColor}
                    error={showValidationErrors && field.isRequired && !answer ? 'Required' : undefined}
                    fluid
                    id={fieldId}
                    label={field.title}
                    onChange={onChangeQuestionAnswer}
                    placeholder={field.placeholder || ''}
                    required={field.isRequired}
                    tabIndex={0}
                    value={answer}
                />
            </Grid.Column>
        );
    } else if (questionType === QuestionType.Date) {
        let momentValue;

        if (answer && !moment.isMoment(answer)) {
            momentValue = moment(answer).utc();
        }

        content = (
            <Grid.Column
                className={classes.gridColumn}
                md={12}
                sm={12}
            >
                <DatePickerInput
                    className={classes.labelColor}
                    date={momentValue}
                    error={showValidationErrors && field.isRequired && !momentValue ? 'Required' : undefined}
                    fluid
                    id={fieldId}
                    label={field.title}
                    onChange={onChangeQuestionAnswer}
                    placeholder={field.placeholder || ''}
                    required={field.isRequired}
                    tabIndex={0}
                />
            </Grid.Column>
        );
    } else if (questionType === QuestionType.MultipleChoice) {
        const multipleChoiceLabelJSX = (
            <div className={`${classes.labelWrapper} ${classes.labelColor}`}>
                <Typography
                    className={classes.label}
                    color="textSecondary"
                    variant="body2"
                >
                    {field.title}
                </Typography>
                {field.isRequired && (
                    <span className={classes.required}> *</span>
                )}
            </div>
        );

        const multipleChoiceJSX = map(field.answerChoices, (choice) => (
            <Radio
                checked={choice.id === answer}
                className={classes.multipleChoiceOption}
                fluid
                id={choice.id}
                label={choice.label}
                onChange={onChangeQuestionAnswer}
                tabIndex={0}
                value={answer}
            />
        ));

        content = (
            <Grid.Column
                className={classes.gridColumn}
                sm={12}
            >
                {multipleChoiceLabelJSX}
                {multipleChoiceJSX}
                {showValidationErrors && field.isRequired && !answer && (
                    <p className={classes.errorMessage}>Required</p>
                )}
            </Grid.Column>
        );
    } else if (questionType === QuestionType.Checkbox || isContainer) {
        const hasPlaceholder = !Utils.isStringNullOrWhiteSpace(field.placeholder);

        const checkboxLabelJSX = (
            <div className={`${classes.labelWrapper} ${classes.labelColor}`}>
                <Typography
                    className={classes.label}
                    color="textSecondary"
                    variant="body2"
                >
                    {!isContainer && hasPlaceholder ? field.placeholder : field.title}
                </Typography>
                {field.isRequired && (
                    <span className={classes.required}> *</span>
                )}
            </div>
        );

        let groupCheckboxJSX;
        let singleCheckboxJSX;

        if (isContainer) {
            groupCheckboxJSX = map(field.fields, (item) => {
                const answerMatchedInfo = find(answer, (a) => a.questionId === item.questionId);

                const groupCheckboxClasses = ClassNames(
                    classes.checkbox,
                    {
                        'checkbox-required': item.isRequired,
                    },
                );

                return (
                    <Grid.Column
                        className={classes.multipleChoiceOption}
                        sm={12}
                    >
                        <Checkbox
                            checked={answerMatchedInfo?.booleanValue ?? false}
                            className={groupCheckboxClasses}
                            fluid
                            id={item.questionId}
                            key={`checkbox--${item.questionId}`}
                            label={item.title}
                            onChange={onChangeQuestionAnswer}
                            tabIndex={0}
                        />
                    </Grid.Column>
                );
            });
        } else {
            const checkboxClasses = ClassNames(
                classes.checkbox,
                {
                    'checkbox-required': field.isRequired && !hasPlaceholder,
                },
            );

            singleCheckboxJSX = (
                <Grid.Column
                    className={classes.multipleChoiceOption}
                    sm={12}
                >
                    <Checkbox
                        checked={answer}
                        className={checkboxClasses}
                        fluid
                        id={fieldId}
                        label={field.title}
                        onChange={onChangeQuestionAnswer}
                        tabIndex={0}
                    />
                </Grid.Column>
            );
        }

        content = (
            <div className={classes.checkboxWrapper}>
                {(isContainer || hasPlaceholder) ? checkboxLabelJSX : null}
                {
                    isContainer ?
                        groupCheckboxJSX :
                        singleCheckboxJSX
                }
                {showValidationErrors && field.isRequired && !answer && (
                    <p className={classes.errorMessage}>Required</p>
                )}
            </div>
        );
    } else if (questionType === QuestionType.DropDown) {
        const options = map(field.answerChoices, (choice) => ({
            label: choice.label,
            value: choice.id,
        }));

        content = (
            <Grid.Column
                className={classes.gridColumn}
                sm={12}
            >
                <Select
                    className={classes.labelColor}
                    error={showValidationErrors && field.isRequired && !answer ? 'Required' : undefined}
                    fluid
                    id={fieldId}
                    label={field.title}
                    onChange={onChangeQuestionAnswer}
                    options={options}
                    placeholder={field.placeholder || 'Select'}
                    required={field.isRequired}
                    searchable
                    selection
                    selectionRequired={field.isRequired}
                    tabIndex={0}
                    value={answer}
                />
            </Grid.Column>
        );
    } else if (!questionType && !isEmpty(field.agreement)) {
        content = (
            <React.Fragment>
                <Grid.Column
                    className={classes.gridColumn}
                    sm={12}
                >
                    <Typography
                        variant="body"
                    >
                        {/* eslint-disable react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: field.agreement?.content }} />
                        {/* eslint-enable react/no-danger */}
                    </Typography>
                </Grid.Column>
                <Grid.Column
                    className={classes.gridColumn}
                    sm={12}
                >
                    <Checkbox
                        checked={answer}
                        fluid
                        id={fieldId}
                        label={field.agreement?.acceptance}
                        onChange={onChangeQuestionAnswer}
                        required={field.isRequired}
                        tabIndex={0}
                    />
                </Grid.Column>
            </React.Fragment>
        );
    }

    return (
        <div>
            {content}
        </div>
    );
}

export default ConnectionFormField;
