import { AxiosResponse } from 'axios';
import { isEmpty } from 'lodash';
import { ServiceType } from '../../global/api/models';
import {
    getApiHost,
    serviceRequest,
} from '../../global/api/public/utils';
import {
    AppSettings,
    Campus,
    Country,
    Region,
} from '../../global/models';
import { Person } from '../../people/global/models';
import ConnectionFormEntry from './models/connectionFormEntry.model';
import ConnectionFormTemplate from './models/connectionFormTemplate.model';
import EventOccurrence from './models/eventOccurrence.model';
import WorshipService from './models/worshipService.model';

export const getAppSettings = async (): Promise<AppSettings> => {
    const { data } = await serviceRequest({
        method: 'GET',
        url: `${getApiHost(ServiceType.Core)}/api/app-settings`,
    });

    return data;
};

export const getCampusList = async (): Promise<Campus[]> => {
    const { data } = await serviceRequest({
        method: 'GET',
        url: `${getApiHost(ServiceType.Core)}/api/churchentity`,
    });

    return data as Campus[];
};

export const getConnectionFormTemplate =
    (formTemplateId: number): Promise<AxiosResponse<ConnectionFormTemplate, any>> => serviceRequest({ // eslint-disable-line max-len
        method: 'GET',
        url: `${getApiHost(ServiceType.Core)}/api/connection-form-public/template/${formTemplateId}`,
    });

export const getCountryList = async (locale?: string): Promise<Country[]> => {
    let url = `${getApiHost(ServiceType.Core)}/api/v2/countries`;

    if (!isEmpty(locale)) {
        url += `?culture=${locale}`;
    }

    const { data } = await serviceRequest({
        method: 'GET',
        url,
    });

    return data as Country[];
};

export const getEventOccurrences = async (
    dateRangeStart: string,
    dateRangeEnd: string,
    eventId: number,
): Promise<EventOccurrence[]> => {
    const { data } = await serviceRequest({
        method: 'GET',
        url: `${getApiHost(ServiceType.Events)}/api/v2/event-public/${eventId}/occurrences?startDate=${dateRangeStart}&endDate=${dateRangeEnd}`,
    });

    return data as EventOccurrence[];
};

export const getPersonInfo = async (personId: number, accessToken: string): Promise<Person> => {
    const { data } = await serviceRequest({
        accessToken,
        method: 'GET',
        url: `${getApiHost(ServiceType.Core)}/api/v2/person/${personId}?suppressMergeRedirect=true&expand=address,email,phone`,
    });

    return data as Person;
};

export const getRegionsByCountry = async (
    countryId: string,
    locale?: string,
): Promise<Region[]> => {
    let url = `${getApiHost(ServiceType.Core)}/api/v2/regions/by-country/${countryId}`;

    if (!isEmpty(locale)) {
        url += `?culture=${locale}`;
    }

    const { data } = await serviceRequest({
        method: 'GET',
        url,
    });

    return data as Region[];
};

export const getWorshipServices = async (
    churchEntityId: number,
    dateRangeStart: string,
    dateRangeEnd: string,
): Promise<WorshipService[]> => {
    const { data } = await serviceRequest({
        method: 'GET',
        url: `${getApiHost(ServiceType.Events)}/api/v2/event-public/worship-service-occurrences?churchEntityId=${churchEntityId}&startDate=${dateRangeStart}&endDate=${dateRangeEnd}`,
    });

    return data as WorshipService[];
};

export const saveConnectionFormEntry = (
    accessToken: string | null,
    data: ConnectionFormEntry,
): Promise<AxiosResponse<ConnectionFormEntry, any>> => serviceRequest({
    accessToken,
    data,
    method: 'POST',
    url: `${getApiHost(ServiceType.Core)}/api/connection-form-public/entry`,
});
