import {
    includes,
    isEmpty,
    isNil,
} from 'lodash';
import validator from 'validator';
import PhoneType from '../../global/models/phoneType.model';
import PhoneTypeId from '../../global/models/phoneTypeId.model';
import ActionType from './contactForm.actionTypes';

export type Address = {
    address1: string;
    address2: string;
    city: string;
    isAddressValid: boolean;
    isPostalCodeValid: boolean;
    postalCode: string;
    isPostalCodeRequired: boolean;
    country: string;
    countryAlpha2: string;
    countryAlpha3: string;
    region: string;
    regionCode: string;
};

export type Email = {
    value: string;
    isEmailValid: boolean;
};

export type Phone = {
    countryCode: string;
    displayPhoneNumber: string;
    extension: number;
    isHome: boolean;
    isMobile: boolean;
    isPhoneValid: boolean;
    isWork: boolean;
    phoneNumber: string;
    phoneType: string;
    phoneTypeId: PhoneTypeId;
};

export type ContactFormState = {
    address: Address;
    email: Email;
    phone: Phone;
};

export const DEFAULT_STATE: ContactFormState = {
    address: {
        address1: '',
        address2: '',
        city: '',
        isAddressValid: false,
        isPostalCodeValid: false,
        postalCode: '',
        isPostalCodeRequired: true,
        country: 'United States of America',
        countryAlpha2: 'US',
        countryAlpha3: 'USA',
        region: '',
        regionCode: '',
    },
    email: {
        value: '',
        isEmailValid: false,
    },
    phone: {
        countryCode: '',
        displayPhoneNumber: '',
        extension: null,
        isHome: true,
        isMobile: false,
        isPhoneValid: false,
        isWork: false,
        phoneNumber: null,
        phoneType: PhoneType.Home,
        phoneTypeId: PhoneTypeId.Home,
    },
};

export default (
    state: ContactFormState | undefined,
    action: any,
): ContactFormState => {
    if (state === undefined) {
        return DEFAULT_STATE;
    }

    const {
        address,
    } = state;

    const {
        address1,
        city,
        country,
        countryAlpha2,
        postalCode,
        isPostalCodeRequired,
        regionCode,
    } = address;

    if (!isNil(action)) {
        switch (action.type) {
            case ActionType.RESET_DATA: {
                return DEFAULT_STATE;
            }

            case ActionType.SET_ADDRESS1: {
                const isAddressValid = !!city &&
                    !!country &&
                    (
                        !isPostalCodeRequired || !!postalCode
                    ) &&
                    !!regionCode &&
                    !!action.result;

                return {
                    ...state,
                    address: {
                        ...state.address,
                        address1: action.result,
                        isAddressValid,
                    },
                };
            }

            case ActionType.SET_ADDRESS2: {
                return {
                    ...state,
                    address: {
                        ...state.address,
                        address2: action.result,
                    },
                };
            }

            case ActionType.SET_CITY: {
                const isAddressValid = !!address1 &&
                    !!country &&
                    (
                        !isPostalCodeRequired || !!postalCode
                    ) &&
                    !!regionCode &&
                    !!action.result;

                return {
                    ...state,
                    address: {
                        ...state.address,
                        city: action.result,
                        isAddressValid,
                    },
                };
            }

            case ActionType.SET_CONTACT_DATA: {
                const {
                    address: newAddressvalue = {},
                    email: newEmailValue,
                    phone: newPhoneValue = {},
                } = action.result;

                const {
                    address1: address1Value,
                    address2: address2Value,
                    city: cityValue,
                    country: countryValue,
                    countryAlpha2: countryAlpha2Value,
                    countryAlpha3: countryAlpha3Value,
                    postalCode: postalCodeValue,
                    region: regionValue,
                    regionCode: regionCodeValue,
                } = newAddressvalue;

                const {
                    countryCode,
                    displayPhoneNumber,
                    extension,
                    isHome,
                    isMobile,
                    isValid,
                    isWork,
                    phoneNumber: newPhoneNumber,
                    phoneType,
                    phoneTypeId,
                } = newPhoneValue;

                const newIsEmailValid = !!newEmailValue && validator.isEmail(newEmailValue);
                const newIsPhoneValid = !!newPhoneNumber && isValid;

                let newIsPostalCodeValid = true;
                let newIsPostalCodeRequired = false;

                if (!isEmpty(postalCodeValue) &&
                    includes(validator.isPostalCodeLocales, countryAlpha2Value)) {
                    newIsPostalCodeValid = validator.isPostalCode(
                        postalCodeValue,
                        countryAlpha2Value,
                    );
                    newIsPostalCodeRequired = true;
                }

                const isAddressValid = !!cityValue &&
                    !!countryAlpha3Value &&
                    (
                        (isPostalCodeRequired && !!postalCodeValue) ||
                        (!isPostalCodeRequired)
                    ) &&
                    !!regionCodeValue &&
                    !!address1Value;

                return {
                    ...state,
                    email: {
                        ...state.email,
                        isEmailValid: newIsEmailValid,
                        value: newEmailValue,
                    },
                    phone: {
                        ...state.phone,
                        countryCode,
                        displayPhoneNumber,
                        extension,
                        phoneNumber: newPhoneNumber,
                        isPhoneValid: newIsPhoneValid,
                        isHome,
                        isMobile,
                        isWork,
                        phoneType,
                        phoneTypeId,
                    },
                    address: {
                        address1: address1Value,
                        address2: address2Value,
                        city: cityValue,
                        country: countryValue,
                        countryAlpha2: countryAlpha2Value,
                        countryAlpha3: countryAlpha3Value,
                        isPostalCodeValid: newIsPostalCodeValid,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                        isAddressValid,
                        postalCode: postalCodeValue,
                        region: regionValue,
                        regionCode: regionCodeValue,
                    },
                };
            }

            case ActionType.SET_COUNTRY: {
                const newCountry = (
                    action.result && action.result.label
                ) || '';

                const newCountryAlpha2 = (
                    action.result && action.result.code
                ) || '';

                const newCountryAlpha3 = (
                    action.result && action.result.value
                ) || '';

                const isAddressValid = !!address1 &&
                    !!city &&
                    (
                        !isPostalCodeRequired || !!postalCode
                    ) &&
                    !!regionCode &&
                    !!newCountry;

                const newIsPostalCodeRequired = includes(
                    validator.isPostalCodeLocales,
                    newCountryAlpha2,
                );

                return {
                    ...state,
                    address: {
                        ...state.address,
                        country: newCountry,
                        countryAlpha2: newCountryAlpha2,
                        countryAlpha3: newCountryAlpha3,
                        isAddressValid,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                    },
                };
            }

            case ActionType.SET_EMAIL: {
                const { result: newEmailValue } = action;
                const isEmailValid = !!newEmailValue && validator.isEmail(newEmailValue);

                return {
                    ...state,
                    email: {
                        ...state.email,
                        isEmailValid,
                        value: newEmailValue,
                    },
                };
            }

            case ActionType.SET_PHONE: {
                const {
                    countryCode,
                    displayPhoneNumber,
                    phoneNumber: newPhoneNumber,
                } = action.result;

                const newIsPhoneValid = !!newPhoneNumber && action.result.isValid;

                return {
                    ...state,
                    phone: {
                        ...state.phone,
                        countryCode,
                        displayPhoneNumber,
                        phoneNumber: newPhoneNumber,
                        isPhoneValid: newIsPhoneValid,
                    },
                };
            }

            case ActionType.SET_PHONE_TYPE: {
                let isHome;
                let isMobile;
                let isWork;
                let phoneType;
                let phoneTypeId;

                if (action.result.isHome) {
                    isHome = true;
                    isMobile = false;
                    isWork = false;
                    phoneType = PhoneType.Home;
                    phoneTypeId = PhoneTypeId.Home;
                }

                if (action.result.isMobile) {
                    isHome = false;
                    isMobile = true;
                    isWork = false;
                    phoneType = PhoneType.Cell;
                    phoneTypeId = PhoneTypeId.Cell;
                }

                if (action.result.isWork) {
                    isHome = false;
                    isMobile = false;
                    isWork = true;
                    phoneType = PhoneType.Work;
                    phoneTypeId = PhoneTypeId.Work;
                }

                return {
                    ...state,
                    phone: {
                        ...state.phone,
                        isHome,
                        isMobile,
                        isWork,
                        phoneType,
                        phoneTypeId,
                    },
                };
            }

            case ActionType.SET_PHONE_EXTENSION: {
                return {
                    ...state,
                    phone: {
                        ...state.phone,
                        extension: action.result,
                    },
                };
            }

            case ActionType.SET_POSTAL_CODE: {
                let newIsPostalCodeValid = true;
                let newIsPostalCodeRequired = false;

                if (includes(validator.isPostalCodeLocales, countryAlpha2)) {
                    newIsPostalCodeValid = validator.isPostalCode(action.result, countryAlpha2);
                    newIsPostalCodeRequired = true;
                }

                const isAddressValid = !!address1 &&
                    !!city &&
                    !!country &&
                    !!regionCode &&
                    newIsPostalCodeValid;

                return {
                    ...state,
                    address: {
                        ...state.address,
                        isAddressValid,
                        isPostalCodeValid: newIsPostalCodeValid,
                        postalCode: action.result,
                        isPostalCodeRequired: newIsPostalCodeRequired,
                    },
                };
            }

            case ActionType.SET_REGION: {
                const newRegion = (
                    action.result && action.result.label
                ) || DEFAULT_STATE.address.region;

                const newRegionCode = (
                    action.result && action.result.value
                ) || DEFAULT_STATE.address.regionCode;

                const isAddressValid = !!address1 &&
                    !!city &&
                    !!country &&
                    (
                        !isPostalCodeRequired || !!postalCode
                    ) &&
                    !!newRegion;

                return {
                    ...state,
                    address: {
                        ...state.address,
                        isAddressValid,
                        region: newRegion,
                        regionCode: newRegionCode,
                    },
                };
            }

            default:
                return state;
        }
    }

    return state;
};
