import { PhoneTypeProps } from '../../global/models/phoneType.model';
import ActionType from './contactForm.actionTypes';

export const resetData = () => (dispatch: Function) => {
    dispatch({
        type: ActionType.RESET_DATA,
    });
};

export const setAddress1 = (address1: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_ADDRESS1,
        result: address1,
    });
};

export const setAddress2 = (address2: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_ADDRESS2,
        result: address2,
    });
};

export const setCity = (city: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_CITY,
        result: city,
    });
};

export const setContactData = (contactData: {}) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_CONTACT_DATA,
        result: contactData,
    });
};

export const setCountry = (country: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_COUNTRY,
        result: country,
    });
};

export const setEmail = (email: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_EMAIL,
        result: email,
    });
};

export const setPhone = (phone: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_PHONE,
        result: phone,
    });
};

export const setPhoneExtension = (phoneExtension: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_PHONE_EXTENSION,
        result: phoneExtension,
    });
};

export const setPhoneType = (phoneType: PhoneTypeProps) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_PHONE_TYPE,
        result: {
            isHome: phoneType.isHome,
            isMobile: phoneType.isMobile,
            isWork: phoneType.isWork,
        },
    });
};

export const setPostalCode = (postalCode: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_POSTAL_CODE,
        result: postalCode,
    });
};

export const setRegion = (region: string) => (dispatch: Function) => {
    dispatch({
        type: ActionType.SET_REGION,
        result: region,
    });
};
