import {
    assign,
    includes,
    isEmpty,
    isNaN,
    isString,
    uniqueId,
    upperFirst,
} from 'lodash';
import {
    useEffect,
    useRef,
} from 'react';
import moment from 'moment-timezone';
import {
    DOT_KEY_CODE,
    ENTER_KEY_CODE,
    ESCAPE_KEY_CODE,
} from '../keyCodeConstants.js';

const structuredLog = require('structured-log');
const consoleSink = require('structured-log/console-sink.js');

export const getPersonFullName = (person = {}) => (person ?
    `${person.firstName?.trim() ?? ''} ${person.lastName?.trim() ?? ''}` :
    ''
);
export const isDotKeyCode = (event) => event.keyCode === DOT_KEY_CODE;
export const isEnterKeyCode = (event) => event.keyCode === ENTER_KEY_CODE;
export const isEscapeKeyCode = (event) => event.keyCode === ESCAPE_KEY_CODE;

// eslint-disable-next-line no-undef
export const cmAuthUtils = window.CmAuthUtils ?? { isLoggedIn: () => (false) };// NOSONAR

/**
 * Hook to store the previous value of a prop or state.
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};

export const onThumbnailImageError = (event) => {
    // eslint-disable-next-line no-param-reassign
    event.target.src = '/images/default-email-template-icon.svg';
};

/**
 * Determines whether a hostname (e.g. from `window.location.hostname`)
 * is a private network address such as `localhost`, `127.0.0.1`, `10.x.x.x`, etc.
 */
export const isPrivateHostname = (hostname) => (
    /^(::f{4}:)?10\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(hostname) ||
    /^(::f{4}:)?192\.168\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(hostname) ||
    /^(::f{4}:)?172\.(1[6-9]|2\d|30|31)\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(hostname) ||
    /^(::f{4}:)?127\.([0-9]{1,3})\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(hostname) ||
    /^(::f{4}:)?169\.254\.([0-9]{1,3})\.([0-9]{1,3})$/i.test(hostname) ||
    /^f[cd][0-9a-f]{2}:/i.test(hostname) ||
    /^fe80:/i.test(hostname) ||
    /^::1$/.test(hostname) ||
    /^::$/.test(hostname) ||
    hostname.indexOf('localhost') > -1
);

export default class Utils {
    static getIncreasingUniqueKey() {
        const now = (new Date().getTime()).toString();
        return now.substring(now.length - 6, now.length) + uniqueId();
    }

    static getLogger(name) {
        return structuredLog.configure()
            .enrich({ name })
            .writeTo(consoleSink())
            .create();
    }

    /**
     * Ensure all words in a tokenized string begin with an initial capital letter
     *
     * @example
     * // returns 'Mary Ann'
     * Utils.autoCaps('mary ann')
     *
     * @returns {String} Returns modified string to force the initial capital letters
     */
    static autoCaps(input) {
        if (isString(input) && !isEmpty(input)) {
            return input
                .split(' ')
                .map(upperFirst)
                .join(' ');
        }

        return input;
    }

    /**
     * Format a string by C#-like template
     *
     * @example
     * // returns 'First - Second - First'
     * Utils.format('{0} - {1} - {0}', 'First', 'Second');
     *
     * @returns {String} Returns the string formatted by template.
     */
    static format(template, ...args) {
        return template.replace(/{(\d+)}/g, (match, number) => (typeof args[number] !== 'undefined' ? args[number] : match));
    }

    /**
     * Build a query string by parameters'array
     *
     * @example
     * // returns '?id=10&sender=John%20Smith'
     * Utils.buildQuery({id: 10, sender: 'John Smith'});
     *
     * @example
     * // returns '&id=10&sender=John%20Smith'
     * Utils.buildQuery({id: 10, sender: 'John Smith'}, true);
     *
     * @returns {String} Returns the string formatted by template.
     */
    static buildQuery(parameters, append) {
        let qs = '';

        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const key in parameters) {
            const value = parameters[key];

            qs += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
        }

        if (qs.length > 0) {
            const prefix = append ? '&' : '?';
            qs = `${prefix}${qs.substring(0, qs.length - 1)}`; // chop off last "&"
        }

        return qs;
    }

    /**
     * URI templating implementation
     *
     * @example
     * // returns 'http://example.com/users/10/messages/hello'
     * Utils.buildUri('http://example.com/users/{id}/messages/{topic}', {id: 10, topic: 'hello'});
     *
     * @returns {String} Returns the string formatted by template.
     */
    static buildUri(template, params = {}) {
        return template.replace(/{(\w+)}/g, (match, param) => (typeof params[param] !== 'undefined' ? params[param] : match));
    }

    /**
     * Building URL by template
     *
     * @example
     * // returns 'http://example.com/users/10/messages/hello?q=121&l=777'
     * Utils.buildUrl('http://example.com/users/{id}/messages/{topic}', {id: 10, topic: 'hello', q: 121, l: 777});
     *
     * @returns {String} Returns the string formatted by template.
     */
    static buildUrl(template, params) {
        // 1) make a copy of params
        const paramsCopy = assign({}, params);

        // 2) build an uri & remove found params
        const uri = template.replace(/{(\w+)}/g, (match, param) => {
            if (typeof params[param] !== 'undefined') {
                delete paramsCopy[param];
                return params[param];
            }

            return match;
        });

        // 3) build a result url with existing params as query
        return uri + Utils.buildQuery(paramsCopy, includes(uri, '?'));
    }

    /**
     * Determines whether or not a string represents a number.
     * Behavior on strings with both letters and digits is based on underlying behavior of `parseInt()`.
     * If it leads with numeric charcters it is considered numeric; otherwise not.
     *
     * @param {String} input - string to check
     *
     * @example
     * // returns false
     * Utils.isStringNumeric('');
     *
     * @example
     * // return false
     * Utils.isStringNumeric('foo');
     *
     * @example
     * // return true
     * Utils.isStringNumeric('123');
     *
     * @returns {Boolean}
     */
    static isStringNumeric(input) {
        return !isNaN(parseInt(input, 10));
    }

    /**
     * Determines whether or not a string contains valid data.
     * Intended to provide similar semantics as C# `String.IsNullOrWhiteSpace()`
     *
     * @param {String} input - string to check
     *
     * @example
     * // returns true
     * Utils.isStringNullOrWhiteSpace('');
     *
     * @example
     * // returns true
     * Utils.isStringNullOrWhiteSpace('  ');
     *
     * @example
     * // return false
     * Utils.isStringNullOrWhiteSpace('foo');
     *
     * @example
     * // return false
     * Utils.isStringNullOrWhiteSpace('123');
     *
     * @example
     * // return true (we require the input to actually be of type string)
     * Utils.isStringNullOrWhiteSpace(123);
     *
     * @returns {Boolean}
     */
    static isStringNullOrWhiteSpace(input) {
        return !isString(input) || isEmpty(input) || /^\s+$/.test(input);
    }

    /**
     * Calculates the number of pages for pagination
     *
     * @param {Number} itemsCount - Total number of items
     * @param {Number} pageSize - Size of page
     *
     * @example
     * // returns 2
     * Utils.pagerPagesCount(15, 10);
     *
     * @example
     * // returns 0, because an UI-control for single page is not needed
     * Utils.pagerPagesCount(9, 10);
     *
     * @returns {Number} Returns the number of pages
     */
    static pagerPagesCount(itemsCount, pageSize) {
        const pages = Math.ceil(itemsCount / pageSize);
        return pages === 1 ? 0 : pages;
    }

    /**
     * Create an array with pagination data; one element for every page, empty elements are for the gaps "..."
     *
     * @param {Number} itemsCount - Total number of items
     * @param {Number} pageSize - Size of page
     * @param {Number} activePage - Pagination's active page
     *
     * @example
     * // returns [{idx: 1, active: true}, {idx: 2}]
     * // buildPagination(16, 15, 1);
     *
     * @example
     * // returns [{idx: 1, active: true}, {idx: 2}, {idx: 3}, {idx: 4}, {idx: 5}, {}, {idx: 11}]
     * buildPagination(160, 15, 1);
     *
     * @example
     * // returns [{idx: 1}, {}, {idx: 4}, {idx: 5, active: true}, {idx: 6}, {}, {idx: 11}]
     * buildPagination(160, 15, 5);
     *
     * @example
     * // returns [{idx: 1}, {}, {idx: 7}, {idx: 8, active: true}, {idx: 9}, {idx: 10}, {idx: 11}]
     * buildPagination(160, 15, 8);
     *
     * @returns {Array} Returns array that describes pagination elements
     */
    static buildPagination(itemsCount, pageSize, activePage = 1) {
        const result = [];

        if (!pageSize) {
            return result;
        }

        const totalPages = Utils.pagerPagesCount(itemsCount, pageSize);
        let pageNumber = activePage;

        if (totalPages < 2) {
            return result;
        }

        if (totalPages > 0 && pageNumber > totalPages) {
            pageNumber = totalPages;
        }

        if (totalPages <= 6) {
            // 1) 1 2 3 4 N
            // eslint-disable-next-line no-plusplus
            for (let i = 1; i <= totalPages; i++) {
                result.push({ active: i === pageNumber, idx: i });
            }
        } else if (pageNumber < 5) {
            // 2) 1 2 3 4 5 ... N
            // eslint-disable-next-line no-plusplus
            for (let i = 1; i < 6; i++) {
                result.push({ active: i === pageNumber, idx: i });
            }

            result.push({ active: false });
            result.push({ active: false, idx: totalPages });
        } else if (pageNumber > (totalPages - 4)) {
            // 3) 1 ... N-4 N-3 N-2 N-1 N
            result.push({ active: false, idx: 1 });
            result.push({ active: false });

            // eslint-disable-next-line no-plusplus
            for (let i = totalPages - 4; i <= totalPages; i++) {
                result.push({ active: i === pageNumber, idx: i });
            }
        } else {
            // 4) 1 ... 7 8 9 ... N
            result.push({ active: false, idx: 1 });
            result.push({ active: false });

            // eslint-disable-next-line no-plusplus
            for (let i = pageNumber - 1; i <= pageNumber + 1; i++) {
                result.push({ active: i === pageNumber, idx: i });
            }

            result.push({ active: false });
            result.push({ active: false, idx: totalPages });
        }

        return result;
    }

    static readBlob(file, startByte, stopByte, onloadend, onprogress) {
        const start = +startByte || 0;
        const stop = +stopByte || file.size - 1;
        const blob = file.slice(start, stop + 1);
        const reader = new FileReader();

        // If we use onloadend, we need to check the readyState.
        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onloadend && onloadend(evt.target.result);
            }
        };

        reader.onprogress = (evt) => {
            if (evt.lengthComputable) {
                const progress = Math.round((evt.loaded / evt.total) * 100);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                onprogress && onprogress(progress);
            }
        };

        reader.onerror = (evt) => {
            switch (evt.target.error.code) {
                case evt.target.error.NOT_FOUND_ERR:
                    // eslint-disable-next-line no-console
                    console.info('File Not Found!');
                    break;
                case evt.target.error.NOT_READABLE_ERR:
                    // eslint-disable-next-line no-console
                    console.info('File is not readable');
                    break;
                case evt.target.error.ABORT_ERR:
                    break; // noop
                default:
                    // eslint-disable-next-line no-console
                    console.info('An error occurred reading this file.');
            }
        };

        reader.readAsBinaryString(blob);
    }
}

export const fetchAllRecords = (
    apiCall,
    params,
    callbackParams,
    pageSize,
    currentPage = 0,
    recordsKey,
    recordsContainer = [],
    onStart,
    onComplete,
) => {
    onStart(() => {
        apiCall(params, null, null, callbackParams)
            .then((res) => {
                const {
                    total,
                } = res;

                const records = res[recordsKey];

                const currentRecords = [
                    ...recordsContainer,
                    ...records,
                ];

                const shouldFetchNextPage = currentRecords.length < total;

                if (shouldFetchNextPage) {
                    fetchAllRecords(
                        apiCall,
                        {
                            ...params,
                            pageNumber: currentPage + 1,
                        },
                        callbackParams,
                        pageSize,
                        currentPage + 1,
                        recordsKey,
                        currentRecords,
                        onStart,
                        onComplete,
                    );
                } else {
                    onComplete(currentRecords);
                }
            });
    });
};

export const isUrl = (url) => {
    if (isEmpty(url)) {
        return true;
    }

    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

    return regexp.test(url);
};

export const noopPromise = () => new Promise((resolve) => resolve());

export const stopPropagation = (e) => e.stopPropagation();

export const date2quarter = (date) => {
    const h = date.hour();
    const q = Math.floor(date.minute() / 15);
    return h * 4 + q;
};

export const quarter2date = (q) => {
    const hour = Math.floor(q / 4);
    const minute = (q % 4) * 15;

    return moment({ hour, minute });
};

export const textFromHtml = (html) => {
    try {
        const div = document?.createElement('div');
        div.innerHTML = html;

        return div.textContent || div.innerText || '';
    } catch (error) {
        return '';
    }
};
