import en from './en.json';
import es from './es.json';

const i18nResources = {
    en: {
        translation: en,
    },
    es: {
        translation: es,
    },
};

export default i18nResources;
