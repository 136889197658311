import {
    HealthyChurchEnvironment,
} from '../models';

const UNKNOWN = 'UNKNOWN';
export type EnvironmentOrUnknown = HealthyChurchEnvironment | 'UNKNOWN';

let theEnvironment: EnvironmentOrUnknown = UNKNOWN;

export const getEnvironment = (): HealthyChurchEnvironment | null => {
    if (theEnvironment === UNKNOWN) {
        console.warn('Healthy Church Application: Environment is not specified');
        return null;
    }

    return theEnvironment;
};

export const setEnvironment = (environment: HealthyChurchEnvironment) => {
    // eslint-disable-next-line no-console
    console.log('Healthy Church Application: Setting API Environment:', environment);
    theEnvironment = environment;
};
