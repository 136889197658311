import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const setupI18n = (
    translationResources: {},
): Promise<any> => i18n
    .use(initReactI18next)
    .init({
        // translation resources
        resources: translationResources,

        // language default
        fallbackLng: 'en',

        // enable debugging
        debug: true,

        interpolation: {
            escapeValue: false, // documentation claims it is safe to set this option; see https://react.i18next.com/guides/quick-start#configure-i18next
        },

        react: {
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em'], // empahsis added ;-)
        },

        saveMissing: true, // fire `onMissingKey` events
    });

i18n.on('missingKey', (lngs, namespace, key, res) => {
    console.warn('Healthy Church i18n (i18next) - Missing Key:', key);
    /* eslint-disable no-console */
    console.log('Languages:', lngs);
    console.log('Namespace:', namespace);
    console.log('Res:', res);
    /* eslint-enable no-console */
});

export default i18n;
