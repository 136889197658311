/**
 * Enumeration for the various back-end (Web API) "Microservices" within Healthy Church.
 */
enum ServiceType {
    Core = 'Core',
    Events = 'Events',
    Giving = 'Giving',
    Journey = 'Journey',
    Notifications = 'Notifications',
    SmallGroups = 'SmallGroups',
    Workflows = 'Workflows',
}

export default ServiceType;
