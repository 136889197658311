import {
    Checkbox,
    Grid,
    Typography,
} from '@saddlebackchurch/react-cm-ui';
import makeStyles from '@saddlebackchurch/react-cm-ui/core/styles/makeStyles';
import moment from 'moment-timezone';
import React from 'react';
import ConnectionFormContainerOrField from './models/connectionFormContainerOrField.model';

type PropTypes = {
    /**
     * Agreement consent
     */
    agreementConsent: boolean;
    /**
     * Function updates question answers
     */
    onChangeAgreementConsent: (field: ConnectionFormContainerOrField, consented: boolean) => void;

    /**
     * Determines connection form question
     */
    field: ConnectionFormContainerOrField;

    /**
     * Whether or not to show required field validation errors (i.e. did user attempt to submit form entry)
     */
    showValidationErrors: boolean;
};

const useStyles = makeStyles(({
    palette,
    spacing,
    typography,
}) => ({
    gridColumn: {
        paddingBottom: spacing(1.5),
        paddingTop: spacing(1.5),
    },
    checkbox: {
        '&.checkbox-required .checkbox-label-text::after': {
            color: palette.error.main,
            content: '"*"',
            marginLeft: 2,
        },
    },
    checkboxWrapper: {
        marginTop: spacing(1.5),
        marginBottom: spacing(1.5),
    },
    errorMessage: {
        color: palette.error.main,
        fontSize: typography.pxToRem(14),
        marginTop: spacing(0.5),
    },
    label: {
        marginBottom: spacing(0.5),
    },
    labelWrapper: {
        display: 'inline-flex',
    },
    multipleChoiceOption: {
        paddingBottom: spacing(0.5),
        paddingTop: spacing(0.5),
    },
    required: {
        marginLeft: 2,
        color: palette.error.main,
    },
}));

function ConnectionFormField({
    agreementConsent,
    onChangeAgreementConsent,
    field,
    showValidationErrors,
}: PropTypes) {
    const classes = useStyles();
    const fieldId = `connection_form_public--agreement_field_${field.questionId ?? field.agreement?.uniqueId}`;

    const onChangeConsent = (value, checked = false) => {
        onChangeAgreementConsent(field, checked);
    };

    return (
        <React.Fragment>
            <Grid.Column
                className={classes.gridColumn}
                sm={12}
            >
                <Typography
                    variant="body"
                >
                    {/* eslint-disable react/no-danger */}
                    <div dangerouslySetInnerHTML={{ __html: field.agreement?.content }} />
                    {/* eslint-enable react/no-danger */}
                </Typography>
            </Grid.Column>
            <Grid.Column
                className={classes.gridColumn}
                sm={12}
            >
                <Checkbox
                    checked={agreementConsent}
                    error={showValidationErrors && field.isRequired && !agreementConsent ? 'Required' : undefined}
                    fluid
                    id={fieldId}
                    label={agreementConsent ? `${field.agreement?.acceptance} (${moment(new Date()).format('MM/DD/yyyy')})` : field.agreement?.acceptance}
                    labelWeight="bold"
                    onChange={onChangeConsent}
                    required={field.isRequired}
                    tabIndex={0}
                />
            </Grid.Column>
        </React.Fragment>
    );
}

export default ConnectionFormField;
