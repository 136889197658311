/**
 * Mode for the targeting of "preview" or "optional" Features.
 *
 * Based on back-end enum [`/api/src/Common/Saddleback.Cm.Common.Contracts/Models/Application/FeatureFilterType.cs`](https://github.com/saddlebackdev/church-management/blob/dev/api/src/Common/Saddleback.Cm.Common.Contracts/Models/Application/FeatureFilterType.cs).
 */
enum FeatureFilterType {
    TargetedViaAssignment = 'TargetedViaAssignment',
    TargetedViaOptin = 'TargetedViaOptin',
}

export default FeatureFilterType;
